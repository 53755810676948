import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import queryString from "query-string";
import IssueType from "./IssuTypeTable";
import SettingsContactUsTable from "./SettingsContactUs";
import { useLocation, useNavigate } from "react-router-dom";

const data = [
  {
    label: "Issues",
    value: "issue",
    component: <SettingsContactUsTable />,
  },
  {
    label: "Issue Type",
    value: "type",
    component: <IssueType />,
  },
];

const SettingsView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { label } = queryString.parse(location.search);
  return (
    <div className="mt-10">
      <Tabs
        id="custom-animation"
        value={label ? label?.toLowerCase() : "issue"}
      >
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab
              activeClassName="font-bold"
              key={value}
              value={value}
              onClick={() => {
                // add query every klik base on label
                navigate(`?label=${value}`);
              }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default SettingsView;
