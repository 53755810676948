import Swal from "sweetalert2";

export const Toast = Swal.mixin({
  customClass: {
    confirmButton: "ml-4 p-2 bg-green-500 text-white rounded-md",
    cancelButton: "p-2 bg-red-500 text-white rounded-md",
  },
  buttonsStyling: false,
});
export const handleDeleteModel = async (id, deleteFunction, model) => {
  try {
    let modelPayload;
    switch (model) {
      case "user":
        modelPayload = "users";
        break;
      default:
        break;
    }
    if (!modelPayload) return;
  } catch (error) {
    console.log(error);
    return error;
  }
};
