export const columnArtPreference = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "IMAGE",
    accessor: "image",
    type: "image",
  },
];

export const columnMotivation = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "IMAGE",
    accessor: "image",
    type: "image",
  },
];