import { Input, Typography, Button } from "@material-tailwind/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { Box, CircularProgress, Stack } from "@mui/material";
import KeywordAutoComplete from "components/autocomplete/keywordAutoComplete";
import MultipleAutocomplete from "components/autocomplete/multiple";
import AutoCompleteUsername from "components/autocomplete/username";
import { artoImagePlacehoder } from "helpers/placeholder";
import { handleUploadArto } from "helpers/uploadFile";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUpdateArtworkMutation } from "redux/Services/artist";
import { useCreateArtworkMutation } from "redux/Services/artist";
import { useGetArtWorkByIdQuery } from "redux/Services/artist";
import { useGetCategoriesQuery } from "redux/Services/category";
import { setTagsId } from "redux/Slices/userSlice";
import { Toaster, toast } from "sonner";
import InventoryView from "views/admin/administration/inventory/InventoryView";
import queryString from "query-string";
import ArtworkDetailUploader from "./ArtworkDetailUploader";
import { Toast } from "components/toasterSweetAlert";
import { TrashIcon } from "lucide-react";
import { Eye } from "lucide-react";
import { EyeOff } from "lucide-react";
import { useDeleteArtworkMutation } from "redux/Services/artist";

import { useGetArtworkByIdNewQuery } from "redux/Services/artist";

const ArtworkDetails = () => {
  const { tags_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { label, profileId } = queryString.parse(location.search);

  const fileRef = useRef(null);
  const { id } = useParams();
  const [deleteArtwork, { data: deleteArtworkData }] =
    useDeleteArtworkMutation();
  // i want to get label and profileId from query router how?

  const { data: dataArtwrokDetail, isFetching } = useGetArtworkByIdNewQuery(
    { id: id },
    { skip: id === "add" }
  );
  const { data: dataCategory, isFetching: fetchingCategory } =
    useGetCategoriesQuery();

  const [artworkTitle, setArtworkTitle] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [price, setPrice] = useState(0);
  const [locationInput, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [medium, setMedium] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [dimension, setDimension] = useState("");
  const [year, setYear] = useState("");
  const [url, setUrl] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState("");
  const [firstFetch, setFirstFetch] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [keywords, setKeywords] = useState("");
  const [papperA2, setPapperA2] = useState({
    price_paper_A2: "",
    quantity_paper_A2: "",
  });
  const [papperA3, setPapperA3] = useState({
    price_paper_A3: "",
    quantity_paper_A3: "",
  });
  const [descriptionPaper, setDescriptionPaper] = useState("");
  const [notePaper, setNotePaper] = useState("");
  const [merchandises, setMerchandises] = useState([]);

  // console.log(
  //   "🚀 ~ file: ArtworkDetails.jsx:39 ~ ArtworkDetails ~ selectedUsername:",
  //   selectedUsername
  // );
  // console.log(dataArtwrokDetail, "dataArtwrokDetail");
  useEffect(() => {
    if (dataArtwrokDetail && firstFetch) {
      setArtworkTitle(dataArtwrokDetail?.title);
      setMediaType(dataArtwrokDetail?.media_type);
      setPrice(dataArtwrokDetail?.price);
      setLocation(dataArtwrokDetail?.location);
      setDescription(dataArtwrokDetail?.description);
      setMedium(dataArtwrokDetail?.medium);
      setHeight(dataArtwrokDetail?.height);
      setWidth(dataArtwrokDetail?.width);
      setDimension(dataArtwrokDetail?.dimension);
      setYear(dataArtwrokDetail?.year);
      setUrl(dataArtwrokDetail?.url);
      setImageId(dataArtwrokDetail?.imageId);
      setSelectedUsername({
        label: dataArtwrokDetail?.userName,
        profileId: dataArtwrokDetail?.profileId,
      });
      setFirstFetch(false);
      setSelectedCategory(dataArtwrokDetail?.selectedTags);
      setKeywords(dataArtwrokDetail?.keywords);
      setSelectedKeyword(dataArtwrokDetail?.keywordsArr);
      setPapperA2({
        price_paper_A2: dataArtwrokDetail?.papperA2?.product_price,
        quantity_paper_A2: dataArtwrokDetail?.papperA2?.stock_quantity,
      });
      setPapperA3({
        price_paper_A3: dataArtwrokDetail?.papperA3?.product_price,
        quantity_paper_A3: dataArtwrokDetail?.papperA3?.stock_quantity,
      });
      setDescriptionPaper(dataArtwrokDetail?.descriptionPapper);
      setNotePaper(dataArtwrokDetail?.notesPapper);
    }
  }, [dataArtwrokDetail]);
  useEffect(() => {
    if (label && profileId && selectedUsername === "") {
      setSelectedUsername({ label, profileId });
    }
  }, [selectedUsername, label, profileId]);
  const handleChange = (e, name) => {
    switch (name) {
      case "title":
        setArtworkTitle(e.target.value);
        break;
      case "media_type":
        setMediaType(e.target.value);
        break;
      case "price":
        setPrice(e.target.value);
        break;
      case "location":
        setLocation(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      case "medium":
        setMedium(e.target.value);
        break;
      case "height":
        setHeight(e.target.value);
        break;
      case "width":
        setWidth(e.target.value);
        break;
      case "dimension":
        setDimension(e.target.value);
        break;
      case "year":
        setYear(e.target.value);
        break;
      case "keywords":
        setKeywords(e.target.value);

      default:
        break;
    }
  };
  const handleClickUpload = () => {
    fileRef.current.click();
  };
  const [updateArtwork, { data, isLoading: loadingUpdate }] =
    useUpdateArtworkMutation();
  const [createArtwork, { data: dataCreate, isLoading: loadingCreate }] =
    useCreateArtworkMutation();
  const handleUpdateArtwork = async () => {
    try {
      const tagsId = tags_id?.map((item) => {
        return item.id;
      });
      const data = {
        Name: artworkTitle,
        Price: price,
        Year: year,
        Medium: medium,
        Height: height,
        Width: width,
        Dimension_Unit: dimension,
        Description: description,
        Location: locationInput,
        Media_Type: mediaType,
        price_paper_A2: papperA2.price_paper_A2,
        qty_paper_A2: papperA2.quantity_paper_A2,
        price_paper_A3: papperA3.price_paper_A3,
        qty_paper_A3: papperA3.quantity_paper_A3,
        paper_desc: descriptionPaper,
        paper_note: notePaper,
        merchandises: merchandises,
        url_file: imageId === null ? null : [+imageId],
        tags_id: tagsId,
        profile_id: selectedUsername.profileId,
        Username: selectedUsername.label,
        Keywords: keywords,
        keywords_id: selectedKeyword.map((item) => item.id),
      };
      // console.log(data, "datasyg");
      if (id === "add") {
        const response = await createArtwork({ data });
        dispatch(setTagsId([]));

        // console.log(response, "response");
        // clearAll();

        toast.success("Sucess Add Artwork");
        //  WAIT 2 SECONDS
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
        navigate(location?.state?.previous || "/admin/default");
      } else {
        const response = await updateArtwork({ id: id, data });

        dispatch(setTagsId([]));

        toast.success("Success Update Artwork");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster richColors position="top-right" />
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setUrl,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>

      <div className="w-full ">
        <div className="ml-10 mt-10 flex flex-row justify-between">
          <div className="  flex flex-row items-center justify-between gap-2">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate(location?.state?.previous || "/admin/default");
                // history(-1);
                //add window refresh
                // window.location.reload();
              }}
            >
              <ArrowBackIcon />
            </div>
            <p className="font  flex w-full justify-items-center text-2xl font-bold">
              {id === "add" ? "Add Artwork" : "Edit Artwork"}
            </p>
          </div>
          <div>
            {id !== "add" && (
              <div className="flex flex-row items-center justify-center gap-4">
                <Button
                  onClick={() => {
                    Toast.fire({
                      title: "Are you sure?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Delete",
                      cancelButtonText: "Cancel",
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        //  delete action
                        deleteArtwork({ id });
                        Toast.fire({
                          title: "Deleted!",
                          icon: "success",
                        });
                        navigate(`/admin/artist?label=Artwork`);
                      }
                      return;
                    });
                  }}
                  size="sm"
                  color="red"
                  variant="outlined"
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <TrashIcon size={20} />
                    <Typography>Delete</Typography>
                  </Stack>
                </Button>
                <Button
                  disabled={loadingUpdate}
                  onClick={() => {
                    Toast.fire({
                      title: "Are you sure?",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: "Yes",
                      cancelButtonText: "Cancel",
                      reverseButtons: true,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        //  delete action
                        const data = {
                          Hide: !dataArtwrokDetail?.Hide,
                        };
                        await updateArtwork({ id, data });
                        Toast.fire({
                          title: "Success!",
                          icon: "success",
                        });
                      }
                      return;
                    });
                  }}
                  size="sm"
                  color="blue"
                  variant="outlined"
                >
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    {loadingUpdate ? (
                      <CircularProgress size={20} />
                    ) : dataArtwrokDetail?.Hide ? (
                      <Eye size={20} />
                    ) : (
                      <EyeOff size={20} />
                    )}
                    <Typography>
                      {dataArtwrokDetail?.Hide ? "UnHide" : "Hide"}
                    </Typography>
                  </Stack>
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex-cols-2 flex w-full justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex flex-col  gap-1">
            {loading || isFetching ? (
              <Box
                height={384}
                width={"full"}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <img
                className="h-96 w-full rounded-lg object-cover object-center"
                src={url}
                alt="content"
              />
            )}

            <Button
              onClick={handleClickUpload}
              className="mt-3"
              color="pink"
              variant="gradient"
            >
              {id === "add" ? "Upload Artwork" : "Change Artwork"}
            </Button>
            <div class="mt-5 flex flex-row gap-3">
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Media Type
                </Typography>
                <Input
                  type="text"
                  name="media_type"
                  value={mediaType}
                  onChange={(e) => handleChange(e, "media_type")}
                />
              </div>
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Medium
                </Typography>
                <Input
                  type="text"
                  name="medium"
                  value={medium}
                  onChange={(e) => handleChange(e, "medium")}
                />
              </div>
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Year
                </Typography>
                <Input
                  type="text"
                  name="medium"
                  value={year}
                  onChange={(e) => handleChange(e, "year")}
                />
              </div>
            </div>
            <div class="mt-5 flex flex-row gap-3">
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Height
                </Typography>
                <Input
                  type="text"
                  value={height}
                  onChange={(e) => handleChange(e, "height")}
                />
              </div>
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Width
                </Typography>
                <Input
                  type="text"
                  value={width}
                  onChange={(e) => handleChange(e, "width")}
                />
              </div>
              <div className="basis-1/4">
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-4 mt-2 font-medium"
                >
                  Dimension Unit
                </Typography>
                <Input
                  type="text"
                  value={dimension}
                  onChange={(e) => handleChange(e, "dimension")}
                />
              </div>
            </div>
            <div className="w-6/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Tags
              </Typography>
              {dataCategory && selectedCategory.length > 0 && (
                <MultipleAutocomplete
                  data={dataCategory}
                  loading={fetchingCategory}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
              {id === "add" && dataCategory && (
                <MultipleAutocomplete
                  data={dataCategory}
                  loading={fetchingCategory}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
            </div>
            <div className="w-6/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Keywords
              </Typography>
              <KeywordAutoComplete
                selectedCategory={selectedKeyword}
                setSelectedCategory={setSelectedKeyword}
              />
            </div>
          </div>
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Artwork Title
              </Typography>
              <Input
                type="text"
                name="title"
                value={artworkTitle}
                onChange={(e) => handleChange(e, "title")}
              />
            </div>
            {/* <div className="w-4/6">
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-4 mt-2 font-medium"
            >
              Last Name
            </Typography>
            <Input type="email" />
          </div> */}
            {/* <div className="w-4/6">
            <Typography
              variant="small"
              color="grey"
              className="mb-4 mt-2 font-medium"
            >
              Country Code
            </Typography>
            <Input type="email" />
          </div>
          <div className="w-4/6">
            <Typography
              variant="small"
              color="grey"
              className="mb-4 mt-2 font-medium"
            >
              Mobile
            </Typography>
            <Input type="email" />
          </div> */}
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Location
              </Typography>
              <Input
                type="text"
                name="location"
                value={locationInput}
                onChange={(e) => handleChange(e, "location")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Username of Artist
              </Typography>
              <AutoCompleteUsername
                selectedUsername={selectedUsername}
                setSelectedUsername={setSelectedUsername}
                detailCollectionId={dataArtwrokDetail}
                isArtist={true}
                disabled={id === "add" && label && profileId}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="gr ey"
                className="mb-4 mt-2 font-medium"
              >
                Description
              </Typography>
              <textarea
                name="description"
                id="description"
                rows="4"
                class="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Write your thoughts here..."
                value={description}
                onChange={(e) => handleChange(e, "description")}
              ></textarea>
            </div>

            {/* <div className="w-4/6">
            <Typography
              variant="small"
              color="grey"
              className="mb-4 mt-2 font-medium"
            >
              Year
            </Typography>
            <Input
              type="text"
              name="medium"
              value={year}
              onChange={(e) => handleChange(e, "year")}
            />
          </div> */}
          </div>
        </div>
        <Stack mt={2} gap={2} ml={5}>
          {!isFetching && (
            <ArtworkDetailUploader
              price={price}
              handleChange={handleChange}
              merchandises={merchandises}
              setMerchandises={setMerchandises}
              note={notePaper}
              setNote={setNotePaper}
              description={descriptionPaper}
              setDescription={setDescriptionPaper}
              papperA2={papperA2}
              setPapperA2={setPapperA2}
              papperA3={papperA3}
              setPapperA3={setPapperA3}
              dataDetail={dataArtwrokDetail}
            />
          )}
        </Stack>
        <div className="mb-14 mt-14 flex justify-center gap-4">
          <Button
            disabled={loadingCreate || loadingUpdate}
            color="pink"
            variant="gradient"
            onClick={handleUpdateArtwork}
          >
            {loadingCreate || loadingUpdate ? (
              <CircularProgress size={20} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
export default ArtworkDetails;
