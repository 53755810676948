import dayjs from "dayjs";

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import {
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button } from "@material-tailwind/react";
import { useCreatePromoMutation } from "redux/Services/promo";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { toast } from "sonner";
import { useGetPromoByIdQuery } from "redux/Services/promo";
import { Button as ButtonMaterial } from "@mui/material";
import { useUpdateActivePromoMutation } from "redux/Services/promo";
import { useUpdatePromoMutation } from "redux/Services/promo";
const AddPromo = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [selectedType, setSelectedType] = useState(null);
  const [detail, setDetail] = useState({
    name: "",
    code: "",
    fix_amount: "",
    percentage: "",
    max_disc_use_all_user: "",
    minimum_purchase: "",
    minimum_qty_item: "",
    max_disc_use_one_user: "",
  });
  console.log(detail, "ini cek");
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [selectMinimal, setSelectMinimal] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const handleChange = (event) => {
    setSelectedType(event.target.value);

    setDetail({
      ...detail,
      fix_amount: "",
      percentage: "",
    });
  };
  const handleChangeInput = (event, name) => {
    setDetail({
      ...detail,
      [name]: event.target.value,
    });
  };
  const [createPromo, { isLoading: loadingCreate, error: errorCreate }] =
    useCreatePromoMutation();
  const [
    updateActive,
    { isLoading: loadingUpdateActive, error: errorUpdateActive },
  ] = useUpdateActivePromoMutation();
  const [updateAll, { isLoading: loadingUpdateAll, error: errorUpdateAll }] =
    useUpdatePromoMutation();
  const { data: dataPromo, isLoading: loadingPromo } = useGetPromoByIdQuery(
    {
      id: id,
    },
    {
      skip: id === "add",
    }
  );

  const handleCreatePromo = async (isActive) => {
    try {
      const data = {
        name: detail?.name === "" ? null : detail?.name,
        code: detail?.code === "" ? null : detail?.code,
        fix_amount: detail?.fix_amount === "" ? null : detail?.fix_amount,
        percentage: detail.percentage === "" ? null : +detail.percentage,
        is_active: isActive,
        minimum_purchase:
          detail?.minimum_purchase === "" ? null : detail?.minimum_purchase,
        start_date: dateStart === null ? null : dateStart.toISOString(),
        end_date: dateEnd === null ? null : dateEnd.toISOString(),
        max_disc_use_all_user:
          detail.max_disc_use_all_user === ""
            ? null
            : +detail?.max_disc_use_all_user,
        minimum_qty_item:
          detail?.minimum_qty_item === "" ? null : +detail?.minimum_qty_item,
        max_disc_use_one_user:
          detail?.max_disc_use_one_user === ""
            ? null
            : +detail?.max_disc_use_one_user,
        promo_type: "Amount of Order",
        purchase_type: "OneTime",
      };
      if (id === "add") {
        const res = await createPromo({ data: { data } });
        if (res?.data?.data?.id) {
          toast.success("Success create promo");
          setTimeout(() => {
            navigate("/admin/promo", {
              state: { previous: location.pathname },
            });
          }, 1000);
        }
      } else {
        const res = await updateAll({ id, data: { data } });
        if (res?.data?.data?.id) {
          toast.success("Success update promo");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickActivate = async () => {
    try {
      const data = {
        is_active: !dataPromo?.data?.attributes?.is_active,
      };
      const res = await updateActive({
        id,
        data: { data },
      });
    } catch (error) {}
  };
  useEffect(() => {
    if (dataPromo) {
      const isFixedAmount =
        dataPromo?.data?.attributes?.fix_amount === null ? false : true;
      setSelectedType(isFixedAmount ? 2 : 1);
      setDetail({
        ...detail,
        name: dataPromo?.data?.attributes?.name,
        code: dataPromo?.data?.attributes?.code,
        fix_amount:
          dataPromo?.data?.attributes?.fix_amount === null
            ? ""
            : dataPromo?.data?.attributes?.fix_amount,
        minimum_purchase:
          dataPromo?.data?.attributes?.minimum_purchase === null
            ? ""
            : dataPromo?.data?.attributes?.minimum_purchase,
        percentage:
          dataPromo?.data?.attributes?.percentage === null
            ? ""
            : dataPromo?.data?.attributes?.percentage,
        max_disc_use_all_user:
          dataPromo?.data?.attributes?.max_disc_use_all_user === null
            ? ""
            : dataPromo?.data?.attributes?.max_disc_use_all_user,
        minimum_qty_item:
          dataPromo?.data?.attributes?.minimum_qty_item === null
            ? ""
            : dataPromo?.data?.attributes?.minimum_qty_item,
        max_disc_use_one_user:
          dataPromo?.data?.attributes?.max_disc_use_one_user === null
            ? ""
            : dataPromo?.data?.attributes?.max_disc_use_one_user,
      });

      const isDate =
        dataPromo?.data?.attributes?.start_date &&
        dataPromo?.data?.attributes?.end_date
          ? true
          : false;
      setSelectDate(isDate ? 1 : 2);

      setDateStart(
        dataPromo?.data?.attributes?.start_date === null
          ? null
          : dayjs(dataPromo?.data?.attributes?.start_date)
      );
      setDateEnd(
        dataPromo?.data?.attributes?.end_date === null
          ? null
          : dayjs(dataPromo?.data?.attributes?.end_date)
      );
    }
  }, [dataPromo]);

  return (
    <>
      <div className="  mt-10 flex flex-row items-center justify-between gap-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate(location?.state?.previous || "/admin/default");
          }}
        >
          <ArrowBackIcon />
        </div>

        <p className="font  flex w-full justify-items-center text-2xl font-bold">
          {id === "add" ? "Add Promo" : "Edit Promo"}
        </p>
        {id !== "add" && (
          <ButtonMaterial
            variant="outlined"
            color={
              dataPromo?.data?.attributes?.is_active ? "success" : "primary"
            }
            onClick={handleClickActivate}
          >
            {loadingUpdateActive ? (
              <CircularProgress size={15} />
            ) : dataPromo?.data?.attributes?.is_active ? (
              "Active"
            ) : (
              "Draft"
            )}
          </ButtonMaterial>
        )}
      </div>
      <Stack mt={5}>
        <Typography mb={2}>What kind of promo do you want to make?</Typography>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedType}
            label=""
            onChange={handleChange}
          >
            <MenuItem value={1}>By percentage</MenuItem>
            <MenuItem value={2}>By fixedamount</MenuItem>
          </Select>
        </FormControl>
        {selectedType !== null && (
          <Stack mt={2} gap={2}>
            <TextField
              variant="outlined"
              name="name"
              onChange={(e) => handleChangeInput(e, "name")}
              value={detail?.name}
              label="Name"
            />
            <TextField
              variant="outlined"
              name="code"
              onChange={(e) => handleChangeInput(e, "code")}
              value={detail?.code}
              label="Code"
            />
            {selectedType === 2 ? (
              <TextField
                variant="outlined"
                name="fix_amount"
                onChange={(e) => handleChangeInput(e, "fix_amount")}
                value={detail?.fix_amount}
                label="Fix Amount"
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            ) : (
              <TextField
                variant="outlined"
                name="percentage"
                onChange={(e) => handleChangeInput(e, "percentage")}
                value={detail?.percentage}
                label="Percentage"
                type="number"
                InputProps={{
                  inputProps: { min: 0, max: 100 },
                }}
              />
            )}

            {/* <Select
              value={selectMinimal}
              onChange={(e) => {
                setSelectMinimal(e.target.value);
                setDetail({
                  ...detail,
                  minimal: "",
                });
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={2}>No</MenuItem>
            </Select> */}

            <TextField
              variant="outlined"
              name="minimum_qty_item"
              onChange={(e) => handleChangeInput(e, "minimum_qty_item")}
              value={detail?.minimum_qty_item}
              label="Minimal Quantity Amount"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <TextField
              variant="outlined"
              name="minimum_purchase"
              onChange={(e) => handleChangeInput(e, "minimum_purchase")}
              value={detail?.minimum_purchase}
              label="Minimal Purchase Amount"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <TextField
              variant="outlined"
              name="max_disc_use_all_user"
              onChange={(e) => handleChangeInput(e, "max_disc_use_all_user")}
              value={detail?.max_disc_use_all_user}
              label="Maximal users per discount"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            <TextField
              variant="outlined"
              name="minimal"
              onChange={(e) => handleChangeInput(e, "max_disc_use_one_user")}
              value={detail?.max_disc_use_one_user}
              label="Maximal usage amount per user"
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
            {/* <Typography>
              Does this promo have date range to be applied?
            </Typography>
            <Select
              value={selectDate}
              onChange={(e) => {
                setSelectDate(e.target.value);
              }}
            >
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={2}>No</MenuItem>
            </Select> */}

            <Stack flexDirection={"row"} gap={5}>
              <DatePicker
                label="Date Start"
                value={dateStart}
                onChange={(e) => {
                  setDateStart(e);
                }}
              />
              <DatePicker
                label="Date End"
                value={dateEnd}
                onChange={(e) => {
                  setDateEnd(e);
                }}
              />
            </Stack>

            {id === "add" ? (
              <Stack flexDirection={"row"} gap={5} justifyContent={"center"}>
                <Button
                  color="pink"
                  variant="gradient"
                  onClick={() => handleCreatePromo(false)}
                  disabled={loadingCreate}
                >
                  {loadingCreate ? (
                    <CircularProgress size={16} />
                  ) : (
                    "Save As Draft"
                  )}
                </Button>
                <Button
                  disabled={loadingCreate}
                  color="pink"
                  variant="gradient"
                  onClick={() => handleCreatePromo(true)}
                >
                  {loadingCreate ? <CircularProgress size={16} /> : "Publish"}
                </Button>
              </Stack>
            ) : (
              <Stack>
                <Button
                  color="pink"
                  variant="gradient"
                  onClick={() => handleCreatePromo(false)}
                  disabled={loadingCreate}
                >
                  {loadingUpdateAll ? (
                    <CircularProgress size={16} />
                  ) : (
                    "Edit Promo"
                  )}
                </Button>
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default AddPromo;
