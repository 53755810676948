import { user } from "@nextui-org/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
// access ENV .url
const baseUrl = process.env.REACT_APP_API_URL;
export const userApi = createApi({
  reducerPath: "userApi",

  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: ({ identifier, password }) => ({
        url: "auths/admin-bo",
        method: "POST",
        body: {
          email: identifier,
          password: password,
        },
      }),
    }),
    getUserList: builder.query({
      //   query: ({
      //     page = 1,
      //     pageSize = 10,
      //     filter = "",
      //     withCount = false,
      //     userName = "",
      //     isArtist = false,
      //   }) => ({
      //     url: `profiles?populate=users_permissions_user&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[is_artist][$eq]=${isArtist}&filters[users_permissions_user][id][$notNull]=true${
      //       filter
      //         ? `&filters[$or][0][First_Name][$containsi]=${filter}&filters[$or][1][Last_Name][$containsi]=${filter}`
      //         : ""
      //     }${
      //       userName
      //         ? `&filters[users_permissions_user][username][$containsi]=${userName}`
      //         : ""
      //     }${withCount ? `&pagination[withCount]=true` : ""}`,
      //     method: "GET",

      query: ({
        page = 1,
        pageSize = 10,
        filter = "",
        withCount = false,
        userName = "",
        isArtist = false,
      }) => {
        const baseUrl = `profiles?populate=users_permissions_user&pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[is_artist][$eq]=${isArtist}&filters[users_permissions_user][id][$notNull]=true`;
        const filterUrl = filter
          ? `&filters[$or][0][First_Name][$containsi]=${filter}&filters[$or][1][Last_Name][$containsi]=${filter}`
          : "";
        const userNameUrl = `&filters[users_permissions_user][username][$containsi]=${userName}`;
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + userNameUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { First_Name, Last_Name, Location } = item?.attributes;
          const status = item?.attributes?.users_permissions_user?.data
            ?.attributes?.blocked
            ? "Blocked"
            : "Active";
          const userName =
            item?.attributes?.users_permissions_user?.data?.attributes
              ?.username;
          const userId = item?.attributes?.users_permissions_user?.data?.id;
          return {
            id,
            first_name: First_Name,
            last_name: Last_Name,
            country: Location,
            status,
            userId,
            userName,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["User"],
    }),
    getUserById: builder.query({
      query: ({ id }) => ({
        url: `profiles/${id}?populate=users_permissions_user,avatar_file`,
        method: "GET",
      }),
      transformResponse: (response) => {
        const { data } = response;
        const id = data?.id;
        const {
          First_Name,
          Last_Name,
          Location,
          avatar_file,
          suspended,
          blacklist,
        } = data?.attributes;

        const userName =
          data?.attributes?.users_permissions_user?.data?.attributes?.username;
        const userId = data?.attributes?.users_permissions_user?.data?.id;
        const newResponse = {
          id,
          suspended,
          first_name: First_Name,
          last_name: Last_Name,
          country: Location,
          blacklist,
          userId,
          userName,
          email:
            data?.attributes?.users_permissions_user?.data?.attributes?.email,
          short_bio: data?.attributes?.Short_Bio,
          bio: data?.attributes?.About_Bio,
          avatar: {
            id: avatar_file?.data?.id,
            url:
              avatar_file?.data?.attributes?.formats?.thumbnail?.url ||
              artoImagePlacehoder,
          },
        };
        return newResponse;
      },
      providesTags: ["UserDetail"],
    }),
    editUser: builder.mutation({
      query: ({ id, data, isArtist }) => ({
        url: `back-office/user-profile/${id}${
          isArtist ? "?is_artist=true" : ""
        }`,
        method: "PUT",
        body: data,
        headers: {
          Authorization: `bearer ${localStorage.getItem("jwt")}`,
        },
      }),
    }),
    postUser: builder.mutation({
      query: ({ data }) => ({
        url: `profiles`,
        method: "POST",
        body: data,
      }),
    }),
    getAllUserList: builder.query({
      // query: ({
      //   filter = "",
      //   withCount = false,
      //   userName = "",
      //   isArtist = false,
      // }) => ({
      //   url: `profiles?populate=users_permissions_user&filters[is_artist][$eq]=${isArtist}&filters[users_permissions_user][id][$notNull]=true${
      //     filter
      //       ? `&filters[$or][0][First_Name][$containsi]=${filter}&filters[$or][1][Last_Name][$containsi]=${filter}&filters[$or][2][users_permissions_user][username][$containsi]=${filter}`
      //       : ""
      //   }${
      //     userName
      //       ? `&filters[users_permissions_user][username][$containsi]=${userName}`
      //       : ""
      //   }${withCount ? `&pagination[withCount]=true` : ""}`,
      //   method: "GET",
      // }),
      query: ({
        filter = "",
        withCount = false,
        userName = "",
        isArtist = false,
      }) => {
        const baseUrl = `profiles?populate=users_permissions_user&filters[is_artist][$eq]=${isArtist}&filters[users_permissions_user][id][$notNull]=true`;
        const filterUrl = filter
          ? `&filters[$or][0][First_Name][$containsi]=${filter}&filters[$or][1][Last_Name][$containsi]=${filter}&filters[$or][2][users_permissions_user][username][$containsi]=${filter}`
          : "";
        const userNameUrl = `&filters[users_permissions_user][username][$containsi]=${userName}`;
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + userNameUrl + withCountUrl,
          method: "GET",
        };
      },

      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { First_Name, Last_Name, Location } = item?.attributes;
          const userName =
            item?.attributes?.users_permissions_user?.data?.attributes
              ?.username;
          return {
            id,
            label: userName,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["User"],
    }),
    deleteUserList: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=plugin::users-permissions.user&id_select=${id}`,
        method: "DELETE",
        headers: {
          Authorization: `bearer ${localStorage.getItem("jwt")}`,
        },
      }),
      invalidatesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: ({ data }) => ({
        url: `back-office/user-profile`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `bearer ${localStorage.getItem("jwt")}`,
        },
      }),
    }),
    suspendedUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `profiles/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UserDetail"],
    }),
    blackListedUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `profiles/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UserDetail"],
    }),
    updatePassword: builder.mutation({
      query: ({ id, data }) => ({
        url: `change-password`,
        method: "POST",
        body: data,
        headers: {
          Authorization: `bearer ${localStorage.getItem("jwt")}`,
        },
      }),
    }),
  }),
});

export const {
  useUserLoginMutation,
  useGetUserListQuery,
  useGetUserByIdQuery,
  useEditUserMutation,
  usePostUserMutation,
  useGetAllUserListQuery,
  useDeleteUserListMutation,
  useSuspendedUserMutation,
  useBlackListedUserMutation,
  useUpdatePasswordMutation,
} = userApi;
