import Tables from "components/table/index";
import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";
import { useGetExhibitionQuery } from "redux/Services/artist";

import { columnExhibition } from "./variables/columnsData";
import { Stack } from "@mui/material";
import { Button } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";

const ExhibitionTable = ({ query }) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selected, setSelected] = useState([]);
  const {
    data: dataUser,
    isFetching,
    error,
  } = useGetExhibitionQuery({
    page,
    pageSize,
    filter: debouncedSearchTerm,
    query,
  });
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="">
      <Stack alignItems={"end"} mb={2}>
        <Button
          className="mb-3 mt-3 w-1/5"
          color="pink"
          variant="gradient"
          onClick={() => {
            navigate(`/admin/artist/exhibition-details/add`, {
              state: { previous: location.pathname + "?label=Exhibition" },
            });
          }}
        >
          Add Exhibition
        </Button>
      </Stack>
      <Tables
        columnsData={columnExhibition}
        tableData={dataUser?.data || []}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={dataUser?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default ExhibitionTable;
