export const columnsComment = [
  {
    Header: "TIME POSTED",
    accessor: "time_posted",
  },
  {
    Header: "COMMENT",
    accessor: "comment",
  },
  {
    Header: "FIRST NAME",
    accessor: "first_name",
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
  },
  {
    Header: "ARTWORK TITLE",
    accessor: "artwork_title",
  },
];

