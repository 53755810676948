import { Input, Typography, Button, Avatar } from "@material-tailwind/react";
import { CircularProgress, Stack } from "@mui/material";
import { artoImagePlacehoder } from "helpers/placeholder";
import { handleUploadArto } from "helpers/uploadFile";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { useGetProfileOrgByIdQuery } from "redux/Services/organisation";
import { useEditProfileOrgMutation } from "redux/Services/organisation";
import { useAddProfileOrgMutation } from "redux/Services/organisation";
import { toast } from "sonner";
import { useGetArtWorkByKeywordQuery } from "redux/Services/artist";
import Carousel from "components/carousel";
import KeywordAutoComplete from "components/autocomplete/keywordAutoComplete";
import InitiativeTable from "./InitiativeTable";

const OrganisationProfileDetail = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fileRef = useRef(null);
  const fileRef2 = useRef(null);
  const { id } = useParams();
  const {
    data: detailOrg,
    isLoading,
    isError,
  } = useGetProfileOrgByIdQuery({ id }, { skip: id === "add" });

  const [loading, setLoading] = useState(false);
  const [urlAvatar, setUrl] = useState(artoImagePlacehoder);
  const [imageIdAvatar, setImageId] = useState("");
  const [urlBg, setUrlBg] = useState(artoImagePlacehoder);
  const [imageIdBg, setImageIdBg] = useState("");
  const [detailOrgState, setDetailOrgState] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const { data: dataArtwork, isLoading: loadingArtwork } =
    useGetArtWorkByKeywordQuery(
      {
        keyword: `&filters[keywords_id][id][$eq]=${detailOrgState?.keywords_id_arr?.map(
          (item) => item?.id
        )}`,
      },
      { skip: id === "add" || !id }
    );

  const handleClick1 = () => {
    fileRef.current.click();
  };
  const handleCLick2 = () => {
    fileRef2.current.click();
  };
  const handleChange = (e, name) => {
    setDetailOrgState({
      ...detailOrgState,
      [name]: e.target.value,
    });
  };
  useEffect(() => {
    if (detailOrg) {
      setDetailOrgState(detailOrg);
      setUrl(detailOrg?.avatarUrl);
      setImageId(detailOrg?.avatarId);
      setUrlBg(detailOrg?.bannerUrl);
      setImageIdBg(detailOrg?.bannerId);
      setSelectedKeyword(detailOrg?.keywords_id_arr);
    }
  }, [detailOrg]);
  const [editProfileOrg, { isLoading: loadingEdit }] =
    useEditProfileOrgMutation();
  const [addProfileOrg, { isLoading: loadingAdd }] = useAddProfileOrgMutation();

  const handlesubmit = async () => {
    try {
      const data = {
        ...detailOrgState,
        banner_url_file: {
          id: imageIdBg,
        },
        avatar_url_file: {
          id: imageIdAvatar,
        },
        keywords_id: selectedKeyword?.map((item) => item?.id),
      };
      if (id === "add") {
        const res = await addProfileOrg({ data: { data } });
        toast.success("Add Profile Organisation Success");
        // make timeout
        setTimeout(() => {
          navigate("/admin/organisation?label=Profile", {
            state: { previous: "admin/organisation/profile/add" },
          });
        }, 1000);
      } else {
        const res = await editProfileOrg({ id, data: { data } });
        toast.success("Edit Profile Organisation Success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setUrl,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setUrlBg,
              setImageId: setImageIdBg,
            })
          }
          ref={fileRef2}
          type="file"
          className="hidden"
        />
      </div>
      <div className="w-full ">
        <div className=" ml-10 mt-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
              // history(-1);
              //add window refresh
              // window.location.reload();
            }}
          >
            <ArrowBackIcon />
          </div>
          <p className="font flex w-full justify-items-center text-2xl font-bold">
            {id === "add"
              ? "Add Profile Organisation"
              : "Edit Profile Organisation"}
          </p>
        </div>

        <div className="flex-cols-2 flex w-full justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <h2 className="font-bold">Basic Info</h2>
            <Typography
              variant="small"
              color="blue-gray"
              className="mb-4 mt-2 font-medium"
            >
              Profile Picture
            </Typography>

            {loading ? (
              <>
                <Stack>
                  <CircularProgress />
                </Stack>
              </>
            ) : (
              <Avatar src={urlAvatar} alt="avatar" size="xl" />
            )}
            <Button
              className="mb-3 mt-3 w-1/5"
              color="pink"
              variant="gradient"
              onClick={handleClick1}
            >
              {id === "add" ? "Upload" : "Edit"}
            </Button>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Organisation Name
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Organisation_Name}
                onChange={(e) => handleChange(e, "Organisation_Name")}
              />
            </div>
            <Typography
              variant="small"
              color="blue-gray-300"
              className="mb-2 mt-2 font-medium"
            >
              Featured Banner
            </Typography>
            <div className=" mt-5 flex flex-col  gap-1">
              {loading ? (
                <>
                  <Stack>
                    <CircularProgress />
                  </Stack>
                </>
              ) : (
                <img
                  className="h-40 w-4/5 rounded-lg object-cover object-center"
                  src={urlBg}
                  alt="profile image"
                />
              )}

              <Button
                className="mb-3 mt-3 w-4/5"
                color="pink"
                variant="gradient"
                onClick={handleCLick2}
              >
                {id === "add" ? "Upload Banner" : "Edit Banner"}
              </Button>
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Location
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Location}
                onChange={(e) => handleChange(e, "Location")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Username
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Username}
                onChange={(e) => handleChange(e, "Username")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Keywords
              </Typography>
              {
                <KeywordAutoComplete
                  selectedCategory={selectedKeyword}
                  setSelectedCategory={setSelectedKeyword}
                />
              }
              {/* <KeywordAutoComplete
                selectedCategory={selectedKeyword}
                setSelectedCategory={setSelectedKeyword}
              /> */}
            </div>

            <div className="w-4/6">
              <Typography
                variant="small"
                color="gr ey"
                className="mb-4 mt-2 font-medium"
              >
                Profile About
              </Typography>
              <textarea
                id="message"
                rows="4"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Write your thoughts here..."
                value={detailOrgState?.Profile_About}
                onChange={(e) => handleChange(e, "Profile_About")}
              ></textarea>
            </div>

            <div className="w-4/6">
              <Typography
                variant="small"
                color="gr ey"
                className="mb-4 mt-2 font-medium"
              >
                Profile Bio
              </Typography>
              <textarea
                id="message"
                rows="2"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Write your thoughts here..."
                value={detailOrgState?.Profile_Bio}
                onChange={(e) => handleChange(e, "Profile_Bio")}
              ></textarea>
            </div>
          </div>
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Contact Number
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Contact_Number}
                onChange={(e) => handleChange(e, "Contact_Number")}
              />
            </div>

            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Contact Email
              </Typography>
              <Input
                type="email"
                value={detailOrgState?.Contact_Email}
                onChange={(e) => handleChange(e, "Contact_Email")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Website
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Website}
                onChange={(e) => handleChange(e, "Website")}
              />
            </div>
            <h2 className="mb-5 mt-5 font-bold">Social Links</h2>

            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Facebook
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Facebook_Link}
                onChange={(e) => handleChange(e, "Facebook_Link")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Instagram
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Instagram_Link}
                onChange={(e) => handleChange(e, "Instagram_Link")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Linkedin
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Linkedin_Link}
                onChange={(e) => handleChange(e, "Linkedin_Link")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                X (Twitter)
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Twitter_Link}
                onChange={(e) => handleChange(e, "Twitter_Link")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Youtube
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Youtube_Link}
                onChange={(e) => handleChange(e, "Youtube_Link")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Tiktok
              </Typography>
              <Input
                type="text"
                value={detailOrgState?.Tiktok_Link}
                onChange={(e) => handleChange(e, "Tiktok_Link")}
              />
            </div>
          </div>
        </div>
        {id !== "add" && (
          <Stack mt={4} alignItems={"center"} className="w-full">
            <Typography
              variant="large"
              color="grey"
              className="mb-4 mt-2 font-medium"
            >
              LIST OF ARTWORK
            </Typography>
          </Stack>
        )}
        {!loadingArtwork && dataArtwork?.length > 0 && id !== "add" ? (
          <Carousel data={dataArtwork} />
        ) : null}
        {id !== "add" && (
          <Stack mt={4} alignItems={"center"} className="w-full">
            <Typography
              variant="large"
              color="grey"
              className="mb-4 mt-2 font-medium"
            >
              INITIATIVE
            </Typography>
          </Stack>
        )}
        {id !== "add" && (
          <InitiativeTable
            query={`&filters[organisation_profile][id][$eq]=${id}`}
          />
        )}
        <div className="mb-14 mt-14 flex justify-center gap-4">
          {isLoading || loadingEdit ? (
            <Stack>
              <CircularProgress />
            </Stack>
          ) : (
            <Button
              onClick={handlesubmit}
              className="mb-3 mt-3 w-2/5"
              color="pink"
              variant="gradient"
            >
              {id === "add" ? "Add" : "Save"}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default OrganisationProfileDetail;
