import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
// import Chip from "@mui/material/Chip";

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetAllUserListQuery } from "redux/Services/user";
import { setTagsId } from "redux/Slices/userSlice";

const MultipleUsername = ({
  data,
  loading,
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debounceSearch = useState(() => {
    setSelected(selectedCategory);
    // dispatch(setTagsId(selectedCategory));
  }, [selected, setSelectedCategory]);

  const {
    data: collectionUserName,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetAllUserListQuery({
    withCount: false,
    filter: debouncedSearchTerm,
    isArtist: true,
  });

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="tags-outlined"
        loading={isFetchingProfile}
        options={collectionUserName?.data || []}
        getOptionLabel={(option) => option?.label}
        //   disableClearable
        value={selectedCategory || []}
        inputValue={searchTerm}
        onChange={(event, newValue) => {
          setSelectedCategory(newValue);
          //   dispatch(setTagsId(newValue));
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetchingProfile ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default MultipleUsername;
