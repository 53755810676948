import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Toast } from 'components/toasterSweetAlert'

import { Box, Button as ButtonMaterial } from '@mui/material'
import {
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { Button, Textarea } from '@material-tailwind/react'
import { useGetByIdQuery } from 'redux/Services/inventory'
import { handleUploadArto } from 'helpers/uploadFile'
import { artoImagePlacehoder } from 'helpers/placeholder'
import AutoCompleteUsername from 'components/autocomplete/username'
import ArtworkAutocomplete from 'components/autocomplete/artworkAutocomplete'
import ProductType from 'components/autocomplete/productAutocomplete'
import SubTypeAutoComplete from 'components/autocomplete/subType'
import SubSubAutoComplete from 'components/autocomplete/subSubType'
import { useCreateMutation } from 'redux/Services/inventory'
import { toast } from 'sonner'
import { useUpdateMutation } from 'redux/Services/inventory'
import { TrashIcon } from 'lucide-react'

const InventoryDetails = () => {
  const fileRef = useRef(null)
  let navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams()
  const { data: dataInventory, isLoading: loadingInventory } = useGetByIdQuery(
    { id },
    { skip: id === 'add' }
  )

  const [detail, setDetail] = useState({
    product_name: '',
    notes: '',
    description: '',
    valueSub: [],
    price: 0
  })
  const handleTyphography = text => {
    console.log(id, 'text')
    if (!id || id === 'add') return null
    if (+id === 1)
      return <Typography variant=''>Transparent svg / 17 px x 14 px</Typography>
    if (+id === 2)
      return <Typography variant=''>Transparent svg / 10 px x 14 px</Typography>
    if (+id === 4)
      return <Typography variant=''>Transparent svg / 11 px x 12 px</Typography>
    if (+id === 5)
      return <Typography variant=''>Transparent svg / 16 px x 16px</Typography>
    if (+id === 6)
      return (
        <Typography variant=''>Transparent svg / 800 px x 800 px</Typography>
      )
  }

  const [selectedUsername, setSelectedUsername] = useState({
    label: '',
    profileId: ''
  })

  const [selectedArtwork, setSelectedArtwordk] = useState({
    label: '',
    typeId: ''
  })
  const [selectedProductType, setSelectedProductType] = useState({
    label: '',
    typeId: ''
  })

  const [selectedProductSubType, setSelectedProductSubType] = useState({
    label: '',
    subTypeId: ''
  })
  const [selectedProductSubSubType, setSelectedProductSubSubType] = useState({
    label: '',
    subSubTypeId: ''
  })

  const [loading, setLoading] = useState(false)
  const [imageLink, setImageLink] = useState(artoImagePlacehoder)
  const [imageId, setImageId] = useState(null)
  const handleDetailChange = e => {
    setDetail({ ...detail, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (dataInventory) {
      console.log(dataInventory, 'dataInventory')
      setDetail({
        product_name: dataInventory?.Name,
        notes: dataInventory?.notes,
        description: dataInventory?.description,
        valueSub: dataInventory?.valueSub,
        price: dataInventory?.price
      })
      const userNameNew =
        dataInventory?.data?.attributes?.artwork_id?.data?.attributes?.profile
          ?.data?.attributes?.users_permissions_user?.data?.attributes
          ?.username || ''
      setSelectedUsername({
        label: userNameNew,
        profileId:
          dataInventory?.data?.attributes?.artwork_id?.data?.attributes?.profile
            ?.data?.id
      })

      const artworkNameNew =
        dataInventory?.data?.attributes?.artwork_id?.data?.attributes?.Name ||
        ''

      const artworkIdNew =
        dataInventory?.data?.attributes?.artwork_id?.data?.id || ''
      setSelectedArtwordk({
        label: artworkNameNew,
        artworkId: artworkIdNew
      })
      setSelectedProductType({
        label:
          dataInventory?.data?.attributes?.type_id?.data?.attributes?.Name ||
          '',
        typeId: dataInventory?.data?.attributes?.type_id?.data?.id || ''
      })
      setSelectedProductSubType({
        label:
          dataInventory?.data?.attributes?.subtype_id?.data?.attributes?.Name ||
          '',
        subTypeId: dataInventory?.data?.attributes?.subtype_id?.data?.id || ''
      })
      setSelectedProductSubSubType({
        label:
          dataInventory?.data?.attributes?.subsubtype_id?.data?.attributes
            ?.Name || '',
        subSubTypeId:
          dataInventory?.data?.attributes?.subsubtype_id?.data?.id || ''
      })

      setImageId(dataInventory?.image?.id)

      setImageLink(dataInventory?.image?.url)
    }
  }, [dataInventory])

  const [
    createInventory,
    { data: dataCreateInventory, isLoading: loadingCreateInventory }
  ] = useCreateMutation()
  const [
    updateInventory,
    { data: dataUpdateInventory, isLoading: loadingUpdateInventory }
  ] = useUpdateMutation()
  const handleCreateInventory = async () => {
    try {
      const data = {
        name: detail.product_name,
        image_url: imageId !== null ? [+imageId] : [null],
        notes: detail.notes,
        description: detail.description,
        valueSub: detail.valueSub
      }
      if (id !== 'add') {
        const res = await updateInventory({
          id,
          data
        })
        if (res?.data?.id) {
          toast.success('Inventory Updated')
        }
        if (res?.error?.status === 409) {
          return toast.error('Inventory not updated')
        }
      } else {
        const res = await createInventory({
          data
        })
        if (res?.data?.id) {
          toast.success('Inventory created')
          setTimeout(() => {
            navigate('/admin/inventory', {
              state: { previous: location?.state?.previous }
            })
          }, 1000)
        }
        if (res?.error?.status === 409) {
          return toast.error('Inventory already exist')
        }
      }
    } catch (error) {
      console.log(error?.error?.status, 'ini error')
    }
  }

  console.log(detail)
  return (
    <>
      <div>
        <input
          onChange={e =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink,
              setImageId
            })
          }
          ref={fileRef}
          type='file'
          className='hidden'
        />
      </div>
      <div className='  mt-10 flex flex-row items-center justify-between gap-2'>
        <div
          className='cursor-pointer'
          onClick={() => {
            navigate(location?.state?.previous || '/admin/default')
          }}
        >
          <ArrowBackIcon />
        </div>

        <p className='font  flex w-full justify-items-center text-2xl font-bold'>
          {id === 'add' ? 'Add Inventory' : 'Edit Inventory'}
        </p>
      </div>
      <Stack gap={2} mt={5}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Stack flexDirection={'row'} gap={5}>
            <Stack gap={2}>
              <Typography>Product Icon</Typography>

              {loading ? (
                <Stack
                  height={100}
                  width={100}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <CircularProgress />
                </Stack>
              ) : (
                // <svg viewBox="0 0 32 32">
                //   <use fill="blue" href={imageLink} />
                // </svg>
                <>
                  <img src={imageLink} height={50} width={50} />
                  {handleTyphography(detail?.product_name)}
                </>
              )}
              <Button
                color='pink'
                variant='gradient'
                ripple='light'
                onClick={() => fileRef.current.click()}
              >
                Change
              </Button>
              <Typography>Price</Typography>
              <TextField
                label=''
                name='price'
                onChange={handleDetailChange}
                value={detail.price}
              />
            </Stack>
            <Stack width={500}>
              <Typography>Name</Typography>
              <TextField
                label=''
                name='product_name'
                onChange={handleDetailChange}
                value={detail.product_name}
              />
              <Typography>Notes</Typography>
              <Textarea
                label=''
                name='notes'
                onChange={handleDetailChange}
                value={detail.notes}
              />
              <Typography>Description</Typography>
              <Textarea
                label=''
                name='description'
                onChange={handleDetailChange}
                value={detail.description}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant='h5'>Sub</Typography>
            <Button
              onClick={() => {
                const newDetail = JSON.parse(JSON.stringify(detail))
                newDetail.valueSub.push({
                  id: `add-${detail.valueSub.length + 1}`,
                  value: '',
                  show: false,
                  variety: []
                })
                setDetail({ ...newDetail })
              }}
              size='sm'
              variant='outlined'
            >
              <Stack direction={'row'} gap={1}>
                <AddIcon />
                <Typography>Add Sub</Typography>
              </Stack>
            </Button>
          </Stack>
          {/* batas */}
          <Stack pl={2} gap={2}>
            {detail?.valueSub?.map((item, index) => (
              <>
                <Stack
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                >
                  <TextField
                    onChange={e => {
                      const newDetail = JSON.parse(JSON.stringify(detail))
                      newDetail.valueSub[index].value = e.target.value
                      setDetail({ ...newDetail })
                    }}
                    inputProps={{
                      style: {
                        fontWeight: 'bold',
                        fontSize: 18
                      }
                    }}
                    value={item.value}
                  />
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={1}
                    justifyContent={'space-between'}
                  >
                    <Button
                      onClick={() => {
                        Toast.fire({
                          title: 'Are you sure?',
                          text: "You won't be able to revert this!",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'Delete',
                          cancelButtonText: 'Cancel',
                          reverseButtons: true
                        }).then(result => {
                          if (result.isConfirmed) {
                            const newDetail = JSON.parse(JSON.stringify(detail))
                            newDetail.valueSub.splice(index, 1)
                            setDetail({ ...newDetail })
                            Toast.fire({
                              title: 'Deleted!',
                              icon: 'success'
                            })
                          }
                          return
                        })
                      }}
                      size='sm'
                      color='red'
                      variant='outlined'
                    >
                      <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <TrashIcon size={20} />
                        <Typography>Delete</Typography>
                      </Stack>
                    </Button>
                    <Button
                      onClick={() => {
                        console.log(item.show, 'item')
                        const newShow = !item.show
                        const newDetail = JSON.parse(JSON.stringify(detail))
                        newDetail.valueSub[index].show = newShow

                        setDetail({ ...newDetail })
                      }}
                      size='sm'
                      variant='outlined'
                    >
                      <Stack direction={'row'} gap={1}>
                        {item.show ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        <Typography>{item.show ? 'Hide' : 'Show'}</Typography>
                      </Stack>
                    </Button>
                  </Stack>
                </Stack>
                {/* variety */}
                {item?.variety?.map((item2, index2) => (
                  <>
                    <Stack
                      sx={{
                        display: item.show ? 'flex' : 'none'
                      }}
                      pl={5}
                      direction={'row'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <TextField
                        onChange={e => {
                          const newDetail = JSON.parse(JSON.stringify(detail))
                          newDetail.valueSub[index].variety[index2].value =
                            e.target.value
                          setDetail({ ...newDetail })
                        }}
                        inputProps={{
                          style: {
                            backgroundColor: 'white'
                          }
                        }}
                        value={item2.value}
                      />
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        gap={1}
                        justifyContent={'space-between'}
                      >
                        <Button
                          size='sm'
                          color='red'
                          variant='outlined'
                          onClick={() => {
                            Toast.fire({
                              title: 'Are you sure?',
                              text: "You won't be able to revert this!",
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Delete',
                              cancelButtonText: 'Cancel',
                              reverseButtons: true
                            }).then(result => {
                              if (result.isConfirmed) {
                                const newDetail = JSON.parse(
                                  JSON.stringify(detail)
                                )
                                newDetail.valueSub[index].variety.splice(
                                  index2,
                                  1
                                )
                                setDetail({ ...newDetail })
                                Toast.fire({
                                  title: 'Deleted!',
                                  text: 'Your file has been deleted.',
                                  icon: 'success'
                                })
                              }
                              return
                            })
                          }}
                        >
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={1}
                          >
                            <TrashIcon size={20} />
                            <Typography>Delete</Typography>
                          </Stack>
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                ))}
                <Stack
                  pl={5}
                  sx={{
                    display: item.show ? 'flex' : 'none'
                  }}
                >
                  <Button
                    size='sm'
                    variant='outlined'
                    onClick={() => {
                      const newDetail = JSON.parse(JSON.stringify(detail))
                      newDetail.valueSub[index].variety.push({
                        id: `add-${detail.valueSub[index].variety.length + 1}`,
                        value: ''
                      })
                      setDetail({ ...newDetail })
                    }}
                  >
                    <Stack
                      alignItems={'center'}
                      justifyContent={'center'}
                      direction={'row'}
                      gap={1}
                    >
                      <AddIcon />
                      <Typography>Add Variety</Typography>
                    </Stack>
                  </Button>
                </Stack>
              </>
            ))}
          </Stack>
        </Stack>
        <Button
          disabled={loadingCreateInventory}
          color='pink'
          variant='gradient'
          ripple='light'
          onClick={handleCreateInventory}
        >
          {loadingCreateInventory || loadingUpdateInventory ? (
            <CircularProgress color='inherit' size={20} />
          ) : (
            'Save'
          )}
        </Button>
      </Stack>
    </>
  )
}

export default InventoryDetails
