import { pagination } from "@nextui-org/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DateTime } from "luxon";
const baseUrl = process.env.REACT_APP_API_URL;

export const keywordApi = createApi({
  reducerPath: "keywordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Keyword"],
  endpoints: (builder) => ({
    getKeywords: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `keywords?pagination[start]=0&pagination[limit]=1000`;
        const filterUrl = filter ? `&filters[name][$containsi]=${filter}` : "";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { name } = item.attributes;

          return {
            id,
            label: name,
          };
        });
        const pagination = {
          pagination: response?.meta,
        };
        return {
          data: newData,
          meta: pagination,
        };
      },
      providesTags: ["Keyword"],
    }),
    addKeyword: builder.mutation({
      query: ({ data }) => ({
        url: "/keywords",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Keyword"],
    }),
  }),
});

export const { useGetKeywordsQuery, useAddKeywordMutation } = keywordApi;
