export const columnsCollection = [
  {
    Header: "COLLECTION NAME",
    accessor: "name",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "FIRST NAME",
    accessor: "first_name",
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
  },
  {
    Header: "TIME CREATED",
    accessor: "time_posted",
  },
];



