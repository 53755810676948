export const columnsSettings = [
  {
    Header: "Issue",
    accessor: "issue",
  },

  {
    Header: "Created At",
    accessor: "created_at",
  },
];
