import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import { ArtworkCard } from "components/card/ArtworkCard";
import { Pagination } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Carousel = ({ data }) => {
  const { pathname } = useLocation();

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const swiperRef = useRef(null); // Add this to get a reference to Swiper

  const nextPage = () => {
    if (currentPage === totalPage) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage === 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (swiperRef.current) {
      swiperRef.current.slideTo(value - 1); // Swiper indices start from 0
    }
  };

  useEffect(() => {
    setTotalPage(Math.ceil(data?.length - 4) + 1);
  }, [data, currentPage, totalPage]);

  return (
    <>
      <div>
        <Swiper
          spaceBetween={5}
          slidesPerView={4}
          onSlideChange={(swiper) => {
            setCurrentPage(swiper.activeIndex + 1); // update current page on slide change
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          modules={[Navigation]}
          navigation={true}
        >
          {data?.map((item, index) => (
            <SwiperSlide key={index}>
              <ArtworkCard data={item} previous={pathname} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="mt-10 flex items-center justify-center">
        <Pagination
          count={totalPage}
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
          page={currentPage}
        />
      </div>
    </>
  );
};

export default Carousel;
