import {
  MdPerson,
  MdOutlineCollections,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { BsPaletteFill } from "react-icons/bs";
import { CgOrganisation } from "react-icons/cg";

import Widget from "components/widget/Widget";
import { FaChalkboardTeacher } from "react-icons/fa";
import {
  useGetArtistQuery,
  useGetArtworkQuery,
} from "../../../redux/Services/artist";
import { useGetUserListQuery } from "redux/Services/user";
import { useCollectionListQuery } from "redux/Services/collection";
import { useGetOrdersQuery } from "redux/Services/order";
import { useGetProfileOrgQuery } from "redux/Services/organisation";
import { Skeleton } from "@mui/material";

const Dashboard = () => {
  const { data: dataArtist, isLoading: isLoadingArtist } = useGetArtistQuery({
    withCount: true,
  });
  const { data: dataArtwork, isLoading: isLoadingArtwork } = useGetArtworkQuery(
    { withCount: true }
  );
  const { data: dataArtLover, isLoading: isLoadingArtLover } =
    useGetUserListQuery({ withCount: true });
  const { data: dataCollection, isLoading: isLoadingCollection } =
    useCollectionListQuery({ withCount: true });
  const { data: dataOrders, isLoading: isLoadingOrders } = useGetOrdersQuery({
    withCount: true,
  });
  console.log(dataOrders, "<>syg");
  const { data: dataProfileOrg, isLoading: isLoadingProfileOrg } =
    useGetProfileOrgQuery({ withCount: true });
  return (
    <div>
      {/* Card widget */}

      <div className="mt-10 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<BsPaletteFill className="h-7 w-7" />}
          title={"Total Artworks"}
          subtitle={
            isLoadingArtwork ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataArtwork?.meta?.total
            )
          }
        />
        <Widget
          icon={<FaChalkboardTeacher className="h-6 w-6" />}
          title={"Total Artist"}
          subtitle={
            isLoadingArtist ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataArtist?.meta?.pagination?.total
            )
          }
        />
        <Widget
          icon={<MdPerson className="h-7 w-7" />}
          title={"Total Art Lover"}
          subtitle={
            isLoadingArtLover ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataArtLover?.meta?.pagination?.total
            )
          }
        />
        <Widget
          icon={<MdOutlineCollections className="h-6 w-6" />}
          title={"Total Collection"}
          subtitle={
            isLoadingCollection ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataCollection?.meta?.pagination?.total || 0
            )
          }
        />
        <Widget
          icon={<MdOutlineShoppingCart className="h-7 w-7" />}
          title={"Total Orders"}
          subtitle={
            isLoadingOrders ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataOrders?.meta?.pagination?.total || 0
            )
          }
        />
        <Widget
          icon={<CgOrganisation className="h-6 w-6" />}
          title={"Total Organisation"}
          subtitle={
            isLoadingProfileOrg ? (
              <Skeleton variant="text" width={30} />
            ) : (
              dataProfileOrg?.meta?.pagination?.total || 0
            )
          }
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div> */}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
