import React from "react";

// Admin Imports
import MainDashboard from "views/admin/dashboard";

import Profile from "views/admin/profile";
import CollectionView from "views/admin/administration/collection";

// Auth Imports

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdOutlineMood,
  MdOutlineCollections,
  MdPerson,
  MdLock,
} from "react-icons/md";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { FaChalkboardTeacher } from "react-icons/fa";
import { BiCommentDetail } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import InventoryIcon from "@mui/icons-material/Inventory";
import { BsPersonBadgeFill } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import UserView from "views/admin/administration/user";
import CommentView from "views/admin/administration/comment";
import SettingsView from "views/admin/order_settings/settings/settingsView";
import OrdersView from "views/admin/order_settings/orders";
import MoodCategoriesView from "views/admin/content_management/mood";
import SignIn from "views/auth/SignIn";
import ArtistView from "views/admin/content_management/artist";
import OrganisationView from "views/admin/content_management/organisation";
import OnboardingView from "views/admin/content_management/onboarding";
import UserDetails from "views/admin/administration/user/component/UserDetails";
import ArtworkDetails from "views/admin/content_management/artist/component/ArtworkDetails";
import CollectionDetail from "views/admin/administration/collection/component/CollectionDetail";
import AwardDetails from "views/admin/content_management/artist/component/AwardDetails";
import ExhibitionDetail from "views/admin/content_management/artist/component/ExhibitionDetail";
import OrganisationProfileDetail from "views/admin/content_management/organisation/component/OrganisationProfileDetails";
import InitiativeDetail from "views/admin/content_management/organisation/component/InitiativeDetails";
import ArtPreferenceDetails from "views/admin/content_management/onboarding/component/ArtPreferenceDetails";
import MotivationDetails from "views/admin/content_management/onboarding/component/MotivationDetails";
import MoodDetails from "views/admin/content_management/mood/MoodDetails";
import OrderDetail from "views/admin/order_settings/orders/component/OrderDetails";
import SettingsDetail from "views/admin/order_settings/settings/settingsDetail";
import IssueTypeDetail from "views/admin/order_settings/IssueTypeDetail";
import PromoView from "views/admin/administration/promo";
import AddPromo from "views/admin/administration/promo/AddPromo";
import InventoryView from "views/admin/administration/inventory/InventoryView";
import InventoryDetails from "views/admin/administration/inventory/InventoryDetails";
import ChangePassword from "views/admin/administration/changePassword/changePasswordPage";
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    isDetail: false,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserView />,
    secondary: true,
    isDetail: false,
  },
  {
    name: "Comments",
    layout: "/admin",
    icon: <BiCommentDetail className="h-6 w-6" />,
    path: "comments",
    component: <CommentView />,
    isDetail: false,
  },
  {
    name: "Collection",
    layout: "/admin",
    path: "collection",
    icon: <MdOutlineCollections className="h-6 w-6" />,
    component: <CollectionView />,
    isDetail: false,
  },
  {
    name: "Artist",
    layout: "/admin",
    path: "artist",
    icon: <FaChalkboardTeacher className="h-6 w-6" />,
    component: <ArtistView />,
    isDetail: false,
  },
  {
    name: "Organisation",
    layout: "/admin",
    path: "organisation",
    icon: <CgOrganisation className="h-6 w-6" />,
    component: <OrganisationView />,
    isDetail: false,
  },
  {
    name: "Inventory",
    layout: "/admin",
    path: "inventory",
    icon: <InventoryIcon className="h-6 w-6" />,
    component: <InventoryView />,
    isDetail: false,
  },
  {
    name: "Inventory",
    layout: "/admin",
    path: "inventory/:id",
    icon: <InventoryIcon className="h-6 w-6" />,
    component: <InventoryDetails />,
    isDetail: true,
  },
  {
    name: "Onboarding",
    layout: "/admin",
    path: "onboarding",
    icon: <BsPersonBadgeFill className="h-6 w-6" />,
    component: <OnboardingView />,
    isDetail: false,
  },

  {
    name: "Mood Categories",
    layout: "/admin",
    path: "mood-categories",
    icon: <MdOutlineMood className="h-6 w-6" />,
    component: <MoodCategoriesView />,
    isDetail: false,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "orders",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <OrdersView />,
    isDetail: false,
  },
  {
    name: "Promo",
    layout: "/admin",
    path: "promo",
    icon: <ConfirmationNumberIcon className="h-6 w-6" />,
    component: <PromoView />,
    isDetail: false,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <AiOutlineSetting className="h-6 w-6" />,
    component: <SettingsView />,
    isDetail: false,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    isDetail: true,
  },
  {
    name: "User Details",
    layout: "/admin",
    path: "user-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <UserDetails />,
    isDetail: true,
  },
  {
    name: "Artist Details",
    layout: "/admin",
    path: "artist/profile-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <UserDetails />,
    isDetail: true,
  },
  {
    name: "Collection Details",
    layout: "/admin",
    path: "/collection/collection-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <CollectionDetail />,
    isDetail: true,
  },
  {
    name: "Artwork Details",
    layout: "/admin",
    path: "artist/artwork-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <ArtworkDetails />,
    isDetail: true,
  },
  {
    name: "Award Details",
    layout: "/admin",
    path: "artist/award-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <AwardDetails />,
    isDetail: true,
  },
  {
    name: "Exhibition Details",
    layout: "/admin",
    path: "artist/exhibition-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <ExhibitionDetail />,
    isDetail: true,
  },
  {
    name: "User Details",
    layout: "/admin",
    path: "users/user-details/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <UserDetails />,
    isDetail: true,
  },
  {
    name: "Organisation Profile Details",
    layout: "/admin",
    path: "organisation/profile/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <OrganisationProfileDetail />,
    isDetail: true,
  },
  {
    name: "Organisation Initiative Details",
    layout: "/admin",
    path: "organisation/initiative/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <InitiativeDetail />,
    isDetail: true,
  },
  {
    name: "Art Preference Details",
    layout: "/admin",
    path: "onboarding/preference/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <ArtPreferenceDetails />,
    isDetail: true,
  },
  {
    name: "Art Motivation Details",
    layout: "/admin",
    path: "onboarding/motivation/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <MotivationDetails />,
    isDetail: true,
  },
  {
    name: "Mood Category Details",
    layout: "/admin",
    path: "mood-categories/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <MoodDetails />,
    isDetail: true,
  },
  {
    name: "Seting Detail",
    layout: "/admin",
    path: "settings/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <SettingsDetail />,
    isDetail: true,
  },
  {
    name: "Order Detail",
    layout: "/admin",
    path: "orders/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <OrderDetail />,
    isDetail: true,
  },
  {
    name: "Issue Type Detail",
    layout: "/admin",
    path: "settings/type/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <IssueTypeDetail />,
    isDetail: true,
  },
  {
    name: "Add Promo",
    layout: "/admin",
    path: "promo/:id",
    icon: <MdLock className="h-6 w-6" />,
    component: <AddPromo />,
    isDetail: true,
  },
  {
    name: "Change Password",
    layout: "/admin",
    path: "change-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ChangePassword />,
    isDetail: true,
  },
];
export default routes;
