import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = process.env.REACT_APP_API_URL;

export const promoApi = createApi({
  reducerPath: "promoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Promo"],
  endpoints: (builder) => ({
    getPromos: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        url: `/promos?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[name][$containsi]=${filter}`,
        method: "GET",
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { name, code, start_date, end_date, is_active } =
            item?.attributes;
          const startDateFormat = start_date
            ? new Date(start_date).toLocaleDateString()
            : "";
          const endDateFormat = end_date
            ? new Date(end_date).toLocaleDateString()
            : "";
          const date = startDateFormat + " - " + endDateFormat;
          return {
            id,
            name,
            code,
            date,
            status: is_active ? "Published" : "Draft",
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Promo"],
    }),
    createPromo: builder.mutation({
      query: ({ data }) => ({
        url: "/promos",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Promo"],
    }),
    updatePromo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/promos/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Promo"],
    }),
    deletePromo: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::promo.promo&id_select=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Promo"],
    }),
    getPromoById: builder.query({
      query: ({ id }) => ({
        url: `/promos/${id}`,
        method: "GET",
      }),
      providesTags: ["Promo"],
    }),
    updateActivePromo: builder.mutation({
      query: ({ id, data }) => ({
        url: `/promos/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Promo"],
    }),
  }),
});

export const {
  useGetPromosQuery,
  useCreatePromoMutation,
  useUpdatePromoMutation,
  useDeletePromoMutation,
  useGetPromoByIdQuery,
  useUpdateActivePromoMutation,
} = promoApi;
