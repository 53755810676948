import React, { Suspense, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

import Tables from "components/table/index";

import { useCollectionListQuery } from "redux/Services/collection";
import { columnsCollection } from "./variables/columnsData";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

const CollectionView = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selected, setSelected] = useState([]);

  const { data, isFetching } = useCollectionListQuery({
    page,
    pageSize,
    filter: debouncedSearchTerm,
  });

  return (
    <Suspense fallback={isFetching && <div>Loading...</div>}>
      <div className="mt-5">
        <div className="mb-5 flex justify-end">
          {/* <Button
            onClick={() =>
              navigate(`/admin/collection/collection-details/add`, {
                state: { previous: "/admin/collection" },
              })
            }
            color="pink"
            variant="gradient"
          >
            Add Collection
          </Button> */}
        </div>
        <Tables
          columnsData={columnsCollection}
          tableData={data?.data || []}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          pageProps={page}
          setPage={setPage}
          totalPage={data?.meta}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </Suspense>
  );
};
export default CollectionView;
