import { Input, Typography, Button } from "@material-tailwind/react";

// import { Button } from "@mui/material";
import { useCollectionDetailQuery } from "redux/Services/collection";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEditCollectionMutation } from "redux/Services/collection";
import CircularProgress from "@mui/material/CircularProgress";
import { Toaster, toast } from "sonner";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { handleUploadArto } from "helpers/uploadFile";
import Carousel from "components/carousel";
import AutoCompleteUsername from "components/autocomplete/username";
import MultipleAutocomplete from "components/autocomplete/multiple";
import { artoImagePlacehoder } from "helpers/placeholder";
import { useGetCategoriesQuery } from "redux/Services/category";

// import { set } from "react-hook-form";

// import { set } from "react-hook-form";
const CollectionDetail = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();

  const fileRef = useRef(null);
  const { id } = useParams();
  // console.log(
  //   "🚀 ~ file: CollectionDetail.jsx:35 ~ CollectionDetail ~ id:",
  //   id
  // );

  const {
    data: detailCollectionId,
    isFetching,
    isError,
  } = useCollectionDetailQuery({ id: id }, { skip: !id });
  // const {
  //   data: dataProfile,
  //   isFetching: isFetchingProfile,
  //   isError: isErrorProfile,
  // } = useGetUserListQuery({
  //   page: 1,
  //   pageSize: 10,
  //   withCount: false,
  //   userName: debouncedSearchTerm,
  // });

  const [collectionName, setCollectionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedUsername, setSelectedUsername] = useState("");
  const [imageLink, setImageLink] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState("");
  const [uploadImage, setUploadImage] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const onChangeCollectionNameAndDescription = (e) => {
    const { name, value } = e.target;
    if (name === "collectionName") {
      setCollectionName(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };
  useEffect(() => {
    if (detailCollectionId && firstFetch) {
      setCollectionName(detailCollectionId?.collectionName);
      setDescription(detailCollectionId?.description);
      setSelectedUsername({
        label: detailCollectionId?.userName,
        profileId: detailCollectionId?.profileId,
      });
      setImageLink(detailCollectionId?.avatarUrl);
      setImageId(detailCollectionId?.avatarId);
      setFirstFetch(false);
      setSelectedCategory(detailCollectionId?.collectionOfTags);
    }
    // if (dataProfile) {
    //   const collectionOfUserName = dataProfile?.data?.map((item) => {
    //     return {
    //       label: item.userName,
    //       profileId: item.id,
    //     };
    //   });
    //   setCollectionUserName(collectionOfUserName);
    // }
  }, [detailCollectionId]);
  const handleClickUpload = () => {
    fileRef.current.click();
  };

  const [
    editCollection,
    {
      isLoading: isFetchingEditCollection,
      data: dataResponseEdit,
      error: errorEdit,
    },
  ] = useEditCollectionMutation();
  const { data: dataCategory, isFetching: fetchingCategory } =
    useGetCategoriesQuery();
  useEffect(() => {
    if (dataResponseEdit) {
      toast.success("Edit collection success");
    }
  }, [dataResponseEdit]);
  const handleSumbit = async (featuredClick, hiddenClick) => {
    try {
      const data = {
        description: description,
        name: collectionName,
        avatar: {
          id: imageId,
        },
        profile_id: {
          id: selectedUsername?.profileId,
        },
        featured: featuredClick
          ? !detailCollectionId?.featured
          : detailCollectionId?.featured,
        hidden: hiddenClick
          ? !detailCollectionId?.hidden
          : detailCollectionId?.hidden,
      };
      const response = await editCollection({
        id: id,
        data: { data },
      });
      // dispatch(addCounter());
    } catch (error) {
      console.log(error, "<>");
    }
  };
  return (
    // <div class="grid grid-flow-col grid-rows-3 gap-4">
    //   <div class="... row-span-3">
    <>
      <div className="mt-10 w-full justify-between">
        <div>
          <input
            onChange={(e) =>
              handleUploadArto({
                loadingFunction: setUploadImage,
                files: e.target.files[0],
                setImageLink,
                setImageId,
              })
            }
            ref={fileRef}
            type="file"
            className="hidden"
          />
        </div>

        <div className=" ml-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
            }}
          >
            <ArrowBackIcon />
          </div>

          <p className="font  flex w-full justify-items-center text-2xl font-bold">
            {id === "add" ? "Add Collection" : "Collection Detail"}
          </p>
          {id !== "add" && (
            <>
              {detailCollectionId?.featured ? (
                <LoadingButton
                  loading={isFetchingEditCollection}
                  variant="outlined"
                  sx={{
                    paddingX: "3rem",
                  }}
                  color="success"
                  onClick={() => handleSumbit(true, false)}
                >
                  Featured
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isFetchingEditCollection}
                  onClick={() => handleSumbit(true, false)}
                  variant="outlined"
                  sx={{
                    paddingX: "3rem",
                  }}
                  color="info"
                >
                  Unfeatured
                </LoadingButton>
              )}

              {detailCollectionId?.hidden ? (
                <LoadingButton
                  loading={isFetchingEditCollection}
                  onClick={() => handleSumbit(false, true)}
                  sx={{
                    paddingX: "3rem",
                  }}
                  variant="outlined"
                  color="error"
                  startIcon={<VisibilityOffIcon />}
                >
                  Hidden
                </LoadingButton>
              ) : (
                <LoadingButton
                  loading={isFetchingEditCollection}
                  onClick={() => handleSumbit(false, true)}
                  sx={{
                    paddingX: "3rem",
                  }}
                  variant="outlined"
                  color="success"
                  startIcon={<VisibilityIcon />}
                >
                  Visible
                </LoadingButton>
              )}
            </>
          )}
        </div>

        <div className="flex-cols-2 flex justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex flex-col justify-center gap-1">
            {uploadImage ? (
              <div className="flex h-96 w-96 items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <img
                className="h-96 w-full rounded-lg object-cover object-center"
                src={imageLink}
                alt="nature image"
              />
            )}
            {/* <Button
              className="mt-3"
              color="pink"
              variant="gradient"
              onClick={handleClickUpload}
            >
              Edit Thumbnail
            </Button> */}
          </div>

          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Collection Name
              </Typography>
              <Input
                value={collectionName}
                name="collectionName"
                onChange={onChangeCollectionNameAndDescription}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Description
              </Typography>
              <textarea
                id="description"
                name="description"
                value={description}
                onChange={onChangeCollectionNameAndDescription}
                rows="4"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Write your thoughts here..."
              ></textarea>
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Name of Uncurator (username)
              </Typography>
              {/* <Autocomplete
                size="small"
                fullWidth
                disablePortfral
                id="combo-box-demo"
                options={collectionUserName || []}
                sx={{ backgroundColor: "white" }}
                value={selectedUsername}
                onChange={(event, newValue) => {
                  event?.preventDefault();
                  setSelectedUsername(newValue);
                }}
                onInputChange={(event, newValue) => {
                  event?.preventDefault();
                  setSearchTerm(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              /> */}
              <AutoCompleteUsername
                selectedUsername={selectedUsername}
                setSelectedUsername={setSelectedUsername}
                detailCollectionId={detailCollectionId}
              />
            </div>
            <div className="w-6/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Tags
              </Typography>
              {dataCategory && selectedCategory.length > 0 && (
                <MultipleAutocomplete
                  data={dataCategory}
                  loading={fetchingCategory}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
              {id === "add" && dataCategory && (
                <MultipleAutocomplete
                  data={dataCategory}
                  loading={fetchingCategory}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
              )}
            </div>
            <div className="mt-20 flex w-full items-center justify-center gap-4">
              {isFetchingEditCollection ? (
                <CircularProgress />
              ) : (
                <Button
                  fullWidth
                  color="pink"
                  variant="gradient"
                  onClick={() => handleSumbit(false, false)}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </div>
        <p className="font  mb-10 ml-10 mt-20 flex w-full justify-items-center text-2xl font-bold">
          List Of Artwork
        </p>
        <Carousel data={detailCollectionId?.collectionOfArtwork} />
      </div>
    </>
  );
};
export default CollectionDetail;
