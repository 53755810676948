import Tables from "components/table/index";
import { faker } from "@faker-js/faker";

import { columnsUser } from "./variables/columnsData";
import { useGetUserListQuery } from "../../../../redux/Services/user";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

let users = [];

function buildFakeUser() {
  return {
    first_name: faker.person.firstName(),
    last_name: faker.person.lastName(),
    country: faker.person.jobArea(),
    last_login: faker.person.jobArea(),
    return_login: faker.person.jobArea(),
  };
}

for (var i = 0; i < 25; i++) {
  users.push(buildFakeUser());
}

const UserView = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const {
    data: dataUser,
    isFetching,
    error,
  } = useGetUserListQuery({ page, pageSize, filter: debouncedSearchTerm });
  const [selected, setSelected] = useState([]);
  return (
    <div className="mt-5">
      {/* <SortableTable/> */}
      <Tables
        columnsData={columnsUser}
        tableData={dataUser?.data || []}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={dataUser?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default UserView;
