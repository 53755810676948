import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";

export function ArtworkCard({ data, previous }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const handleClickCarousel = () => {
    navigate(`/admin/artist/artwork-details/${data?.id}`, {
      state: { previous: `${previous}` },
    });
  };
  return (
    <Card className="w-60 cursor-pointer" onClick={handleClickCarousel}>
      <CardHeader floated={false}>
        <div className="relative h-60 overflow-hidden">
          <img
            src={data?.url}
            alt="profile-picture"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        </div>
      </CardHeader>
      <CardBody className="text-center">
        <Typography variant="h4" color="blue-gray" className="mb-2">
          {data?.name.length > 15
            ? `${data?.name.slice(0, 13)}...`
            : data?.name}
        </Typography>
        {/* <Typography color="blue-gray" className="font-medium" textGradient>
          Artist Name
        </Typography> */}
      </CardBody>
      {/* <CardFooter className="flex justify-center gap-7 pt-2">
        <Tooltip content="Like">
          <Typography
            as="a"
            href="#facebook"
            variant="lead"
            color="blue"
            textGradient
          >
            <i className="fab fa-facebook" />
          </Typography>
        </Tooltip>
        <Tooltip content="Follow">
          <Typography
            as="a"
            href="#twitter"
            variant="lead"
            color="light-blue"
            textGradient
          >
            <i className="fab fa-twitter" />
          </Typography>
        </Tooltip>
        <Tooltip content="Follow">
          <Typography
            as="a"
            href="#instagram"
            variant="lead"
            color="purple"
            textGradient
          >
            <i className="fab fa-instagram" />
          </Typography>
        </Tooltip>
      </CardFooter> */}
    </Card>
  );
}
