export const columnPromo = [
  {
    Header: "",
    accessor: "_id",
  },
  {
    Header: "Promo Name",
    accessor: "name",
  },
  {
    Header: "Promo Code",
    accessor: "code",
  },
  {
    Header: "Date Range",
    accessor: "date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];
