import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import OrganisationProfileDetail from "views/admin/content_management/organisation/component/OrganisationProfileDetails";
import InitiativeDetail from "views/admin/content_management/organisation/component/InitiativeDetails";
import ArtPreferenceDetails from "views/admin/content_management/onboarding/component/ArtPreferenceDetails";
import MoodDetails from "views/admin/content_management/mood/MoodDetails";
import UserDetails from "views/admin/administration/user/component/UserDetails";

const App = () => {
  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      {/* <Route path="user/detail" element={<UserDetails />} /> */}
      <Route path="/detail" element={<UserDetails />} />

      <Route path="/" element={<Navigate to="/admin" replace />} />
    </Routes>
  );
};

export default App;
