import Tables from "components/table/index";
import { faker } from "@faker-js/faker";

import { columnsSettings } from "./variables/columnsData";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useListContactUsQuery } from "redux/Services/contactUs";
import { useListIssueTypeQuery } from "redux/Services/contactUs";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { Button } from "@material-tailwind/react";
let settings = [];

// function buildFakeDt() {
//   return {
//     date_of_ticket: faker.person.middleName(),
//     type: faker.person.sexType(),
//     answered: faker.person.suffix(),

//   };
// }

// for(var i = 0; i < 25; i++) {
//   settings.push(buildFakeDt())
// }

const IssueType = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const [selected, setSelected] = useState([]);
  const { data: dataSettings } = useListIssueTypeQuery({
    page: page,
    pageSize: pageSize,
    filter: debouncedSearchTerm,
  });
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="">
      <Stack alignItems={"end"} mb={2}>
        <Button
          className="mb-3 mt-3 w-1/5"
          color="pink"
          variant="gradient"
          onClick={() => {
            navigate(`/admin/settings/type/add`, {
              state: { previous: location.pathname + "?label=type" },
            });
          }}
        >
          Add Issue Type
        </Button>
      </Stack>

      <Tables
        columnsData={columnsSettings}
        tableData={dataSettings?.data || []}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={dataSettings?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default IssueType;
