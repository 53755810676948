import React, { useEffect } from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function TablePagination(props) {
  const { page, setPage, totalPage } = props;
  const [active, setActive] = React.useState(1);

  const next = () => {
    // if (active === 10) return;

    setPage(page + 1);
  };

  const prev = () => {
    // if (active === 1) return;

    setPage(page - 1);
  };
  // useEffect(() => {}, [page]);
  const totalPageReal =
    +totalPage?.pagination?.pageCount || +totalPage?.totalPages;

  return (
    <div className="flex justify-center gap-8">
      <IconButton
        size="sm"
        variant="outlined"
        onClick={prev}
        disabled={page === 1}
        className="flex items-center justify-center"
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      <Typography color="gray" className="font-normal">
        Page <strong className="text-gray-900">{page}</strong> of{" "}
        <strong className="text-gray-900">
          {totalPage?.pagination?.pageCount || totalPage?.totalPages || ""}
        </strong>
      </Typography>
      <IconButton
        size="sm"
        variant="outlined"
        onClick={next}
        disabled={totalPageReal === page}
        className="flex items-center justify-center"
      >
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
    </div>
  );
}
