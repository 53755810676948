export const columnInventory = [
  {
    Header: "",
    accessor: "_id",
  },
  {
    Header: "Name",
    accessor: "name",
  },

  // {
  //   Header: "Product Type",
  //   accessor: "Name",
  // },
  // {
  //   Header: "Artwork Name",
  //   accessor: "artwork_name",
  // },
  // {
  //   Header: "Price",
  //   accessor: "product_price",
  // },
];
