import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
// access ENV .url
import { DateTime } from "luxon";

const baseUrl = process.env.REACT_APP_API_URL;

export const collectionApi = createApi({
  reducerPath: "collectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Collection"],
  endpoints: (builder) => ({
    collectionList: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `collections?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=profile_id,tags_id`;
        const filterUrl = filter ? `&filters[name][$containsi]=${filter}` : "";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { name, profile_id, createdAt, description } = item?.attributes;
          const First_Name = profile_id?.data?.attributes?.First_Name;
          const Last_Name = profile_id?.data?.attributes?.Last_Name;
          // const artwork_name = artwork_id?.data?.attributes?.Name;
          const dateTime = DateTime.fromISO(createdAt);
          const formattedDate = dateTime.toFormat("yyyy-MM-dd");
          return {
            id,
            name,
            time_posted: formattedDate,
            first_name: First_Name,
            last_name: Last_Name,
            description,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Collection"],
    }),
    collectionDetail: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `collections/${id}?populate=profile_id.users_permissions_user,avatar,artworks_id.url_file,tags_id`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const id = data?.id;
        const {
          name,
          profile_id,
          createdAt,
          description,
          featured,
          hidden,
          avatar,
          artworks_id,
          tags_id,
        } = data?.attributes;
        const profileId = profile_id?.data?.id;
        const userId =
          profile_id?.data?.attributes?.users_permissions_user?.data?.id;
        const userName =
          profile_id?.data?.attributes?.users_permissions_user?.data?.attributes
            ?.username;
        const avatarUrl =
          avatar?.data?.attributes?.formats?.thumbnail?.url ||
          artoImagePlacehoder;
        const avatarId = avatar?.data?.id;
        const collectionOfArtwork =
          artworks_id?.data?.length > 0
            ? artworks_id?.data?.map((item) => {
                const { Name, url_file } = item?.attributes;
                return {
                  id: item?.id,
                  name: Name,
                  url:
                    url_file?.data?.attributes?.formats?.thumbnail?.url ||
                    artoImagePlacehoder,
                };
              })
            : [];
        const collectionOfTags =
          tags_id?.data?.length > 0
            ? tags_id?.data?.map((item) => {
                const id = item?.id;
                const { Name } = item?.attributes;
                return {
                  id,
                  label: Name,
                };
              })
            : [];

        const newResponse = {
          avatarId,
          avatarUrl,
          featured,
          hidden,
          collectionId: id,
          collectionName: name,
          profileId,
          userId,
          userName,
          description,
          collectionOfArtwork,
          collectionOfTags,
        };
        return newResponse;
      },
      providesTags: (result, error, { id }) => [{ type: "Collection", id }],
    }),
    editCollection: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `collections/${id}`,
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Collection", id },
        "Collection",
      ],
    }),
    deleteCollection: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::collection.collection&id_select=${id}`,
      }),
      invalidatesTags: ["Collection"],
    }),
  }),
});

export const {
  useCollectionListQuery,
  useCollectionDetailQuery,
  useEditCollectionMutation,
  useDeleteCollectionMutation,
} = collectionApi;
