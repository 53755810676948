import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import queryString from "query-string";

import ArtPreferenceTable from "./component/ArtPreferenceTable";
import MotivationTable from "./component/MotivationTable";
import { useLocation, useNavigate } from "react-router-dom";

const data = [
  {
    label: "Art Preference",
    value: "preference",
    component: <ArtPreferenceTable />,
  },
  {
    label: "Motivation",
    value: "motivation",
    component: <MotivationTable />,
  },
];

const OnboardingView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { label } = queryString.parse(location.search);
  return (
    <div className="mt-10">
      <Tabs
        id="custom-animation"
        value={label ? label?.toLowerCase() : "art_preference"}
      >
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab
              activeClassName="font-bold"
              key={value}
              value={value}
              onClick={() => {
                // add query every klik base on label
                navigate(`?label=${label}`);
              }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default OnboardingView;
