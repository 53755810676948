import React, { useEffect } from "react";
import { Input, Typography, Button } from "@material-tailwind/react";
import { Stack } from "@mui/material";
import ArtkworkDetailType from "./ArtkworkDetailType";
import { useGetAllTypeQuery } from "redux/Services/inventory";
import { useParams } from "react-router-dom";

const ArtworkDetailUploader = ({
  price,
  handleChange,
  merchandises,
  setMerchandises,
  note,
  setNote,
  description,
  setDescription,
  papperA2,
  setPapperA2,
  papperA3,
  setPapperA3,
  dataDetail,
}) => {
  const { id } = useParams();
  const { data: dataType, isLoading } = useGetAllTypeQuery({
    page: 1,
    pageSize: 100,
    additional:
      "&filters[$and][0][Name][$ne]=Prints&filters[$and][1][Name][$ne]=Original Artwork",
  });

  return (
    <div className="w-full">
      <div className="basis-1/4">
        <Stack width={630} gap={2}>
          <Typography
            variant="small"
            color="grey"
            className=" mt-2 font-medium"
          >
            Price of Orginal Artwork (USD)
          </Typography>
          <Input
            fullwidth
            type="number"
            name="price"
            value={price}
            onChange={(e) => handleChange(e, "price")}
          />
          <Typography
            variant="medium"
            color="grey"
            className="mb-4 mt-2 font-bold"
          >
            Art Prints
            <Stack pl={4} direction={"row"} alignItems={"center"} gap={4}>
              <Stack gap={3} mt={4}>
                <Typography
                  variant="small"
                  color="grey"
                  className=" mt-2 font-medium"
                >
                  PRICE (USD)
                </Typography>
                <Typography
                  variant="small"
                  color="grey"
                  className=" mt-2 font-medium"
                >
                  QUANTITIES
                </Typography>
              </Stack>
              <Stack gap={2}>
                <Typography
                  variant="small"
                  color="grey"
                  className="mt-2 flex items-center justify-center font-medium"
                >
                  A2
                </Typography>
                <Input
                  fullwidth
                  type="number"
                  name="price"
                  value={papperA2.price_paper_A2}
                  onChange={(e) =>
                    setPapperA2({ ...papperA2, price_paper_A2: e.target.value })
                  }
                />
                <Input
                  fullwidth
                  type="number"
                  name="price"
                  value={papperA2.quantity_paper_A2}
                  onChange={(e) =>
                    setPapperA2({
                      ...papperA2,
                      quantity_paper_A2: e.target.value,
                    })
                  }
                />
              </Stack>
              <Stack gap={2}>
                <Typography
                  variant="small"
                  color="grey"
                  className=" mt-2 flex items-center justify-center font-medium"
                >
                  A3
                </Typography>
                <Input
                  fullwidth
                  value={papperA3.price_paper_A3}
                  type="number"
                  name="price"
                  onChange={(e) =>
                    setPapperA3({ ...papperA3, price_paper_A3: e.target.value })
                  }
                />
                <Input
                  fullwidth
                  type="number"
                  name="price"
                  value={papperA3.quantity_paper_A3}
                  onChange={(e) =>
                    setPapperA3({
                      ...papperA3,
                      quantity_paper_A3: e.target.value,
                    })
                  }
                />
              </Stack>
            </Stack>
            <Stack pl={4} mb={4}>
              <Stack>
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-2 mt-2 font-medium"
                >
                  DESCRIPTION
                </Typography>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  id="description"
                  rows="4"
                  class="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  // placeholder="Write your thoughts here..."
                />
              </Stack>
              <Stack>
                <Typography
                  variant="small"
                  color="grey"
                  className="mb-2 mt-2 font-medium"
                >
                  NOTE
                </Typography>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  name="description"
                  id="description"
                  rows="4"
                  class="block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  // placeholder="Write your thoughts here..."
                />
              </Stack>
            </Stack>
            <Typography
              variant="medium"
              color="grey"
              className="mb-2 mt-2 font-bold"
            >
              What merchandises would you wish to sell this artwork in?
            </Typography>
          </Typography>
          {!isLoading &&
            dataType?.data?.map((item, index) => (
              <ArtkworkDetailType
                title={item.name}
                keyIndex={index}
                id={item.id}
                merchandises={merchandises}
                setMerchandises={setMerchandises}
                key={item.id}
                dataArtwork={dataDetail}
              />
            ))}
          {/* <ArtkworkDetailType title={"Phone Covers"} />
          <ArtkworkDetailType title={"Macbook Sleeve"} />
          <ArtkworkDetailType title={"T-shirt"} /> */}
        </Stack>
      </div>
    </div>
  );
};

export default ArtworkDetailUploader;
