import { artoImagePlacehoder } from "helpers/placeholder";
import React from "react";

const OrderDetailCard = ({ item }) => {
  console.log(item, "ini item cekk");
  return (
    <div
      key={item.id}
      className="mt-4 flex w-full  flex-col items-start justify-start md:mt-6 md:flex-row md:items-center md:space-x-6 xl:space-x-8 "
    >
      <div className="w-full pb-4 md:w-40 md:pb-8">
        <img
          className="hidden w-full md:block"
          src={item?.product_image || artoImagePlacehoder}
          alt="dress"
        />
      </div>
      <div className="flex w-full flex-col items-start justify-between space-x-4 space-y-4 border-b border-gray-200  pb-8 md:flex-row md:space-y-0">
        <div className="flex w-full flex-col items-start justify-start space-y-8">
          <h3 className="text-xl font-semibold leading-6 text-gray-800 xl:text-2xl">
            {item?.product_name}
          </h3>
          <div className="flex flex-col items-start justify-start space-y-2">
            <p className="text-sm leading-none text-gray-800">
              <span className="text-gray-300">Product Type: </span>{" "}
              {item?.type?.Name || "Original Artwork"}
            </p>
            <p className="text-sm leading-none text-gray-800">
              <span className="text-gray-300">Product Subtype: </span>{" "}
              {item?.subtype?.Name || "-"}
            </p>
            <p className="text-sm leading-none text-gray-800">
              <span className="text-gray-300">Product Variety: </span>{" "}
              {item?.subsubtype?.Name || "-"}
            </p>
            <p className="text-sm leading-none text-gray-800">
              <span className="text-gray-300">Quantity: </span>{" "}
              {item?.quantity || "-"}
            </p>
          </div>
        </div>
        <div className="flex w-full items-start justify-between space-x-8">
          <p className="text-base leading-6 xl:text-lg">
            ${item?.product_price}
          </p>
          <p className="text-base leading-6 text-gray-800 xl:text-lg">
            {item?.quantity || "1"}
          </p>
          <p className="text-base font-semibold leading-6 text-gray-800 xl:text-lg">
            ${item?.sub_total}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailCard;
