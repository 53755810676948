import { Input, Typography, Button } from "@material-tailwind/react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Avatar } from "@material-tailwind/react";
import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { useGetUserByIdQuery } from "redux/Services/user";
import { useEffect, useRef, useState } from "react";
import { Toaster } from "sonner";
import { handleUploadArto } from "helpers/uploadFile";
import { Box, CircularProgress, Stack, Switch } from "@mui/material";
import { useEditUserMutation, usePostUserMutation } from "redux/Services/user";
import { toast } from "sonner";
import { artoImagePlacehoder } from "helpers/placeholder";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSuspendedUserMutation } from "redux/Services/user";
import { useBlackListedUserMutation } from "redux/Services/user";
import { useAddArtistMutation } from "redux/Services/artist";
import { useSendEmailMutation } from "redux/Services/artist";
import { useGetArtWorkByKeywordQuery } from "redux/Services/artist";
import Carousel from "components/carousel";
import AwardTable from "views/admin/content_management/artist/component/AwardTable";
import ExhibitionTable from "views/admin/content_management/artist/component/ExhibitionTable";

const UserDetails = () => {
  const fileRef = useRef(null);
  let navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const isArtist = pathName.split("/")[2] === "artist";
  console.log(isArtist, "isartist");
  // how to extract id from params using react?
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const queryValue = searchParams.get("query");
  const { data: dataUserDetail, isLoading: isLoadingUserDetail } =
    useGetUserByIdQuery({ id }, { skip: id === "add" });
  console.log(dataUserDetail, "dataUserDetail");
  const [userDetail, setUserDetail] = useState({});

  const handleChange = (e, name) => {
    setUserDetail({ ...userDetail, [name]: e.target.value });
  };
  const [loading, setLoading] = useState(false);
  const [imageLink, setImageLink] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (dataUserDetail) {
      setUserDetail(dataUserDetail);
      setImageId(dataUserDetail?.avatar?.id);
      setImageLink(dataUserDetail?.avatar?.url);
    }
  }, [dataUserDetail]);
  const handleClickUpload = () => {
    fileRef.current.click();
  };
  const [editProfile, { data, isLoading: loadingEditProfile }] =
    useEditUserMutation();
  const [handleAdd, { isloading: loadingAdd }] = useAddArtistMutation();

  const handleSubmit = async () => {
    try {
      const data = {
        id: +id,
        username: userDetail.userName,
        First_Name: userDetail.first_name,
        Last_Name: userDetail.last_name,
        email: userDetail.email,
        Location: userDetail.country,
        About_Bio: userDetail.bio,
        Short_Bio: userDetail.short_bio,
        avatar_file: {
          id: imageId,
        },
      };
      if (isArtist && id === "add") {
        const res = await handleAdd({
          data: {
            ...data,
            password: "123456",
          },
        });
        toast.success(`Success Add Artist`);
        setTimeout(() => {
          navigate(`/admin/artist/?label=Profile`, {
            state: { previous: location.pathname },
          });
        }, 1000);
      } else {
        const res = await editProfile({
          id: +userDetail?.userId,
          data,
          isArtist,
        });
        toast.success(`Edit ${pathName.split("/")[2]} Success`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getTitle = () => {
    const action = id === "add" ? "Add" : "Edit";
    const entity = isArtist ? "Artist" : "User";
    return `${action} ${entity}`;
  };
  const handleSendEmail = () => {
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${userDetail.email}`
    );
  };
  const [suspendedUser, { isLoading }] = useSuspendedUserMutation();
  const [blackListUser, { isLoading: isLoadingBlackList }] =
    useBlackListedUserMutation();
  const handleSuspend = async (e) => {
    try {
      const data = {
        suspended: e.target.checked,
      };
      const res = await suspendedUser({
        id: +userDetail?.id,
        data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleBlackList = async (e) => {
    try {
      const data = {
        blacklist: e.target.checked,
      };
      // console.log(data, "stg");
      const res = await blackListUser({
        id: +userDetail?.id,
        data,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const { data: dataArtwork, isLoading: loadingArtwork } =
    useGetArtWorkByKeywordQuery(
      { keyword: `filters[profile][id][$eq]=${id}` },
      { skip: id === "add" || !id }
    );
  const [
    sendPassword,
    { data: dataSendemail, error: errorSendEmail, isLoading: loadingSendEmail },
  ] = useSendEmailMutation();
  const handleSendPassword = async () => {
    try {
      const res = sendPassword({
        id,
      });
      console.log(res, "<res>");
    } catch (error) {}
  };

  return (
    // <div class="grid grid-flow-col grid-rows-3 gap-4">
    //   <div class="... row-span-3">
    <>
      <Toaster richColors position="top-right" />
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <div className="w-full ">
        <div className="ml-10 mt-10 flex w-full justify-items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
            }}
          >
            <ArrowBackIcon />
          </div>
          <p className="font  text-2xl font-bold">{getTitle()}</p>
        </div>
        <div className="flex-cols-2 flex justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex flex-col justify-center gap-1">
            {loading ? (
              <div className="flex h-96 w-96 items-center justify-center">
                <CircularProgress />
              </div>
            ) : (
              <img
                className="h-96 w-full rounded-lg object-cover object-center"
                src={imageLink}
                alt="nature image"
              />
            )}
            <Button
              className="mt-3"
              color="pink"
              variant="gradient"
              onClick={handleClickUpload}
            >
              Edit Thumbnail
            </Button>
          </div>
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                First Name
              </Typography>
              <Input
                type="text"
                value={userDetail?.first_name}
                onChange={(e) => handleChange(e, "first_name")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Last Name
              </Typography>
              <Input
                type="text"
                value={userDetail?.last_name}
                onChange={(e) => handleChange(e, "last_name")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Country
              </Typography>
              <Input
                type="text"
                value={userDetail?.country}
                onChange={(e) => handleChange(e, "country")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Username
              </Typography>
              <Input
                type="text"
                value={userDetail?.userName}
                onChange={(e) => handleChange(e, "userName")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Email
              </Typography>
              <Input
                type="email"
                value={userDetail?.email}
                onChange={(e) => handleChange(e, "email")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Short Bio
              </Typography>
              <Input
                type="email"
                value={userDetail?.short_bio}
                onChange={(e) => handleChange(e, "short_bio")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                About
              </Typography>
              <textarea
                rows={4}
                className="w-full rounded-lg p-2"
                type="text"
                onChange={(e) => handleChange(e, "bio")}
                value={userDetail?.bio}
              />
            </div>

            {!isArtist && id !== "add" && (
              <>
                <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                  <Typography variant="h7" style={{ fontWeight: 500 }}>
                    Suspended
                  </Typography>
                  <Switch
                    checked={dataUserDetail?.suspended ? true : false}
                    label={"Suspended"}
                    onChange={(e) => handleSuspend(e)}
                  />
                </Stack>
                <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
                  <Typography variant="h7" style={{ fontWeight: 500 }}>
                    Blacklisted
                  </Typography>
                  <Switch
                    label={"Suspended"}
                    checked={dataUserDetail?.blacklist ? true : false}
                    onChange={(e) => handleBlackList(e)}
                  />
                </Stack>
              </>
            )}
          </div>
        </div>
        {isArtist && (
          <div className="w-full">
            <Stack mt={4} className="w-full" mb={4}>
              <Stack alignItems={"center"}>
                {id !== "add" && isArtist && (
                  <>
                    <Stack
                      direction={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width={"100%"}
                    >
                      <Box flexGrow={1} display="flex" justifyContent="center">
                        <Typography
                          color="grey"
                          className="mb-4 mt-2 font-medium"
                        >
                          LIST OF ARTWORK
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      justifyContent={"flex-end"}
                      mb={4}
                    >
                      <Button
                        className="mb-3 mt-3 w-1/5"
                        color="pink"
                        variant="gradient"
                        onClick={() => {
                          navigate(
                            `/admin/artist/artwork-details/add?label=${userDetail.userName}&profileId=${id}`,
                            {
                              state: { previous: location.pathname },
                            }
                          );
                        }}
                      >
                        ADD ARTWORK
                      </Button>
                    </Stack>
                  </>
                )}
              </Stack>
              {!loadingArtwork && dataArtwork?.length > 0 && (
                <Carousel data={dataArtwork} />
              )}
              {id !== "add" && isArtist && (
                <>
                  <Stack alignItems={"center"} mt={2} gap={2}>
                    <Typography
                      variant="h5"
                      color="grey"
                      className="mb-4 mt-2 font-medium"
                    >
                      Awards
                    </Typography>
                  </Stack>
                  <AwardTable query={id} />
                  <Stack alignItems={"center"} mt={2} gap={2}>
                    <Typography
                      variant="h5"
                      color="grey"
                      className="mb-4 mt-2 font-medium"
                    >
                      Exhibition
                    </Typography>
                  </Stack>
                  <ExhibitionTable query={id} />
                </>
              )}
            </Stack>
          </div>
        )}
        <Stack gap={4} justifyContent={"center"} flexDirection={"row"}>
          {loadingEditProfile || loadingAdd ? (
            <div className="flex h-full items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <Button
              color="pink"
              variant="gradient"
              // disabled={isLoading}
              onClick={handleSubmit}
            >
              {id === "add" ? "Add" : "Update"}
            </Button>
          )}
          {id === "add" ? null : (
            <>
              <Button onClick={handleSendEmail} color="pink" variant="gradient">
                Send Email
              </Button>
            </>
          )}
          {isArtist && id !== "add" ? (
            <Button
              onClick={handleSendPassword}
              color="pink"
              variant="gradient"
              disabled={loadingSendEmail}
            >
              {loadingSendEmail ? (
                <CircularProgress size={16} />
              ) : (
                "Send Password"
              )}
            </Button>
          ) : null}
        </Stack>
      </div>
    </>
  );
};
export default UserDetails;
