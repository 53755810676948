import { Button, Input } from "@material-tailwind/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetIssueTypeByIdQuery } from "redux/Services/contactUs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEditOrAddIssueTypeMutation } from "redux/Services/contactUs";
import { toast } from "sonner";
const IssueTypeDetail = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { data } = useGetIssueTypeByIdQuery({ id: id }, { skip: id === "add" });
  const [issue, setIssue] = useState("");
  useEffect(() => {
    if (data) {
      setIssue(data?.issue);
    }
  }, [data]);
  const [editOrAdd, { isLoading }] = useEditOrAddIssueTypeMutation();
  const handleSubmit = async () => {
    try {
      const data = {
        issue,
      };
      if (id === "add") {
        const res = await editOrAdd({ data: { data } });
        toast.success("Success Add Issue Type");
        setTimeout(() => {
          navigate(`/admin/settings?label=type`, {
            state: { previous: location.pathname },
          });
        }, 1000);
      } else {
        const res = await editOrAdd({ id: id, data: { data } });
        toast.success("Success Edit Issue Type");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Stack flexDirection={"row"} mt={5} alignItems={"center"} gap={2}>
        <ArrowBackIcon
          onClick={() => navigate(-1)}
          className="cursor-pointer"
        />
        <Typography variant="h6" component="h4">
          {id === "add" ? "Add Issue Type" : "Edit Issue Type"}
        </Typography>
      </Stack>
      <Stack width={500} gap={5}>
        <Stack mt={5}>
          <Typography variant="h6" component="h4">
            Issue Name
          </Typography>
          <Input value={issue} onChange={(e) => setIssue(e.target.value)} />
        </Stack>
        {isLoading ? (
          <Stack alignItems={"center"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Button color="pink" variant="gradient" onClick={handleSubmit}>
            {id === "add" ? "Add Issue Type" : "Edit Issue Type"}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default IssueTypeDetail;
