import { Autocomplete, Button, TextField } from "@mui/material";
// import Chip from "@mui/material/Chip";

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setTagsId } from "redux/Slices/userSlice";

const MultipleAutocomplete = ({
  data,
  loading,
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  useState(() => {
    setSelected(selectedCategory);
    dispatch(setTagsId(selectedCategory));
  }, [selected, setSelectedCategory]);

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        id="tags-outlined"
        options={data}
        getOptionLabel={(option) => option?.label}
        //   disableClearable
        value={selected || []}
        inputValue={searchTerm}
        onChange={(event, newValue) => {
          setSelected(newValue);
          dispatch(setTagsId(newValue));
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            //   label="Multiple values"
            //   placeholder="Favorites"

            label=""
            placeholder=""
          />
        )}
      />
    </>
  );
};

export default MultipleAutocomplete;
