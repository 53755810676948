import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
// access ENV .url

const baseUrl = process.env.REACT_APP_API_URL;

export const moodCategoryApi = createApi({
  reducerPath: "moodCategoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["MoodCategory"],
  endpoints: (builder) => ({
    moodCategoryList: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        method: "GET",
        url: `categories?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=*${
          filter ? `&filters[Name][$containsi]=${filter}` : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, image_url_file, bg_url_file, Bg_Color } =
            item?.attributes;
          return {
            id,
            name: Name,
            image: image_url_file?.data?.attributes?.url || artoImagePlacehoder,
            background_url:
              bg_url_file?.data?.attributes?.url || artoImagePlacehoder,
            background_colour: Bg_Color || "#000000",
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["MoodCategory"],
    }),
    deleteMoodCategory: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::category.category&id_select=${id}`,
      }),
      invalidatesTags: ["MoodCategory"],
    }),
    moodCategoryByid: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `categories/${id}?populate=*`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const { Name, image_url_file, bg_url_file, Bg_Color } =
          data?.attributes;
        return {
          Name,
          imageUrl:
            image_url_file?.data?.attributes?.url || artoImagePlacehoder,
          backgroundUrl:
            bg_url_file?.data?.attributes?.url || artoImagePlacehoder,
          imageId: image_url_file?.data?.id,
          backgroundId: bg_url_file?.data?.id,
          Bg_Color,
        };
      },
    }),
    editMoodCategory: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `categories/${id}`,
        body: data,
      }),
      invalidatesTags: ["MoodCategory"],
    }),
    addMoodCategory: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `categories`,
        body: data,
      }),
      invalidatesTags: ["MoodCategory"],
    }),
  }),
});

export const {
  useMoodCategoryListQuery,
  useDeleteMoodCategoryMutation,
  useMoodCategoryByidQuery,
  useAddMoodCategoryMutation,
  useEditMoodCategoryMutation,
} = moodCategoryApi;
