export const columnInitiative = [
  {
    Header: "Initiative Name",
    accessor: "initiative_name",
  },
  {
    Header: "START DATE",
    accessor: "start_date",
  },
  {
    Header: "END DATE",
    accessor: "end_date",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];

export const columnOrganisationProfileTable = [
  {
    Header: "ORGANISATION NAME",
    accessor: "organisation_name",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];
