import axios from "axios";
export const handleUploadFile = async (data) => {
  try {
    const response = await axios("https://admin.artoglobal.com/api/upload", {
      method: "POST",
      headers: {
        // Authorization: `bearer ${localStorage.getItem("jwt")}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
    });
    return response;
  } catch (error) {
    console.log(error, "error upload file");
    return error;
  }
};

export const handleUploadCsvAxios = async (data) => {
  try {
    const response = await axios(
      "https://admin.artoglobal.com/api/product/bulkUploadExcelNewProducts",
      {
        method: "POST",
        headers: {
          Authorization: `bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data",
        },
        data: data,
      }
    );
    return response;
  } catch (error) {
    console.log(error, "error upload file");
    return error;
  }
};
