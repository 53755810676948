import { pagination } from "@nextui-org/react";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DateTime } from "luxon";
const baseUrl = process.env.REACT_APP_API_URL;

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `bo-orders/lists?page=${page}&limit=${pageSize}`;
        const filterUrl = filter ? `&search=${filter}` : "&search=&filters=";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const {
            Address,
            createdAt,
            Fullfilled_Date,
            Status,
            profile_id,
            order_details_id,
            Is_Paid,
            products_id,
          } = item;
          const First_Name = profile_id?.First_Name;
          const Last_Name = profile_id?.Last_Name;
          // const artwork_name = artwork_id?.data?.attributes?.Name;
          const dateTime = DateTime.fromISO(createdAt);
          const formattedDate = dateTime.toFormat("yyyy-MM-dd");
          const date_fulfilled = Fullfilled_Date
            ? DateTime.fromISO(Fullfilled_Date).toFormat("yyyy-MM-dd")
            : "";
          let listOfItems = products_id?.map((item) => {
            const productName = item?.product_name;
            if (productName) {
              return productName + ", ";
            }
            // how to return nothing
          });
          const handleStatus = () => {
            return Is_Paid
              ? Status === "done"
                ? "Paid & Delivered"
                : "Paid"
              : "Unpaid";
          };
          console.log(listOfItems, "cek", id);
          const handleListItems = () => {
            if (listOfItems?.length < 1) {
              return [];
            } else {
              // i want to remove undefined
              const removeUndefined = listOfItems?.filter((item) => {
                return item !== undefined;
              });
              const changeLastArrayToPediod = removeUndefined?.map(
                (item, index) => {
                  if (index === removeUndefined?.length - 1) {
                    return item.replace(", ", ".");
                  }
                  return item;
                }
              );
              return changeLastArrayToPediod;
            }
          };

          return {
            id,
            date_fulfilled,
            date_of_order: formattedDate,
            buyer: `${First_Name || ""} ${Last_Name || ""}`,
            order_details_id,
            status: handleStatus(),
            list_of_items: handleListItems(),
          };
        });
        const pagination = {
          pagination: response?.meta,
        };
        return {
          data: newData,
          meta: pagination,
        };
      },
      providesTags: ["Order"],
    }),
    getOrderById: builder.query({
      query: ({ id }) => {
        console.log("id", id);
        return {
          url: `/order-bo/${id}`,
          method: "GET",
        };
      },

      providesTags: ["Order"],
    }),
    createOrder: builder.mutation({
      query: (newOrder) => ({
        url: "/orders",
        method: "POST",
        body: newOrder,
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `/orders/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Order"],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `/orders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
} = orderApi;
