// import Tables from "components/table/index";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import { columnOrder } from "./component/variables/columnsData";
import ProfileTable from "./component/ProfileTable";
import ArtworkTable from "./component/ArtworkTable";
import AwardTable from "./component/AwardTable";
import ExhibitionTable from "./component/ExhibitionTable";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

const data = [
  {
    label: "Profile",
    value: "profile",
    component: <ProfileTable />,
  },
  {
    label: "Artwork",
    value: "artwork",
    component: <ArtworkTable />,
    // component: <ProfileTable />,
  },

  {
    label: "Awards",
    value: "awards",
    component: <AwardTable />,
    // component: <ProfileTable />,
  },

  {
    label: "Exhibition",
    value: "exhibition",
    component: <ExhibitionTable />,
    // component: <ProfileTable />,
  },
];

const ArtistView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { label } = queryString.parse(location.search);

  return (
    <div className="mt-10">
      <Tabs
        id="custom-animation"
        value={`${label?.toLowerCase() || "profile"}`}
      >
        <TabsHeader>
          {data.map(({ label, value }) => (
            <Tab
              activeClassName="font-bold"
              key={value}
              value={value}
              onClick={() => {
                // add query every klik base on label
                navigate(`?label=${label}`);
              }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, component }) => (
            <TabPanel key={value} value={value}>
              {component}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};
export default ArtistView;
