import { useDebounce } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { useGetUserListQuery } from "redux/Services/user";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useGetProfileOrgQuery } from "redux/Services/organisation";

const ProfileOrgAutoComplete = ({
  selectedUsername,
  setSelectedUsername,
  detailCollectionId,
}) => {
  const [collectionUserName, setCollectionUserName] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    data: dataProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetProfileOrgQuery({
    filter: debouncedSearchTerm,
  });
  useEffect(() => {
    // if (detailCollectionId && firstFetch) {
    //   setSelectedUsername({
    //     label: detailCollectionId?.username || "",
    //     profileId: detailCollectionId?.profileId || "",
    //   });
    //   setFirstFetch(false);
    // }
    if (dataProfile) {
      const collectionOfUserName = dataProfile?.data?.map((item) => {
        return {
          label: item?.Username || "",
          profileId: item?.id,
        };
      });
      setCollectionUserName(collectionOfUserName);
    }
  }, [dataProfile]);
  console.log(collectionUserName, "<sayang>");
  return (
    <>
      <Autocomplete
        loading={isFetchingProfile}
        size="small"
        fullWidth
        disablePortfral
        id="combo-box-demo"
        options={collectionUserName || []}
        sx={{ backgroundColor: "white" }}
        value={selectedUsername}
        onChange={(event, newValue) => {
          event?.preventDefault();
          setSelectedUsername(newValue);
        }}
        onInputChange={(event, newValue) => {
          event?.preventDefault();
          setSearchTerm(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetchingProfile ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ProfileOrgAutoComplete;
