import { useDebounce } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { useGetUserListQuery } from "redux/Services/user";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useGetArtworkAutoCompleteQuery } from "redux/Services/artist";

const ArtworkAutocomplete = ({
  selectedArtworkId,
  setSelectedArtworkId,
  profileId,
  disabled,

  size,
}) => {
  const [collectionUserName, setCollectionUserName] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    data: dataProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetArtworkAutoCompleteQuery(
    {
      withCount: false,
      filter: debouncedSearchTerm,
      profileId: profileId,
    },
    {
      skip: !profileId,
    }
  );

  useEffect(() => {
    // if (detailCollectionId && firstFetch) {
    //   setSelectedUsername({
    //     label: detailCollectionId?.userName,
    //     profileId: detailCollectionId?.profileId,
    //   });
    //   setFirstFetch(false);
    // }
    if (dataProfile) {
      const collectionOfUserName = dataProfile?.map((item) => {
        return {
          label: item.name,
          artworkId: item.id,
        };
      });
      setCollectionUserName(collectionOfUserName);
    }
  }, [selectedArtworkId, dataProfile, firstFetch]);
  useEffect(() => {
    if (!profileId) {
      setSelectedArtworkId({
        label: "",
        artworkId: "",
      });
    }
  }, [profileId]);
  return (
    <>
      <Autocomplete
        disabled={disabled}
        loading={isFetchingProfile}
        size={size === "large" ? "large" : "small"}
        fullWidth
        disablePortfral
        id="combo-box-demo"
        options={collectionUserName || []}
        sx={{
          backgroundColor: size === "large" ? "" : "white",
          width: "250px",
        }} // set the width to 300px
        value={selectedArtworkId}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => {
          event?.preventDefault();

          setSelectedArtworkId(newValue);
        }}
        onInputChange={(event, newValue) => {
          event?.preventDefault();
          setSearchTerm(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetchingProfile ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default ArtworkAutocomplete;
