import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// access ENV .url
import { DateTime } from "luxon";

const baseUrl = process.env.REACT_APP_API_URL;

export const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Comment"],
  endpoints: (builder) => ({
    commentList: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        method: "GET",
        url: `comments?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=*&filters[profile_id][id][$notNull]=true&filters[artwork_id][id][$notNull]=true${
          filter
            ? `&filters[$or][0][profile_id][First_Name][$containsi]=${filter}&filters[$or][1][profile_id][Last_Name][$containsi]=${filter}}&filters[$or][2][artwork_id][Name][$containsi]=${filter}&filters[$or][3][comment][$containsi]=${filter}`
            : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { comment, profile_id, createdAt, artwork_id } =
            item?.attributes;
          const First_Name = profile_id?.data?.attributes?.First_Name;
          const Last_Name = profile_id?.data?.attributes?.Last_Name;
          const artwork_name = artwork_id?.data?.attributes?.Name;
          const dateTime = DateTime.fromISO(createdAt);
          const formattedDate = dateTime.toFormat("yyyy-MM-dd");
          return {
            id,
            comment,
            time_posted: formattedDate,
            first_name: First_Name,
            last_name: Last_Name,
            artwork_title: artwork_name,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Comment"],
    }),
    deleteComment: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::comment.comment&id_select=${id}`,
      }),
      transformResponse: (response) => {
        console.log(response, "response");
      },
      invalidatesTags: ["Comment"],
    }),
  }),
});

export const { useCommentListQuery, useDeleteCommentMutation } = commentApi;
