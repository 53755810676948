import Tables from "components/table/index";
import { columnsComment } from "./variables/columnsData";
import { useCommentListQuery } from "redux/Services/comment";
import { useDebounce } from "@uidotdev/usehooks";
import { useState } from "react";

const CommentView = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const { data, isFetching, error } = useCommentListQuery({
    page,
    pageSize,
    filter: debouncedSearchTerm,
  });
  const [selected, setSelected] = useState([]);
  console.log("🚀 ~ file: index.jsx:18 ~ CommentView ~ selected:", selected);

  return (
    <div className="mt-5">
      {
        <Tables
          columnsData={columnsComment}
          tableData={data?.data || []}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          pageProps={page}
          setPage={setPage}
          totalPage={data?.meta}
          selected={selected}
          setSelected={setSelected}
        />
      }
    </div>
  );
};
export default CommentView;
