import React from "react";
import { artoImagePlacehoder } from "helpers/placeholder";
import { Input, Typography, Button } from "@material-tailwind/react";

import {
  Box,
  Checkbox,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
} from "@mui/material";
import { ToastError } from "components/toast";
import { handleUploadFile } from "redux/Services/uploadFileCustom";
import { Toast } from "components/toasterSweetAlert";
import { useParams } from "react-router-dom";
const ArtkworkDetailType = ({
  title,
  keyIndex,
  id,
  merchandises,
  setMerchandises,
  dataArtwork,
}) => {
  const { id: artworkId } = useParams();
  const selectedMerchandise = merchandises?.filter(
    (item) => item?.type_id === id
  );

  const [imageList, setImageList] = React.useState([]);
  console.log("🚀 ~ file: ArtkworkDetailType.jsx:31 ~ imageList:", imageList);
  if (id === 1) {
    console.log(selectedMerchandise?.[0], "aadsa");
  }
  const [loadingUpload, setLoadingUpload] = React.useState(false);
  const [totalImage, setTotalImage] = React.useState([1, 2, 3, 4]);
  const [error, setError] = React.useState([]);
  const [firstFetch, setFirstFetch] = React.useState(true);
  const fileRef = React.useRef(null);
  console.log(merchandises, "ini syg");
  const handleClick = () => {
    fileRef.current.click();
  };
  const onChangeUpload = async (e) => {
    try {
      setLoadingUpload(true);
      const files = e.target.files;
      const promises = Array.from(files).map((file) => {
        const formData = new FormData();
        formData.append("files", file);
        return handleUploadFile(formData);
      });
      Promise.all(promises).then((responses) => {
        setLoadingUpload(false);
        responses.forEach((res) => {
          if (res?.data) {
            setImageList((prev) => [...prev, res?.data?.[0]]);
            setMerchandises((prev) => {
              return [
                ...prev?.map((item, index) => {
                  if (index === keyIndex) {
                    return {
                      ...item,
                      product_image: [
                        ...item?.product_image,
                        res?.data?.[0]?.id,
                      ],
                    };
                  }
                  return item;
                }),
              ];
            });
          } else {
            const fileName = res?.config?.data?.get("files")?.name;
            setError((prev) => [fileName]);
          }
        });
      });
      // console.log(res, "res,<>");
    } catch (error) {
      console.log(error, "error upload");
    }
  };
  React.useEffect(() => {
    if (error?.length > 0) {
      ToastError.fire({
        width: "auto",
        title: `Upload file Error!`,
        icon: `error`,
        html: `<ul class="text-left"><li>${error
          ?.map((err) => `File name ${err} is not uploaded because too large`)
          .join("</li><li>")}</li></ul>`,
        showCloseButton: true,
        onclose: () => {
          setError([]);
        },
      });
    }
  }, [error]);
  React.useEffect(() => {
    if (firstFetch && merchandises?.length === 0) {
      const merchanediseSameId = dataArtwork?.merchandises?.filter(
        (item) => item?.type_id === id
      );

      setMerchandises((prev) => [
        ...prev,
        {
          artwork_id: +artworkId,
          type_id: id,
          stock_quantity: merchanediseSameId?.[0]?.stock_quantity || 0,
          is_active: merchanediseSameId?.[0]?.is_active || false,
          product_image: merchanediseSameId?.[0]?.product_image || [],
          imageList: merchanediseSameId?.[0]?.imageList || [],
        },
      ]);
      setFirstFetch(false);

      setImageList(merchanediseSameId?.[0]?.imageList || []);
    }
  }, [
    firstFetch,
    merchandises,
    artworkId,
    id,
    setMerchandises,
    dataArtwork,
    selectedMerchandise,
    setImageList,
  ]);

  const onDeleteImage = (id) => {
    Toast.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setImageList((prev) => prev.filter((item) => item?.id !== id));
        setMerchandises((prev) => {
          return [
            ...prev?.map((item, index) => {
              if (index === keyIndex) {
                return {
                  ...item,
                  product_image: item?.product_image?.filter(
                    (item) => item !== id
                  ),
                };
              }
              return item;
            }),
          ];
        });
      } else {
        return;
      }
    });
  };
  return (
    <div>
      <div>
        <input
          type="file"
          onChange={onChangeUpload}
          ref={fileRef}
          hidden
          multiple
        />
      </div>
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={700}
        justifyContent={"space-between"}
      >
        <Stack
          width={150}
          mt={4}
          display={"flex"}
          direction={"row"}
          alignItems={"center"}
        >
          <Checkbox
            size="small"
            checked={selectedMerchandise?.[0]?.is_active || false}
            onChange={(e) => {
              setMerchandises((prev) => {
                return [
                  ...prev?.map((item, index) => {
                    if (index === keyIndex) {
                      return {
                        ...item,
                        is_active: e.target.checked,
                      };
                    }
                    return item;
                  }),
                ];
              });
            }}
          />
          <Typography variant="medium" color="grey" className="  font-medium">
            {title}
          </Typography>
        </Stack>
        <Stack direction={"column"} alignItems={"center"}>
          <Typography
            variant="small"
            color="grey"
            className="mb-2 mt-2 font-medium"
          >
            QUANTITY
          </Typography>

          <Stack alignItems={"center"}>
            <Input
              type="number"
              value={selectedMerchandise?.[0]?.stock_quantity || ""}
              onChange={(e) => {
                setMerchandises((prev) => {
                  return [
                    ...prev?.map((item, index) => {
                      if (index === keyIndex) {
                        return {
                          ...item,
                          stock_quantity: e.target.value,
                        };
                      }
                      return item;
                    }),
                  ];
                });
              }}
            />
          </Stack>
        </Stack>
        <Stack mt={4}>
          <Button
            disabled={loadingUpload}
            onClick={handleClick}
            color="pink"
            variant="gradient"
          >
            UPLOAD
          </Button>
        </Stack>
      </Stack>

      <ImageList sx={{ width: 800, marginTop: 2 }} cols={5} rowHeight={230}>
        {loadingUpload ? (
          <>
            {totalImage?.map((item, index) => (
              <Stack gap={2} key={index}>
                <Skeleton
                  variant="rectangular"
                  width={164}
                  height={164}
                  animation="wave"
                  sx={{ borderRadius: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  width={164}
                  height={50}
                  animation="wave"
                  sx={{ borderRadius: 1 }}
                />
              </Stack>
            ))}
          </>
        ) : (
          imageList?.map((item, index) => (
            <ImageListItem key={index}>
              <Stack gap={2}>
                {console.log(item?.url, "cek<>")}
                <img
                  src={item?.url || artoImagePlacehoder}
                  alt="image"
                  style={{ borderRadius: 4 }}
                />
                <Button onClick={() => onDeleteImage(item?.id)} color="red">
                  Delete
                </Button>
              </Stack>
            </ImageListItem>
          ))
        )}
      </ImageList>
    </div>
  );
};

export default ArtkworkDetailType;
