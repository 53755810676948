import { Input, Typography, Button, Avatar } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetInitiativeOrgByIdQuery } from "redux/Services/organisation";
import { useGetInitiativeOrgQuery } from "redux/Services/organisation";
import { toast } from "sonner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { artoImagePlacehoder } from "helpers/placeholder";
import { handleUploadArto } from "helpers/uploadFile";
import { CircularProgress, Stack } from "@mui/material";
import ProfileOrgAutoComplete from "components/autocomplete/profileOrg";
import { useEditInitiativeOrgMutation } from "redux/Services/organisation";
import { useAddInitiativeOrgMutation } from "redux/Services/organisation";

const InitiativeDetail = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fileRef = useRef(null);
  const { id } = useParams();
  const {
    data: detailInitiative,
    isLoading,
    isError,
  } = useGetInitiativeOrgByIdQuery(
    {
      id: id,
    },
    {
      skip: id === "add",
    }
  );
  const [detailInitiativeState, setDetailInitiativeState] = useState("");
  const [image, setImage] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedUsername, setSelectedUsername] = useState({
    label: "",
    profileId: "",
  });
  const [firstFetch, setFirstFetch] = useState(false);
  useEffect(() => {
    if (detailInitiative && !firstFetch) {
      setDetailInitiativeState(detailInitiative);
      setImage(detailInitiative.imageUrl);
      setImageId(detailInitiative.imageId);
      setSelectedUsername({
        label: detailInitiative?.username,
        profileId: detailInitiative?.profileId,
      });
      setFirstFetch(true);
    }
  }, [detailInitiative, selectedUsername, firstFetch]);
  const handleChange = (e, name) => {
    setDetailInitiativeState({
      ...detailInitiativeState,
      [name]: e.target.value,
    });
  };
  const handleClick = () => {
    fileRef.current.click();
  };
  const [editInitiative, { isLoading: loadingEdit }] =
    useEditInitiativeOrgMutation();
  const [postInitiative, { isLoading: loadingPost }] =
    useAddInitiativeOrgMutation();
  const handleSumbit = async () => {
    try {
      const data = {
        ...detailInitiativeState,
        image_url_file: {
          id: imageId,
        },
        organisation_profile: {
          id: selectedUsername?.profileId,
        },
      };
      if (id === "add") {
        const res = await postInitiative({ data: { data } });
        toast.success("Add Initiative Organisation Success");
        setTimeout(() => {
          navigate("/admin/organisation?label=Initiative", {
            state: { previous: "/admin/organisation/initiative/add" },
          });
        }, 1000);
      } else {
        const res = await editInitiative({ id, data: { data } });
        toast.success("Edit Initiative Organisation Success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setImage,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <div className="w-full ">
        <div className=" ml-10 mt-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
              // history(-1);
              //add window refresh
              // window.location.reload();
            }}
          >
            <ArrowBackIcon />
          </div>
          <p className="font flex w-full justify-items-center text-2xl font-bold">
            {id === "add"
              ? "Add Initiative Organisation"
              : "Edit Initiative Organisation"}
          </p>
        </div>
        <div className="flex-cols-2 flex w-full justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Initiative Title
              </Typography>
              <Input
                type="text"
                value={detailInitiativeState?.Initiative_Title}
                onChange={(e) => handleChange(e, "Initiative_Title")}
              />
            </div>
            <Typography
              variant="small"
              color="blue-gray-300"
              className="mb-2 mt-2 font-medium"
            >
              Featured Image
            </Typography>
            <div className=" mt-5 flex flex-col  gap-1">
              {loading ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <img
                  className="h-4/5 w-3/5 rounded-lg object-cover object-center"
                  src={image}
                  alt="nature image"
                />
              )}
              <Button
                className="mb-3 mt-3 w-3/5"
                color="pink"
                variant="gradient"
                onClick={handleClick}
              >
                {id === "add" ? "Add Image" : "Change Image"}
              </Button>
            </div>
          </div>
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Start Date
              </Typography>
              <Input
                type="text"
                value={detailInitiativeState?.Start_Date}
                onChange={(e) => handleChange(e, "Start_Date")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                End Date
              </Typography>
              <Input
                type="text"
                value={detailInitiativeState?.End_Date}
                onChange={(e) => handleChange(e, "End_Date")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Learn More URL
              </Typography>
              <Input
                type="text"
                value={detailInitiativeState?.Learn_More_Url}
                onChange={(e) => handleChange(e, "Learn_More_Url")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Company Username
              </Typography>

              <ProfileOrgAutoComplete
                selectedUsername={selectedUsername?.label || ""}
                setSelectedUsername={setSelectedUsername}
                detailCollectionId={detailInitiative}
              />
            </div>

            <div className="w-4/6">
              <Typography
                variant="small"
                color="gr ey"
                className="mb-4 mt-2 font-medium"
              >
                Description
              </Typography>
              <textarea
                id="message"
                rows="4"
                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Write your thoughts here..."
                value={detailInitiativeState?.Description}
                onChange={(e) => handleChange(e, "Description")}
              ></textarea>
            </div>
            <div className="mb-14 mt-6">
              {loadingEdit || loadingPost ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <Button
                  onClick={handleSumbit}
                  className="mb-3 mt-3 w-3/5"
                  color="pink"
                  variant="gradient"
                >
                  {id === "add" ? "Add" : "Save"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
    // <div class="grid grid-flow-col grid-rows-3 gap-4">
    //   <div class="... row-span-3">
  );
};
export default InitiativeDetail;
