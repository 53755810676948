export const columnsMood = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "IMAGE",
    accessor: "image",
    type: 'image',
  },
  {
    Header: "BACKGROUND URL",
    accessor: "background_url",
    type: 'image',
  },
  {
    Header: "BACKGROUND COLOUR",
    accessor: "background_colour",
    type: 'color',
  },
];

