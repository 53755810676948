import Tables from "components/table/index";
import { faker } from "@faker-js/faker";

import { columnsSettings } from "./variables/columnsData";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useListContactUsQuery } from "redux/Services/contactUs";
let settings = [];

// function buildFakeDt() {
//   return {
//     date_of_ticket: faker.person.middleName(),
//     type: faker.person.sexType(),
//     answered: faker.person.suffix(),

//   };
// }

// for(var i = 0; i < 25; i++) {
//   settings.push(buildFakeDt())
// }

const SettingsContactUsTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selected, setSelected] = useState([]);
  const { data: dataSettings } = useListContactUsQuery({
    page: page,
    pageSize: pageSize,
    search: debouncedSearchTerm,
  });

  return (
    <div className="mt-5">
      <Tables
        columnsData={columnsSettings}
        tableData={dataSettings?.data || []}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={dataSettings?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default SettingsContactUsTable;
