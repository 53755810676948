import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
import { DateTime } from "luxon";
const baseUrl = process.env.REACT_APP_API_URL;

export const oraganisationApi = createApi({
  reducerPath: "oraganisationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["profileOrg", "initiativeOrg"],
  endpoints: (builder) => ({
    getProfileOrg: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        method: "GET",
        url: `organisation-profiles?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[is_artist][$eq]=false${
          filter
            ? `&filters[$or][0][Organisation_Name][$containsi]=${filter}&filters[$or][1][Username][$containsi]=${filter}`
            : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Organisation_Name, Location, createdAt, Username } =
            item?.attributes;
          const formattedDate =
            DateTime.fromISO(createdAt).toFormat("yyyy-MM-dd");

          return {
            id,
            organisation_name: Organisation_Name,
            country: Location,
            uploaded_date: formattedDate,
            Username,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["profileOrg"],
    }),
    getInitiativeOrg: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", query = "" }) => ({
        method: "GET",
        url: `organisation-initiatives?pagination[page]=${page}&pagination[pageSize]=${pageSize}${query}${
          filter
            ? `&filters[$or][0][Organisation_Name][$containsi]=${filter}&filters[$or][1][Location][$containsi]=${filter}`
            : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Initiative_Title, Start_Date, End_Date, createdAt } =
            item?.attributes;
          const formattedDate =
            DateTime.fromISO(createdAt).toFormat("yyyy-MM-dd");

          return {
            id,
            initiative_name: Initiative_Title,
            start_date: Start_Date,
            end_date: End_Date,
            uploaded_date: formattedDate,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["initiativeOrg"],
    }),
    deleteProfileOrg: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::organisation-profile.organisation-profile&id_select=${id}`,
      }),
      invalidatesTags: ["profileOrg"],
    }),
    deleteInitiativeOrg: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::organisation-initiative.organisation-initiative&id_select=${id}`,
      }),
      invalidatesTags: ["initiativeOrg"],
    }),
    getProfileOrgById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `organisation-profiles/${id}?populate=avatar_url_file,banner_url_file,keywords_id`,
      }),
      providesTags: ["profileOrg"],
      transformResponse: (response) => {
        const { data } = response;
        const {
          Organisation_Name,
          Banner_Url,
          Location,
          Username,
          Profile_Bio,
          Profile_About,
          Contact_Number,
          Contact_Email,
          Website,
          Facebook_Link,
          Instagram_Link,
          Linkedin_Link,
          Youtube_Link,
          Twitter_Link,
          Tiktok_Link,
          Avatar_Url,
          Artwork_Keyword,
          createdAt,
          updatedAt,
          publishedAt,
          banner_url_file,
          avatar_url_file,
          keywords_id,
        } = data.attributes;
        const avatarId = avatar_url_file?.data?.id;
        const bannerId = banner_url_file?.data?.id;
        const avatarUrl =
          avatar_url_file?.data?.attributes?.formats?.thumbnail?.url ||
          artoImagePlacehoder;
        const bannerUrl =
          banner_url_file?.data?.attributes?.formats?.thumbnail?.url ||
          artoImagePlacehoder;
        const keywords_id_arr = keywords_id?.data?.map((item) => {
          const id = item.id;
          const { name } = item.attributes;
          return {
            id,
            label: name,
          };
        });
        return {
          Organisation_Name,
          Banner_Url,
          Location,
          Username,
          Profile_Bio,
          Profile_About,
          Contact_Number,
          Contact_Email,
          Website,
          Facebook_Link,
          Instagram_Link,
          Linkedin_Link,
          Youtube_Link,
          Twitter_Link,
          Tiktok_Link,
          Avatar_Url,
          Artwork_Keyword,
          createdAt,
          updatedAt,
          publishedAt,
          avatarId,
          bannerId,
          avatarUrl,
          bannerUrl,
          keywords_id_arr,
        };
      },
    }),
    editProfileOrg: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `organisation-profiles/${id}`,
        body: data,
      }),
      invalidatesTags: ["profileOrg"],
    }),
    addProfileOrg: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `organisation-profiles`,
        body: data,
      }),
      invalidatesTags: ["profileOrg"],
    }),
    getInitiativeOrgById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `organisation-initiatives/${id}?populate=image_url_file,organisation_profile`,
      }),
      providesTags: ["initiativeOrg"],
      transformResponse: (response) => {
        const { data } = response;
        const {
          Initiative_Title,
          Start_Date,
          End_Date,
          Learn_More_Url,
          Description,
          image_url_file,
          organisation_profile,
        } = data?.attributes;
        const imageId = image_url_file?.data?.id;
        const imageUrl =
          image_url_file?.data?.attributes?.formats?.thumbnail?.url ||
          artoImagePlacehoder;
        const profileId = organisation_profile?.data?.id;
        const username = organisation_profile?.data?.attributes?.Username;
        return {
          Initiative_Title,
          Start_Date,
          End_Date,
          Learn_More_Url,
          Description,
          imageId,
          imageUrl,
          organisation_profile,
          profileId,
          username,
        };
      },
    }),
    editInitiativeOrg: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `organisation-initiatives/${id}`,
        body: data,
      }),
      invalidatesTags: ["initiativeOrg"],
    }),
    addInitiativeOrg: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `organisation-initiatives`,
        body: data,
      }),
      invalidatesTags: ["initiativeOrg"],
    }),
  }),
});

export const {
  useGetProfileOrgQuery,
  useGetInitiativeOrgQuery,
  useDeleteProfileOrgMutation,
  useDeleteInitiativeOrgMutation,
  useGetProfileOrgByIdQuery,
  useEditProfileOrgMutation,
  useAddProfileOrgMutation,
  useGetInitiativeOrgByIdQuery,
  useEditInitiativeOrgMutation,
  useAddInitiativeOrgMutation,
} = oraganisationApi;
