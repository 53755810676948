import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { AddKeywords } from "components/dialog/AddKeyword";
// import Chip from "@mui/material/Chip";

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAddKeywordMutation } from "redux/Services/keyword";
import { useGetKeywordsQuery } from "redux/Services/keyword";
import { useGetAllUserListQuery } from "redux/Services/user";
import { setTagsId } from "redux/Slices/userSlice";
const filter = createFilterOptions();
const KeywordAutoComplete = ({
  data,
  loading,
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [selected, setSelected] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [open, toggleOpen] = React.useState(false);
  const handleClose = () => {
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });
  const [postKeywords, { isLoading: loadingPostKeywords }] =
    useAddKeywordMutation();
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = {
        name: dialogValue.name,
      };
      const res = await postKeywords({ data: { data } });
      // setValue({
      //   title: dialogValue.title,
      //   year: parseInt(dialogValue.year, 10),
      // });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  // const debounceSearch = useState(() => {
  //   setSelected(selectedCategory);
  //   // dispatch(setTagsId(selectedCategory));
  // }, [selected, setSelectedCategory]);

  const {
    data: collectionUserName,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetKeywordsQuery({
    withCount: false,
    // filter: debouncedSearchTerm,
  });
  console.log(selectedCategory, "selected");
  return (
    <>
      {!isFetchingProfile && !isErrorProfile && selectedCategory && (
        <>
          <Autocomplete
            multiple
            filterSelectedOptions={true}
            id="tags-outlined"
            loading={isFetchingProfile}
            options={collectionUserName?.data}
            // getOptionLabel={(option) => option?.label}
            //   disableClearable
            value={selectedCategory || []}
            inputValue={searchTerm}
            onChange={(event, newValue) => {
              console.log(newValue, "newvalue");
              const filteredValue = newValue.filter((item) => item.id === "");

              if (filteredValue?.[0]?.id === "" && filteredValue?.[0]?.label) {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  console.log(`masuk sini`, searchTerm);
                  toggleOpen(true);
                  setDialogValue({
                    name: searchTerm,
                  });
                });
              } else if (newValue.id === "" && newValue.inputValue) {
                toggleOpen(true);
                setDialogValue({
                  name: newValue.inputValue,
                });
              } else {
                // check if newValue is duplicated return that not duplicated
                // const newValueNotDuplicated = newValue.filter((item) => {
                //   return selectedCategory.includes(item);
                // });
                setSelectedCategory(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== "") {
                filtered.push({
                  // inputValue: params.inputValue,
                  id: "",
                  label: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            getOptionLabel={(option) => {
              // e.g. value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            // selectOnFocus
            // handleHomeEndKeys
            // freeSolo
            // onChange={(event, newValue) => {
            //   //  check if newValueis duplicated remove it
            //   const checkDuplicated = newValue.filter((item) =>
            //     selectedCategory.includes(item)
            //   );

            //   if (checkDuplicated.length > 0) {
            //     const newSelected = selectedCategory.filter(
            //       (item) => !newValue.includes(item)
            //     );
            //     setSelectedCategory(newSelected);
            //   } else {
            //     setSelectedCategory(newValue);
            //   }

            //   //   dispatch(setTagsId(newValue));
            // }}
            onInputChange={(event, newInputValue) => {
              setSearchTerm(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <React.Fragment>
                      {isFetchingProfile ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          <AddKeywords
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            dialogValue={dialogValue}
            setDialogValue={setDialogValue}
          />
        </>
      )}
    </>
  );
};

export default KeywordAutoComplete;
