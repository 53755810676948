import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useUpdatePasswordMutation } from "redux/Services/user";
import { toast } from "sonner";
const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const checkPassword = () => {
    if (!password || !confirmPassword || !oldPassword)
      return { error: true, message: "please fill all the fields" };
    if (password !== confirmPassword)
      return {
        error: true,
        message: "password and confirm password does not match",
      };
    if (
      password.length < 8 ||
      confirmPassword.length < 8 ||
      oldPassword.length < 8
    )
      return {
        error: true,
        message: "password must be atleast 8 characters long",
      };
    return { error: false, message: "password matched" };
  };
  const handleSubmit = async () => {
    try {
      const check = checkPassword();
      if (check?.error) return toast.error(check?.message);
      const data = {
        oldPassword,
        password,
        confirmPassword,
      };
      console.log(data, "<data>");
      const response = await updatePassword({
        data: { data },
      });
      console.log(response, "cek");
      if (response?.error?.data?.error?.message)
        return toast.error(response?.error?.data?.error?.message);
      if (response?.data?.id) {
        setPassword("");
        setConfirmPassword("");
        setOldPassword("");
        toast.success("password changed successfully");
      }
    } catch (error) {}
  };
  return (
    <form noValidate>
      <Stack mt={4} gap={2} alignItems={"center"}>
        <Typography variant="h6">Old Password</Typography>
        <TextField
          className="w-1/2"
          value={oldPassword}
          type={showOldPassword ? "text" : "password"}
          autoComplete="new-passwor"
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  {showOldPassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="h6">New Password</Typography>
        <TextField
          value={password}
          type={showPassword ? "text" : "password"}
          onChange={(e) => setPassword(e.target.value)}
          className="w-1/2"
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  {showPassword ? (
                    <VisibilityOffIcon
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() => setShowPassword(!showPassword)}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="h6">Confirm New Password</Typography>
        <TextField
          value={confirmPassword}
          type={showConfirmPassword ? "text" : "password"}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="w-1/2"
          InputProps={{
            autoComplete: "off",
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  {showConfirmPassword ? (
                    <VisibilityOffIcon
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  ) : (
                    <VisibilityIcon
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          disabled={isLoading}
          onClick={handleSubmit}
          className="w-1/2"
          variant="outlined"
          color="success"
        >
          {isLoading ? <CircularProgress size={20} /> : "Change Password"}
        </Button>
      </Stack>
    </form>
  );
};

export default ChangePassword;
