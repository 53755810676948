export const columnOrder = [
  {
    Header: "status",
    accessor: "status",
  },
  {
    Header: "DATE OF ORDER",
    accessor: "date_of_order",
  },

  {
    Header: "BUYER",
    accessor: "buyer",
  },
  {
    Header: "LIST OF ITEMS",
    accessor: "list_of_items",
  },
];
