import { ToastError } from "components/toast";
import { handleUploadCsvAxios } from "redux/Services/uploadFileCustom";
import { handleUploadFile } from "redux/Services/uploadFileCustom";

export const handleUploadArto = async ({
  loadingFunction,
  files,
  setImageLink,
  setImageId,
}) => {
  try {
    loadingFunction(true);
    const formData = new FormData();
    formData.append("files", files);
    const response = await handleUploadFile(formData);
    const newLink =
      response?.data?.[0]?.formats?.thumbnail?.url || response?.data?.[0]?.url;
    const imageId = response?.data?.[0]?.id;
    console.log(response, "response");
    setImageLink(newLink);
    setImageId(imageId);
    loadingFunction(false);
  } catch (error) {
    loadingFunction(false);
    console.log(error);
    return error;
  }
};

export const handleUploadCsv = async ({ loadingFunction, files }) => {
  try {
    loadingFunction(true);
    const formData = new FormData();
    formData.append("inventory_files", files);
    const response = await handleUploadCsvAxios(formData);
    if (response?.data?.error?.length > 0) {
      loadingFunction(false);
      const newMessageString = response?.data?.error?.join(",</br> ");
      return ToastError.fire({
        title: "Error",
        html: newMessageString,
        icon: "error",
        showCloseButton: true,
        width: "auto",
        onclose: () => {
          console.log(`ini cek`);
          window.location.reload();
        },
      }).then(() => {
        window.location.reload();
      });
    }
    loadingFunction(false);
    ToastError.fire({
      title: "Success",
      text: "Upload success",
      icon: "success",

      showCloseButton: true,
      onclose: () => {
        window.location.reload();
      },
    }).then(() => {
      window.location.reload();
    });
  } catch (error) {
    loadingFunction(false);
    console.log(error);
  }
};
