/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const createLinks = (routes) => {
    return routes.map((route, index) => {
      const getLabel = () => {
        switch (route.path) {
          case "artist":
            return "Profile";
          case "organisation":
            return "Profile";
          case "onboarding":
            return "Preference";
          case "settings":
            return "issue";
          default:
            return "";
        }
      };
      const label = getLabel();

      const to = `${route.layout}/${route.path}${
        label ? `?label=${encodeURIComponent(label)}` : ""
      }`;
      if (route.layout !== "/admin" && route.layout !== "/auth") {
        return null;
      }
      if (route.isDetail) {
        return null;
      }
      const active = activeRoute(route.path);
      const fontWeight = active ? "font-bold" : "font-medium";
      const textColor = active
        ? "text-navy-700 dark:text-white"
        : "text-gray-600";
      const icon = route.icon || <DashIcon />;
      const bgClass = active ? "bg-brand-500 dark:bg-brand-400" : "";
      return (
        <Link key={index} to={to}>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li
              className="my-[3px] flex cursor-pointer items-center px-8"
              key={index}
            >
              <span className={`${fontWeight} text-gray-600`}>{icon}</span>
              <p className={`leading-1 ml-4 flex ${fontWeight} ${textColor}`}>
                {route.name}
              </p>
            </li>
            {active && (
              <div
                className={`absolute right-0 top-px h-9 w-1 rounded-lg ${bgClass}`}
              />
            )}
          </div>
        </Link>
      );
    });
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
