import { useDebounce } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { useGetUserListQuery } from "redux/Services/user";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useGetArtworkAutoCompleteQuery } from "redux/Services/artist";
import { useGetAllSubTypeQuery } from "redux/Services/inventory";
import { useGetAllSubSubTypeQuery } from "redux/Services/inventory";
import { useAddKeywordMutation } from "redux/Services/keyword";
import AddInventories from "components/dialog/AddInventories";
import { useCreaateSubsubTypeMutation } from "redux/Services/inventory";
const filter = createFilterOptions();
const SubSubAutoComplete = ({
  selectedArtworkId,
  setSelectedArtworkId,
  typeId,
  disabled,
  size,
}) => {
  const [collectionUserName, setCollectionUserName] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    data: dataProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetAllSubSubTypeQuery(
    {
      filters: `?filters[Name][$contains]=${debouncedSearchTerm}&filters[subtype_id][id][$eq]=${typeId}`,
    },
    {
      skip: !typeId,
    }
  );

  useEffect(() => {
    // if (detailCollectionId && firstFetch) {
    //   setSelectedUsername({
    //     label: detailCollectionId?.userName,
    //     profileId: detailCollectionId?.profileId,
    //   });
    //   setFirstFetch(false);
    // }
    if (dataProfile) {
      const collectionOfUserName = dataProfile?.data?.map((item) => {
        return {
          label: item.Name,
          subSubTypeId: item.id,
        };
      });
      setCollectionUserName(collectionOfUserName);
    }
  }, [selectedArtworkId, dataProfile, firstFetch]);
  useEffect(() => {
    if (!typeId) {
      setSelectedArtworkId({
        label: "",
        subTypeId: "",
      });
    }
  }, [typeId]);
  const [open, toggleOpen] = React.useState(false);
  const handleClose = () => {
    setDialogValue({
      name: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    name: "",
  });
  const [postSubSub, { isLoading: loadingPostKeywords }] =
    useCreaateSubsubTypeMutation();
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const data = {
        Name: dialogValue.name,
        subtype_id: typeId,
      };
      const res = await postSubSub({ data: { data } });
      // setValue({
      //   title: dialogValue.title,
      //   year: parseInt(dialogValue.year, 10),
      // });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Autocomplete
        disabled={disabled}
        loading={isFetchingProfile}
        size={size === "large" ? "large" : "small"}
        fullWidth
        disablePortfral
        id="combo-box-demo"
        options={collectionUserName || []}
        sx={{
          backgroundColor: size === "large" ? "" : "white",
          width: "250px",
        }} // set the width to 300px
        value={selectedArtworkId}
        getOptionLabel={(option) => option.label}
        onChange={(event, newValue) => {
          event?.preventDefault();

          if (newValue.id === "") {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                name: searchTerm,
              });
            });
          } else {
            setSelectedArtworkId(newValue);
          }
        }}
        onInputChange={(event, newValue) => {
          event?.preventDefault();
          setSearchTerm(newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== "" && filtered?.length === 0) {
            filtered.push({
              // inputValue: params.inputValue,
              id: "",
              label: `Add "${params.inputValue}"`,
            });
            // toggleOpen(true);
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isFetchingProfile ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <AddInventories
        open={open}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        dialogValue={dialogValue}
        setDialogValue={setDialogValue}
        title={"Variety"}
      />
    </>
  );
};

export default SubSubAutoComplete;
