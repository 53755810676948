import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
// access ENV .url
import { DateTime } from "luxon";

const baseUrl = process.env.REACT_APP_API_URL;

export const artPreferenceApi = createApi({
  reducerPath: "artPreferenceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ArtPreference"],
  endpoints: (builder) => ({
    artPreferenceList: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        method: "GET",
        url: `preferences?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=*${
          filter ? `&filters[Name][$containsi]=${filter}` : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, createdAt, image_url_file } = item?.attributes;
          const dateTime = DateTime.fromISO(createdAt);
          const formattedDate = dateTime.toFormat("yyyy-MM-dd");
          return {
            id,
            name: Name,
            time_posted: formattedDate,
            image: image_url_file?.data?.attributes?.url,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["ArtPreference"],
    }),
    deleteArtPreference: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::preference.preference&id_select=${id}`,
      }),
      invalidatesTags: ["ArtPreference"],
    }),
    artPreferenceById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `preferences/${id}?populate=*`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const { Name, image_url_file } = data?.attributes;
        const imageUrl =
          image_url_file?.data?.attributes?.url || artoImagePlacehoder;
        const imageId = image_url_file?.data?.id;
        const newData = {
          Name,
          imageUrl,
          imageId,
        };
        return newData;
      },
      providesTags: ["ArtPreference"],
    }),
    editPreference: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `preferences/${id}`,
        body: data,
      }),
      invalidatesTags: ["ArtPreference"],
    }),
    addPreference: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `preferences`,
        body: data,
      }),
      invalidatesTags: ["ArtPreference"],
    }),
  }),
});

export const {
  useArtPreferenceListQuery,
  useDeleteArtPreferenceMutation,
  useArtPreferenceByIdQuery,
  useEditPreferenceMutation,
  useAddPreferenceMutation,
} = artPreferenceApi;
