import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "./Services/user";
import { commentApi } from "./Services/comment";
import { collectionApi } from "./Services/collection";
import { artistUrl } from "./Services/artist";
import { oraganisationApi } from "./Services/organisation";
import { artPreferenceApi } from "./Services/art-preference";
import { motivationApi } from "./Services/motivation";
import { moodCategoryApi } from "./Services/mood-category";
import { uploadFile } from "./Services/uploadFile";
import { categoryApi } from "./Services/category";
import { contactUsApi } from "./Services/contactUs";
import { orderApi } from "./Services/order";
import { promoApi } from "./Services/promo";
import { inventoryApi } from "./Services/inventory";
import { keywordApi } from "./Services/keyword";
import userSlice from "./Slices/userSlice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [artistUrl.reducerPath]: artistUrl.reducer,
    [oraganisationApi.reducerPath]: oraganisationApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [motivationApi.reducerPath]: motivationApi.reducer,
    [artPreferenceApi.reducerPath]: artPreferenceApi.reducer,
    [moodCategoryApi.reducerPath]: moodCategoryApi.reducer,
    [uploadFile.reducerPath]: uploadFile.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [contactUsApi.reducerPath]: contactUsApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer, // add contactUsApi reducer
    [promoApi.reducerPath]: promoApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [keywordApi.reducerPath]: keywordApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(commentApi.middleware)
      .concat(collectionApi.middleware)
      .concat(oraganisationApi.middleware)
      .concat(motivationApi.middleware)
      .concat(artPreferenceApi.middleware)
      .concat(moodCategoryApi.middleware)
      .concat(uploadFile.middleware)
      .concat(artistUrl.middleware)
      .concat(categoryApi.middleware)
      .concat(contactUsApi.middleware)
      .concat(orderApi.middleware)
      .concat(promoApi.middleware)
      .concat(inventoryApi.middleware)
      .concat(keywordApi.middleware),
});
