import React, { Suspense, useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";

import Tables from "components/table/index";
import { columnMotivation } from "./variables/columnsData";
import { useMotivationListQuery } from "redux/Services/motivation";
import { Stack } from "@mui/material";
import { Button } from "@material-tailwind/react";
import { useLocation, useNavigate } from "react-router-dom";

const MotivationTable = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selected, setSelected] = useState([]);

  const { data, isFetching } = useMotivationListQuery({
    page,
    pageSize,
    filter: debouncedSearchTerm,
  });
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Suspense fallback={isFetching && <div>Loading...</div>}>
      <div className="">
        <Stack alignItems={"end"} mb={2}>
          <Button
            className="mb-3 mt-3 w-1/5"
            color="pink"
            variant="gradient"
            onClick={() => {
              navigate(`/admin/onboarding/motivation/add`, {
                state: { previous: location.pathname + "?label=Motivation" },
              });
            }}
          >
            Add Motivation
          </Button>
        </Stack>
        <Tables
          columnsData={columnMotivation}
          tableData={data?.data || []}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          pageProps={page}
          setPage={setPage}
          totalPage={data?.meta}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </Suspense>
  );
};
export default MotivationTable;
