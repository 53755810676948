import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// access ENV .url
const baseUrl = process.env.REACT_APP_API_URL;

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Category"],
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: () => ({
        method: "GET",
        url: `tags?pagination[start]=0&pagination[limit]=-1`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newCategory = data?.map((item) => {
          const id = item?.id;
          const { Name } = item?.attributes;
          return {
            id,
            label: Name,
          };
        });
        return newCategory;
      },
    }),
  }),
});
export const { useGetCategoriesQuery } = categoryApi;
