import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
import { DateTime } from "luxon";

// access ENV .url
const baseUrl = process.env.REACT_APP_API_URL;

export const artistUrl = createApi({
  reducerPath: "artistUrl",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Artist", "Artwork", "Award", "Exhibition"],
  endpoints: (builder) => ({
    getArtist: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `profiles?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[is_artist][$eq]=true`;
        const filterUrl = filter
          ? `&filters[$or][0][First_Name][$containsi]=${filter}&filters[$or][1][Last_Name][$containsi]=${filter}`
          : "";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { First_Name, Last_Name, Location, createdAt } =
            item?.attributes;
          const uploadedDate = DateTime.fromISO(createdAt);
          const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
          return {
            id,
            first_name: First_Name,
            last_name: Last_Name,
            country: Location,
            uploaded_date: formattedDate,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Artist"],
    }),
    getArtwork: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = false }) => {
        const baseUrl = `artwork/backoffice?page=${page}&limit=${pageSize}&populate=profile,url_file,tags_id,inventories_id`;
        const filterUrl = `&search=${filter}`;
        return {
          url: baseUrl + filterUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const {
            Name,
            profile,
            url_file,
            createdAt,
            tags_id,
            Price,
            Description,
            Height,
            Width,
            Location,
            Medium,
            Year,
            Dimension_Unit,
            inventories_id,
          } = item;

          const fullName =
            profile?.First_Name ?? "" + " " + profile?.Last_Name ?? "";
          const formattedDate =
            DateTime.fromISO(createdAt).toFormat("yyyy-MM-dd") ?? "";
          const tagsArrayName = tags_id.map((item) => item?.Name);
          const thumbnail =
            url_file?.formats?.thumbnail?.url ||
            "https://artostorage.blob.core.windows.net/arto-v5/assets/file_placeholder_c574686b95.jpeg";
          return {
            id,
            title: Name,
            artist: fullName,
            thumbnail: thumbnail,
            uploaded_date: formattedDate,
            tags: tagsArrayName?.join(", "),
            description: Description,
            height: `${Height}  ${Dimension_Unit}`,
            width: `${Width}  ${Dimension_Unit}`,
            location: Location,
            medium: Medium,
            year: Year,
            availability:
              inventories_id?.length > 0 ? inventories_id?.length : 0,
            price: inventories_id?.length > 0 ? Price : "-",
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Artwork"],
    }),
    getArtworkAutoComplete: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", profileId }) => {
        const baseUrl = `artworks?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[Name][$containsi]=${filter}&populate=profile&filters[profile][id][$eq]=${profileId}`;
        return {
          url: baseUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, profile } = item?.attributes;
          const fullName =
            profile?.data?.attributes?.First_Name ??
            "" + " " + profile?.data?.attributes?.Last_Name ??
            "";
          return {
            id,
            name: Name,
            artist: fullName,
          };
        });
        return newData;
      },
      providesTags: ["Artwork"],
    }),
    getArtWorkById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `artworks/${id}?populate=url_file,tags_id,profile.users_permissions_user,keywords_id`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const { id } = data;
        const {
          Name,
          Media_Type,
          Price,
          Location,
          Description,
          Medium,
          Height,
          Width,
          Dimension_Unit,
          Year,
          url_file,
          profile,
          tags_id,
          Keywords,
          keywords_id,
        } = data?.attributes;

        const newTags =
          tags_id?.data?.map((item) => {
            const id = item?.id;
            const { Name } = item?.attributes;
            return {
              id,
              label: Name,
            };
          }) ?? [];
        const keywordsArr = keywords_id?.data?.map((item) => {
          const id = item?.id;
          const { name } = item?.attributes;
          return {
            id,
            label: name,
          };
        });
        const newData = {
          id,
          title: Name,
          media_type: Media_Type,
          price: Price,
          location: Location,
          description: Description,
          medium: Medium,
          height: Height,
          width: Width,
          dimension: Dimension_Unit,
          year: Year,
          url:
            url_file?.data?.attributes?.formats?.thumbnail?.url ||
            artoImagePlacehoder,
          imageId: url_file?.data?.id,
          profileId: profile?.data?.id,
          userName:
            profile?.data?.attributes?.users_permissions_user?.data?.attributes
              ?.username,
          selectedTags: newTags,
          keywords: Keywords,
          keywordsArr,
          // artist: fullName,
          // thumbnail: thumbnail,
          // uploaded_date: formattedDate,
          // tags: tagsArrayName?.join(", "),
          // description: Description,
          // height: `${Height}  ${Dimension_Unit}`,
          // width: `${Width}  ${Dimension_Unit}`,
          // location: Location,
          // medium: Medium,
          // year: Year,
          // availability: inventories_id?.length > 0 ? inventories_id?.length : 0,
          // price: inventories_id?.length > 0 ? Price : "-",
        };
        return newData;
      },
      providesTags: ["Artwork"],
    }),
    getArtworkByIdNew: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `artwork/back-office/${id}`,
      }),
      transformResponse: (response) => {
        const { id } = response;
        const {
          Name,
          Media_Type,
          Price,
          Location,
          Description,
          Medium,
          Height,
          Width,
          Dimension_Unit,
          Year,
          url_file,
          profile,
          tags_id,
          Keywords,
          keywords_id,
          products_id,
          Hide,
        } = response;

        const newTags =
          tags_id?.map((item) => {
            const id = item?.id;
            const { Name } = item;
            return {
              id,
              label: Name,
            };
          }) ?? [];
        const keywordsArr = keywords_id?.map((item) => {
          const id = item?.id;
          const { name } = item;
          return {
            id,
            label: name,
          };
        });

        const papperA2 = products_id?.filter(
          (item) => item?.subtype_id?.Name === "A2"
        );
        const papperA3 = products_id?.filter(
          (item) => item?.subtype_id?.Name === "A3"
        );
        const merchandises = products_id
          ?.filter(
            (item) =>
              item?.type_id?.Name !== "Prints" &&
              item?.type_id?.Name !== "Original Artwork"
          )
          .map((item) => ({
            type_id: item?.type_id?.id,
            name: item?.type_id?.Name,
            is_active: item?.is_active,
            artwork_id: item?.artwork_id,
            stock_quantity: item?.stock_quantity,
            imageList:
              item?.product_image?.map((item) => ({
                id: item?.id,
                url: item?.url,
              })) ?? [],
            product_image: item?.product_image?.map((item) => item?.id) ?? [],
          }));

        const newData = {
          id,
          title: Name,
          media_type: Media_Type,
          price: Price,
          location: Location,
          description: Description,
          medium: Medium,
          height: Height,
          width: Width,
          dimension: Dimension_Unit,
          year: Year,
          url: url_file?.url || artoImagePlacehoder,
          imageId: url_file?.id,
          profileId: profile?.id,
          userName: profile?.users_permissions_user?.username,
          selectedTags: newTags,
          keywords: Keywords,
          keywordsArr,
          papperA2: papperA2?.length > 0 ? papperA2[0] : {},
          papperA3: papperA3?.length > 0 ? papperA3[0] : {},
          descriptionPapper:
            papperA2?.length > 0 ? papperA2[0]?.description : "",
          notesPapper: papperA2?.length > 0 ? papperA2[0]?.notes : "",
          merchandises,
          Hide,
          // artist: fullName,
          // thumbnail: thumbnail,
          // uploaded_date: formattedDate,
          // tags: tagsArrayName?.join(", "),
          // description: Description,
          // height: `${Height}  ${Dimension_Unit}`,
          // width: `${Width}  ${Dimension_Unit}`,
          // location: Location,
          // medium: Medium,
          // year: Year,
          // availability: inventories_id?.length > 0 ? inventories_id?.length : 0,
          // price: inventories_id?.length > 0 ? Price : "-",
        };
        return newData;
      },
      providesTags: ["Artwork"],
    }),
    getAward: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", query = "" }) => {
        const base = `awards?pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
        const filterUrl = filter
          ? `&filters[$or][0][Name][$containsi]=${filter}&filters[$or][1][Year][$containsi]=${filter}&filters[$or][2][Place][$containsi]=${filter}`
          : "";
        const queryUrl = query ? `&filters[profile][id][$eq]=${query}` : "";
        return {
          method: "GET",
          url: base + filterUrl + queryUrl,
        };
      },
      transformResponse: (response) => {
        const { data } = response;

        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, Year, Country, createdAt } = item?.attributes;
          const uploadedDate = DateTime.fromISO(createdAt);
          const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
          return {
            id,
            name: Name,
            year: Year,
            country: Country,
            uploaded_date: formattedDate,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Award"],
    }),
    getAwardById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `awards/${id}?populate=profile.users_permissions_user`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const { id } = data;
        const { Name, Year, Place, profile } = data?.attributes;
        const newData = {
          id,
          name: Name,
          year: Year,
          place: Place,
          profileId: profile?.data?.id,
          userName:
            profile?.data?.attributes?.users_permissions_user?.data?.attributes
              ?.username,
        };
        return newData;
      },
      providesTags: ["Award"],
    }),
    updateAward: builder.mutation({
      query: ({ id, data }) => ({
        url: `awards/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Award"],
    }),
    createAward: builder.mutation({
      query: ({ data }) => ({
        url: `awards`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Award"],
    }),
    getExhibition: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", query = "" }) => {
        const base = `exhibitions?pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
        const filterUrl = filter
          ? `&filters[$or][0][Name][$containsi]=${filter}&filters[$or][1][Year][$containsi]=${filter}&filters[$or][2][Place][$containsi]=${filter}`
          : "";
        const queryUrl = query ? `&filters[profiles][id][$eq]=${query}` : "";
        return {
          method: "GET",
          url: base + filterUrl + queryUrl,
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, Year, Place, createdAt } = item?.attributes;
          const uploadedDate = DateTime.fromISO(createdAt);
          const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
          return {
            id,
            name: Name,
            year: Year,
            Place,
            uploaded_date: formattedDate,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Exhibition"],
    }),
    getExhibitionById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `exhibitions/${id}?populate=profiles.users_permissions_user`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const { id } = data;
        const { Name, Year, Place, profiles } = data?.attributes;

        const selected = profiles?.data?.map((item) => {
          const { id } = item;
          const { username } =
            item?.attributes?.users_permissions_user?.data?.attributes;
          return {
            id,
            label: username,
          };
        });
        const newData = {
          id,
          name: Name,
          year: Year,
          place: Place,
          selected,
        };

        return newData;
      },
      providesTags: ["Exhibition"],
    }),
    updateExhibition: builder.mutation({
      query: ({ id, data }) => ({
        url: `exhibitions/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Exhibition"],
    }),
    createExhibition: builder.mutation({
      query: ({ data }) => ({
        url: `exhibitions`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Exhibition"],
    }),
    updateArtwork: builder.mutation({
      query: ({ id, data }) => ({
        url: `artwork/back-office/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Artwork"],
    }),
    createArtwork: builder.mutation({
      query: ({ data }) => ({
        url: `artwork/back-office`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Artwork"],
    }),
    deleteExhibition: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::exhibition.exhibition&id_select=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Exhibition"],
    }),
    deleteAward: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::award.award&id_select=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Award"],
    }),
    deleteArtwork: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::artwork.artwork&id_select=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Artwork"],
    }),
    getArtWorkByKeyword: builder.query({
      query: ({ keyword }) => ({
        url: `artworks?populate=profile.users_permissions_user,url_file&${keyword}&pagination[limit]=-1`,
        method: "GET",
      }),
      providesTags: ["Artwork"],
      transformResponse: (response) => {
        const { data } = response;

        const newData = data?.map((item) => {
          const idArtwork = item?.id;
          const { Name, url_file } = item?.attributes;
          const urlFile =
            url_file?.data?.attributes?.url || artoImagePlacehoder;
          const idImage = url_file?.data?.id;
          return {
            id: idArtwork,
            idImage,
            name: Name,
            url: urlFile,
          };
        });
        return newData;
      },
    }),
    addArtist: builder.mutation({
      query: ({ data }) => ({
        url: `back-office/user-profile?is_artist=true`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Artist"],
    }),
    sendEmail: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/send-password?profile_id=${id}`,
        method: "POST",
      }),
      invalidatesTags: ["Artist"],
    }),
  }),
});

export const {
  useGetArtistQuery,
  useGetArtworkQuery,
  useGetAwardQuery,
  useGetExhibitionQuery,
  useGetArtWorkByIdQuery,
  useUpdateArtworkMutation,
  useCreateArtworkMutation,
  useGetAwardByIdQuery,
  useUpdateAwardMutation,
  useCreateAwardMutation,
  useGetExhibitionByIdQuery,
  useUpdateExhibitionMutation,
  useCreateExhibitionMutation,
  useDeleteExhibitionMutation,
  useDeleteAwardMutation,
  useDeleteArtworkMutation,
  useGetArtWorkByKeywordQuery,
  useAddArtistMutation,
  useSendEmailMutation,
  useGetArtworkAutoCompleteQuery,
  useGetArtworkByIdNewQuery,
} = artistUrl;
