import { Input, Typography, Button, Avatar } from "@material-tailwind/react";
import { artoImagePlacehoder } from "helpers/placeholder";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useArtPreferenceByIdQuery } from "redux/Services/art-preference";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { CircularProgress, Stack } from "@mui/material";
import { handleUploadArto } from "helpers/uploadFile";
import { useAddInitiativeOrgMutation } from "redux/Services/organisation";
import { useEditPreferenceMutation } from "redux/Services/art-preference";
import { toast } from "sonner";
import { useAddPreferenceMutation } from "redux/Services/art-preference";

const ArtPreferenceDetails = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fileRef = useRef(null);
  const { id } = useParams();
  const {
    data: detailPreference,
    isLoading,
    isError,
  } = useArtPreferenceByIdQuery(
    {
      id: id,
    },
    {
      skip: id === "add",
    }
  );
  const [detailPreferenceState, setDetailPreferenceState] = useState("");
  const [image, setImage] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e, name) => {
    setDetailPreferenceState({
      ...detailPreferenceState,
      [name]: e.target.value,
    });
  };
  useEffect(() => {
    if (detailPreference) {
      setDetailPreferenceState(detailPreference);
      setImage(detailPreference.imageUrl);
      setImageId(detailPreference.imageId);
    }
  }, [detailPreference]);
  const handleClick = () => {
    fileRef.current.click();
  };
  const [addArtPreference, { isLoading: addLoading }] =
    useAddPreferenceMutation();
  const [editArtPreference, { isLoading: editLoading }] =
    useEditPreferenceMutation();
  const handleSubmit = async () => {
    try {
      const data = {
        ...detailPreferenceState,
        image_url_file: {
          id: imageId,
        },
      };
      if (id === "add") {
        await addArtPreference({ data: { data } });
        toast.success("Add Art Preference Success");
        // setTimeout(() => {
        //   navigate("/admin/onboarding?label=Art%20Preference", {
        //     state: { previous: location.pathname },
        //   });
        // }, 1000);
      } else {
        await editArtPreference({
          id: id,
          data: { data },
        });
        toast.success("Edit Art Preference Success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setImage,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <div className="w-full ">
        <div className=" ml-10 mt-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
              // history(-1);
              //add window refresh
              // window.location.reload();
            }}
          >
            <ArrowBackIcon />
          </div>
          <p className="font flex w-full justify-items-center text-2xl font-bold">
            {id === "add" ? "Add Art Preference" : "Edit Art Preference"}
          </p>
        </div>
        <div className="flex-cols-2 flex w-full justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <Typography
              variant="small"
              color="blue-gray-300"
              className="mb-2 mt-2 font-medium"
            >
              Featured Image
            </Typography>
            <div className=" mt-5 flex flex-col  gap-1">
              {loading ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <img
                  className="h-4/5 w-3/5 rounded-lg object-cover object-center"
                  src={image}
                  alt="nature image"
                />
              )}
              <Button
                className="mb-3 mt-3 w-3/5"
                color="pink"
                variant="gradient"
                onClick={handleClick}
              >
                {id === "add" ? "Choose" : "Edit"}
              </Button>
            </div>

            <div className="w-3/5">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Art Preference Name
              </Typography>
              <Input
                type="text"
                value={detailPreferenceState?.Name}
                onChange={(e) => handleChange(e, "Name")}
              />
            </div>

            <div className="mb-14 mt-6">
              {addLoading || editLoading ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <Button
                  className="mb-3 mt-3 w-3/5"
                  color="pink"
                  variant="gradient"
                  onClick={handleSubmit}
                >
                  {id === "add" ? "Add" : "Save"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArtPreferenceDetails;
