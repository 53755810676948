export const columnsUser = [
  {
    Header: "",
    accessor: "id_",
  },
  {
    Header: "FIRST NAME",
    accessor: "first_name",
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "LAST LOGIN",
    accessor: "last_login",
  },
  {
    Header: "RETURN LOGIN",
    accessor: "return_login",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
];
