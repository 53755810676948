import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_URL;

export const uploadFile = createApi({
  reducerPath: "uploadFile",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
        // headers.set("Content-Type", "multipart/form-data");
      }
      return headers;
    },
  }),
  tagTypes: ["UploadFile"],
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("files", file);
        return {
          url: "upload",
          method: "POST",
          body: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          formData: true,
        };
      },
      invalidatesTags: ["UploadFile"],
    }),
  }),
});

export const { useUploadFileMutation } = uploadFile;
