import Tables from "components/table/index";

import { columnOrder } from "./variables/columnsData";
import { useState } from "react";
import { useDebounce } from "@uidotdev/usehooks";
import { useGetOrdersQuery } from "redux/Services/order";

const OrdersView = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [selected, setSelected] = useState([]);

  const { data: OrdersData, isLoading } = useGetOrdersQuery({
    page: page,
    pageSize: pageSize,
    search: debouncedSearchTerm,
  });
  console.log(OrdersData, "OrdersData");
  return (
    <div className="mt-5">
      <Tables
        columnsData={columnOrder}
        tableData={OrdersData?.data || []}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={OrdersData?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};
export default OrdersView;
