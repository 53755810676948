import { Input, Typography, Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useGetAwardByIdQuery } from "redux/Services/artist";
import { Toaster, toast } from "sonner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import { CircularProgress, TextField } from "@mui/material";
import AutoCompleteUsername from "components/autocomplete/username";
import { useUpdateAwardMutation } from "redux/Services/artist";
import { useCreateAwardMutation } from "redux/Services/artist";

const AwardDetails = () => {
  let navigate = useNavigate();
  const location = useLocation();

  // how to extract id from params using react?
  const { id } = useParams();
  const {
    data: detailAward,
    isLoading,
    error,
  } = useGetAwardByIdQuery({ id }, { skip: id === "add" });

  const [searchParams] = useSearchParams();
  const [selectedUsername, setSelectedUsername] = useState({
    label: "",
    profileId: "",
  });
  const [firstFetch, setFirstFetch] = useState(true);
  const [dataDetail, setDataDetail] = useState("");

  useEffect(() => {
    if (detailAward && firstFetch) {
      setDataDetail(detailAward);
      setSelectedUsername({
        label: detailAward?.userName,
        profileId: detailAward?.profileId,
      });
      setFirstFetch(false);
    }
  }, [detailAward]);
  const handleChange = (e, name) => {
    setDataDetail({ ...dataDetail, [name]: e.target.value });
  };
  const [updateAward, { data, isLoading: isLoadingUpdate }] =
    useUpdateAwardMutation();
  const [createAward, { data: dataCreate, isLoading: isLoadingCreate }] =
    useCreateAwardMutation();
  const handleSumbit = async () => {
    try {
      const data = {
        id: +id,
        Name: dataDetail.name,
        Place: dataDetail.place,
        Year: dataDetail.year,
        profile: {
          id: selectedUsername.profileId,
        },
      };
      if (id === "add") {
        const res = await createAward({ data: { data } });
        toast.success("Create Award Success");

        setTimeout(() => {
          navigate("/admin/artist?label=Awards", {
            state: { previous: `/admin/artist/award-details/add` },
          });
        }, 2000);
      } else {
        const res = await updateAward({ id: +id, data: { data } });
        toast.success("Update Award Success");
      }
    } catch (error) {}
  };

  return (
    // <div class="grid grid-flow-col grid-rows-3 gap-4">
    //   <div class="... row-span-3">
    <>
      <Toaster richColors position="top-right" />

      <div className="w-full ">
        <div className="ml-10 mt-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
            }}
          >
            <ArrowBackIcon />
          </div>

          <p className="font flex w-full justify-items-center text-2xl font-bold">
            {id === "add" ? "Add Award" : "Edit Award"}
          </p>
        </div>

        <div className="flex-cols-2 flex justify-items-center  gap-4">
          {/* <div className="ml-10 mt-10 flex flex-col justify-center gap-1">
          <img
            className="h-96 w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1682407186023-12c70a4a35e0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2832&q=80"
            alt="nature image"
          />
          <Button className="mt-3" color="pink" variant="gradient">
            Edit Photo
          </Button>
        </div> */}
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Name Of Award
              </Typography>
              <Input
                type="text"
                value={dataDetail.name}
                onChange={(e) => handleChange(e, "name")}
              />
            </div>
            <div className="w-4/6">
              <Typography
                variant="small"
                color="blue-gray"
                className="mb-4 mt-2 font-medium"
              >
                Place
              </Typography>
              <Input
                type="text"
                value={dataDetail.place}
                onChange={(e) => handleChange(e, "place")}
              />
            </div>
            {/* <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Date
              </Typography>
              <DatePicker
                label=""
                variant="outlined"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </div> */}
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Year
              </Typography>
              <Input
                type="text"
                value={dataDetail.year}
                onChange={(e) => handleChange(e, "year")}
              />
            </div>
            {/* <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Location
              </Typography>
              <Input type="email" />
            </div> */}
            <div className="w-4/6">
              <Typography
                variant="small"
                color="grey"
                className="mb-4 mt-2 font-medium"
              >
                Username
              </Typography>
              {/* <Input type="text" value={dataDetail.userName} /> */}

              <AutoCompleteUsername
                selectedUsername={selectedUsername}
                setSelectedUsername={setSelectedUsername}
                detailCollectionId={detailAward}
                isArtist={true}
              />
            </div>

            <div className=" mt-14 flex items-center justify-center gap-4">
              {isLoadingUpdate || isLoadingCreate ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleSumbit} color="pink" variant="gradient">
                  {id === "add" ? "Add Award" : "Edit Award"}
                </Button>
              )}
              {/* <Button color="pink" variant="gradient">
                Send Email
              </Button>
              <Button color="pink" variant="gradient">
                Blacklist
              </Button>
              <Button color="pink" variant="gradient">
                Suspend
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AwardDetails;
