import { useDebounce } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import { useGetUserListQuery } from "redux/Services/user";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

const AutoCompleteUsername = ({
  selectedUsername,
  setSelectedUsername,
  detailCollectionId,
  isArtist,
  size,
  disabled,
}) => {
  const [collectionUserName, setCollectionUserName] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const {
    data: dataProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
  } = useGetUserListQuery({
    withCount: false,
    userName: debouncedSearchTerm,
    isArtist: isArtist ? true : false,
  });
  useEffect(() => {
    if (detailCollectionId && firstFetch) {
      setSelectedUsername({
        label: detailCollectionId?.userName,
        profileId: detailCollectionId?.profileId,
      });
      setFirstFetch(false);
    }
    if (dataProfile) {
      const collectionOfUserName = dataProfile?.data?.map((item) => {
        return {
          label: item.userName,
          profileId: item.id,
        };
      });
      setCollectionUserName(collectionOfUserName);
    }
  }, [selectedUsername, dataProfile, firstFetch]);
  return (
    <>
      <Autocomplete
        disabled={disabled}
        loading={isFetchingProfile}
        size={size === "large" ? "large" : "small"}
        fullWidth
        disablePortfral
        id="combo-box-demo"
        options={collectionUserName || []}
        sx={{
          backgroundColor: size === "large" ? "" : "white",
          width: "250px",
        }} // set the width to 300px
        value={selectedUsername}
        onChange={(event, newValue) => {
          event?.preventDefault();

          setSelectedUsername(newValue);
        }}
        onInputChange={(event, newValue) => {
          event?.preventDefault();
          setSearchTerm(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { backgroundColor: disabled ? "#cccccc" : "" },
              endAdornment: (
                <React.Fragment>
                  {isFetchingProfile ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteUsername;
