import Links from "./components/Links";
import routes from "routes.js";
import artoLogo from "assets/img/icon.png";

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <div className={`mx-[56px] mt-[70px] flex items-center`}>
        <div
          className="absolute flex h-24 w-24 items-end justify-center bg-cover bg-center"
          style={{ backgroundImage: `url(${artoLogo})` }}
        />
      </div>
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={routes} />
      </ul>


      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
