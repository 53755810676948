import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DateTime } from "luxon";
const baseUrl = process.env.REACT_APP_API_URL;

export const contactUsApi = createApi({
  reducerPath: "contactUsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["ContactUs", "IssueType"],

  endpoints: (builder) => ({
    addContactUs: builder.mutation({
      query: (data) => ({
        url: "/contact-us",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContactUs"],
    }),
    listContactUs: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `/contact-uses?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=issue_image,issue_id`;
        const filterUrl = filter
          ? `&filters[message][$containsi]=${filter}`
          : "";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item.id;
          const { message, issue_image, createdAt, issue_id } = item.attributes;
          const imageId = issue_image?.data?.id;
          const imageUrl = issue_image?.data?.attributes?.url;
          const uploadedDate = DateTime.fromISO(createdAt);
          const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
          return {
            id,
            issue: issue_id?.data?.attributes?.issue,
            message,
            imageId,
            imageUrl,
            created_at: formattedDate,
          };
        });
        return {
          data: newData,
          meta: response.meta,
        };
      },
      providesTags: ["ContactUs"],
    }),
    getContactUsById: builder.query({
      query: ({ id }) => `/contact-uses/${id}?populate=*`,
      providesTags: ["ContactUs"],
      transformResponse: (response) => {
        const { data } = response;
        const id = data.id;
        const { message, issue_image, createdAt, issue } = data.attributes;
        const imageId = issue_image?.data?.id;
        const imageUrl = issue_image?.data?.attributes?.url;

        return {
          id,
          issue,
          message,
          imageId,
          imageUrl,
        };
      },
    }),
    listIssueType: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "", withCount = "" }) => {
        const baseUrl = `/issues?pagination[start]=0&pagination[limit]=2000&sort=issue_index:asc`;
        const filterUrl = filter ? `&filters[issue][$containsi]=${filter}` : "";
        const withCountUrl = withCount ? `&pagination[withCount]=true` : "";
        return {
          url: baseUrl + filterUrl + withCountUrl,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item, index) => {
          const id = item.id;
          const { issue, createdAt, issue_index } = item.attributes;
          const uploadedDate = DateTime.fromISO(createdAt);
          const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
          return {
            issue_index,
            id,
            issue,
            created_at: formattedDate,
          };
        });
        return {
          data: newData,
          meta: response.meta,
        };
      },
      providesTags: ["IssueType"],
    }),
    getIssueTypeById: builder.query({
      query: ({ id }) => `/issues/${id}?populate=*`,
      providesTags: ["IssueType"],
      transformResponse: (response) => {
        const { data } = response;
        const id = data.id;
        const { issue, createdAt } = data.attributes;
        const uploadedDate = DateTime.fromISO(createdAt);
        const formattedDate = uploadedDate.toFormat("yyyy-MM-dd");
        return {
          id,
          issue,
          created_at: formattedDate,
        };
      },
    }),
    EditOrAddIssueType: builder.mutation({
      query: ({ id, data }) => {
        if (id) {
          return {
            url: `/issues/${id}`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: "/issues",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["IssueType"],
    }),
    deleteIssueType: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::issue.issue&id_select=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IssueType"],
    }),
    editIndexIssueType: builder.mutation({
      query: ({ data }) => ({
        url: `/issue/update_all_index`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["IssueType"],
    }),
  }),
});

export const {
  useAddContactUsMutation,
  useListContactUsQuery,
  useGetContactUsByIdQuery,
  useListIssueTypeQuery,
  useGetIssueTypeByIdQuery,
  useEditOrAddIssueTypeMutation,
  useDeleteIssueTypeMutation,
  useEditIndexIssueTypeMutation,
} = contactUsApi;
