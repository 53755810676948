// create slice

import { createSlice } from "@reduxjs/toolkit";
import { Toast } from "components/toast";

const initialState = {
  jwt: "",
  userName: "",
  counter: 0,
  tags_id: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoginSliceAction: (state, action) => {
      state.jwt = action.payload.jwt;
      state.userName = action.payload.user.username;
      localStorage.setItem("jwt", action.payload.jwt);
      localStorage.setItem("userName", action.payload.user.username);
      Toast.fire({
        icon: "success",
        title: "Welcome",
      });
    },
    userLogoutSliceAction: (state) => {
      state.jwt = "";
      state.userName = "";
      localStorage.clear();
      Toast.fire({
        icon: "success",
        title: "Logout successfully",
      });
    },
    addCounter: (state) => {
      state.counter += 1;
      console.log(state.counter, "didalem");
    },
    setTagsId: (state, action) => {
      state.tags_id = action.payload;
    },
  },
});

export const {
  userLoginSliceAction,
  userLogoutSliceAction,
  addCounter,
  setTagsId,
} = userSlice.actions;

export default userSlice;
