import { Input, Typography, Button, Avatar } from "@material-tailwind/react";
import { Stack, Box, CircularProgress } from "@mui/material";
import { artoImagePlacehoder } from "helpers/placeholder";
import { handleUploadArto } from "helpers/uploadFile";

import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEditMoodCategoryMutation } from "redux/Services/mood-category";
import { useAddMoodCategoryMutation } from "redux/Services/mood-category";
import { useMoodCategoryByidQuery } from "redux/Services/mood-category";
import { toast } from "sonner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";

const MoodDetails = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const fileRef = useRef(null);
  const fileRef2 = useRef(null);
  const { id } = useParams();
  const { data: detailMood } = useMoodCategoryByidQuery(
    { id },
    { skip: id === "add" }
  );
  const [addMood, { isLoading: addLoading }] = useAddMoodCategoryMutation();
  const [editMood, { isLoading: editLoading }] = useEditMoodCategoryMutation();
  const [detailMoodState, setDetailMoodState] = useState("");
  const [image, setImage] = useState(artoImagePlacehoder);
  const [imageId, setImageId] = useState("");
  const [image2, setImage2] = useState(artoImagePlacehoder);
  const [imageId2, setImageId2] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChange = (e, name) => {
    setDetailMoodState({
      ...detailMoodState,
      [name]: e.target.value,
    });
  };
  const handleClick = () => {
    fileRef.current.click();
  };
  const handleClick2 = () => {
    fileRef2.current.click();
  };
  const handleSubmit = async () => {
    try {
      const data = {
        ...detailMoodState,
        image_url_file: {
          id: imageId,
        },
        bg_url_file: {
          id: imageId2,
        },
      };
      if (id === "add") {
        await addMood({ data: { data } });
        toast.success("Success Add Mood");
        setTimeout(() => {
          navigate("/admin/mood-categories", {
            state: { previous: location.pathname },
          });
        }, 1000);
      } else {
        await editMood({ id, data: { data } });
        toast.success("Success Edit Mood");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (detailMood) {
      setDetailMoodState(detailMood);
      setImage(detailMood.imageUrl);
      setImageId(detailMood.imageId);
      setImage2(detailMood.backgroundUrl);
      setImageId2(detailMood.backgroundId);
    }
  }, [detailMood]);

  return (
    // <div class="grid grid-flow-col grid-rows-3 gap-4">
    //   <div class="... row-span-3">
    <>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setImage,
              setImageId,
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <div>
        <input
          onChange={(e) =>
            handleUploadArto({
              loadingFunction: setLoading,
              files: e.target.files[0],
              setImageLink: setImage2,
              setImageId: setImageId2,
            })
          }
          ref={fileRef2}
          type="file"
          className="hidden"
        />
      </div>
      <div className="w-full ">
        <div className=" ml-10 mt-10 flex flex-row items-center justify-between gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(location?.state?.previous || "/admin/default");
              // history(-1);
              //add window refresh
              // window.location.reload();
            }}
          >
            <ArrowBackIcon />
          </div>
          <p className="font flex w-full justify-items-center text-2xl font-bold">
            {id === "add" ? "Add Mood Category" : "Edit Mood Category"}
          </p>
        </div>
        <div className="flex-cols-2 flex w-full justify-items-center  gap-4">
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <Typography
              variant="small"
              color="blue-gray-300"
              className="mb-2 mt-2 font-medium"
            >
              Mood Image
            </Typography>
            <div className=" mt-5 flex flex-col gap-1">
              {loading ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <img
                  className="h-4/5 w-3/5 rounded-lg object-cover object-center"
                  src={image}
                  alt="nature image"
                />
              )}
              <Button
                className="mb-3 mt-3 w-3/5"
                color="pink"
                variant="gradient"
                onClick={handleClick}
              >
                {id === "add" ? "Upload" : "Change"}
              </Button>
            </div>

            <div className="w-3/5">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-4 mt-2 font-medium"
              >
                Mood Name
              </Typography>
              <Input
                type="text"
                value={detailMoodState?.Name}
                onChange={(e) => handleChange(e, "Name")}
              />
            </div>
          </div>
          <div className="ml-10 mt-10 flex w-4/6 flex-col">
            <Typography
              variant="small"
              color="blue-gray-300"
              className="mb-2 mt-2 font-medium"
            >
              Background Image
            </Typography>
            <div className=" mt-5 flex flex-col  gap-1">
              {loading ? (
                <Stack>
                  <CircularProgress />
                </Stack>
              ) : (
                <img
                  className="h-4/5 w-3/5 rounded-lg object-cover object-center"
                  src={image2}
                  alt="nature image"
                />
              )}
              <Button
                className="mb-3 mt-3 w-3/5"
                color="pink"
                variant="gradient"
                onClick={handleClick2}
              >
                {id === "add" ? "Upload" : "Change"}
              </Button>
            </div>
            <Stack className="w-4/6 ">
              <Typography
                variant="small"
                color="blue-gray-300"
                className="mb-2 mt-2 font-medium"
              >
                Background Color
              </Typography>
              {/* <Box
                width={300}
                height={150}
                style={{
                  backgroundColor: detailMoodState?.Bg_Color || "#0000",
                }}
              ></Box> */}
              <Input
                type="color"
                value={detailMoodState?.Bg_Color}
                onChange={(e) => handleChange(e, "Bg_Color")}
              />
            </Stack>
          </div>
        </div>
      </div>
      <Stack alignItems={"center"} justifyContent={"center"} mt={5}>
        {loading || addLoading || editLoading ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={handleSubmit}
            className="mb-3 mt-3 w-3/5"
            color="pink"
            variant="gradient"
          >
            Done
          </Button>
        )}
      </Stack>
    </>
  );
};
export default MoodDetails;
