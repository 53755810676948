export const columnProfile = [
  {
    Header: "FIRST NAME",
    accessor: "first_name",
  },
  {
    Header: "LAST NAME",
    accessor: "last_name",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];

export const columnArtwork = [
  {
    Header: "TITLE",
    accessor: "title",
  },
  {
    Header: "ARTIST",
    accessor: "artist",
  },
  {
    Header: "THUMBNAILS",
    accessor: "thumbnail",
  },
  {
    Header: "PRICE",
    accessor: "price",
  },

  {
    Header: "AVAILABILITY",
    accessor: "availability",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "HEIGHT",
    accessor: "height",
  },
  {
    Header: "WIDTH",
    accessor: "width",
  },
  {
    Header: "LOCATION",
    accessor: "location",
  },
  {
    Header: "MEDIUM",
    accessor: "medium",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "TAGS",
    accessor: "tags",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];

export const columnExhibition = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "Place",
    accessor: "Place",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];

export const columnAward = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "YEAR",
    accessor: "year",
  },
  {
    Header: "UPLOADED DATE",
    accessor: "uploaded_date",
  },
];
