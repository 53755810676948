import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { artoImagePlacehoder } from "helpers/placeholder";
// access ENV .url
import { DateTime } from "luxon";

const baseUrl = process.env.REACT_APP_API_URL;

export const motivationApi = createApi({
  reducerPath: "motivationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        headers.set("Authorization", `bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Motivation"],
  endpoints: (builder) => ({
    motivationList: builder.query({
      query: ({ page = 1, pageSize = 10, filter = "" }) => ({
        method: "GET",
        url: `motivations?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=*${
          filter ? `&filters[Name][$containsi]=${filter}` : ""
        }`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const newData = data?.map((item) => {
          const id = item?.id;
          const { Name, createdAt, image_url_file } = item?.attributes;
          const dateTime = DateTime.fromISO(createdAt);
          const formattedDate = dateTime.toFormat("yyyy-MM-dd");
          return {
            id,
            name: Name,
            time_posted: formattedDate,
            image: image_url_file?.data?.attributes?.url,
          };
        });
        const newResponse = {
          meta: response?.meta,
          data: newData,
        };
        return newResponse;
      },
      providesTags: ["Motivation"],
    }),
    deleteMotivation: builder.mutation({
      query: ({ id }) => ({
        method: "DELETE",
        url: `back-office/dynamic-delete?model=api::motivation.motivation&id_select=${id}`,
      }),
      invalidatesTags: ["Motivation"],
    }),
    motivationById: builder.query({
      query: ({ id }) => ({
        method: "GET",
        url: `motivations/${id}?populate=*`,
      }),
      transformResponse: (response) => {
        const { data } = response;
        const id = data?.id;
        const { Name, image_url_file } = data?.attributes;
        return {
          id,
          Name,
          imageUrl:
            image_url_file?.data?.attributes?.url || artoImagePlacehoder,
          imageId: image_url_file?.data?.id,
        };
      },
      providesTags: ["Motivation"],
    }),
    editMotivation: builder.mutation({
      query: ({ id, data }) => ({
        method: "PUT",
        url: `motivations/${id}`,
        body: data,
      }),
      invalidatesTags: ["Motivation"],
    }),
    addMotivation: builder.mutation({
      query: ({ data }) => ({
        method: "POST",
        url: `motivations`,
        body: data,
      }),
      invalidatesTags: ["Motivation"],
    }),
  }),
});

export const {
  useMotivationListQuery,
  useDeleteMotivationMutation,
  useMotivationByIdQuery,
  useEditMotivationMutation,
  useAddMotivationMutation,
} = motivationApi;
