import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetOrderByIdQuery } from "redux/Services/order";
import { DateTime } from "luxon";
import OrderDetailCard from "./OrderDetailCard";
import { artoImagePlacehoder } from "helpers/placeholder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // import { set } from "react-hook-form";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useUpdateOrderMutation } from "redux/Services/order";

const OrderDetail = () => {
  const [updateOrder, { isLoading: loadingUpdate }] = useUpdateOrderMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = React.useState(1);

  const handleChange = async (event) => {
    setStatus(event.target.value);
    try {
      const data = {
        Status: event.target.value === 1 ? "" : "done",
      };
      const result = await updateOrder({ id: id, data: { data } });
    } catch (error) {}
  };
  //   const { id } = useParams;
  //   console.log(id, "iddd");
  //   const { data: orderData, isLoading } = useGetOrderByIdQuery(
  //     { id: id },
  //     { skip: !id }
  //   );
  //   console.log(orderData, "<orderData>");

  const { id } = useParams();

  const { data: orderData, isLoading } = useGetOrderByIdQuery(
    { id: id },
    { skip: !id }
  );

  // const calculateAllPrice = () => {
  //   let total = 0;
  //   orderData?.order_details_id?.forEach((item) => {
  //     total += item?.inventory_id?.product_price * item?.inventory_id?.quantity;
  //   });
  //   return total;
  // };
  // const getPromoDiscoutPercentage = () => {
  //   const promoData = orderData?.promos_id[0];
  //   const isPromoPecentage = promoData?.percentage ? true : false;
  //   if (isPromoPecentage) {
  //     const resultString = `- $${
  //       calculateAllPrice() * (promoData?.percentage / 100)
  //     } (${promoData?.percentage}%)`;
  //     return resultString;
  //   } else {
  //     return 0;
  //   }
  // };
  // const handlePromos = (string) => {
  //   if (orderData?.promos_id?.length > 0) {
  //     const promoData = orderData?.promos_id[0];
  //     const isPromoPecentage = promoData?.percentage ? true : false;

  //     switch (string) {
  //       default:
  //         return;
  //       case "name":
  //         return promoData?.name;
  //       case "discount":
  //         // return `${
  //         //   calculateAllPrice() *
  //         //   (promoData?.percentage / 100)(promoData?.percentage)
  //         // }`;
  //         return `${
  //           isPromoPecentage
  //             ? getPromoDiscoutPercentage()
  //             : `- $${promoData?.fix_amount}`
  //         }`;
  //     }
  //   } else {
  //     switch (string) {
  //       default:
  //         return;
  //       case "name":
  //         return "";
  //       case "discount":
  //         // return `${
  //         //   calculateAllPrice() *
  //         //   (promoData?.percentage / 100)(promoData?.percentage)
  //         // }`;
  //         return 0;
  //     }
  //   }
  // };
  const handleClick = () => {
    window.open(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${orderData?.profile?.email}`
    );
  };
  useEffect(() => {
    if (orderData?.order?.Is_Paid && orderData?.order?.Status === "done") {
      setStatus(2);
    }
  }, [orderData]);
  return (
    <div className="px-4 py-14 2xl:container md:px-6 2xl:mx-auto 2xl:px-20">
      <div className="item-start flex flex-col justify-start space-y-2 ">
        <div className="flex flex-row items-center gap-2">
          <div className="flex justify-between">
            <div
              role="button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowBackIcon size={12} />
            </div>
          </div>
          <h1 className="text-3xl font-semibold leading-7 text-gray-800 lg:text-4xl  lg:leading-9">
            Order #{orderData?.order?.id || ""}
          </h1>
        </div>
        <p className="text-base font-medium leading-6 text-gray-600">
          {orderData?.order?.createdAt
            ? DateTime.fromISO(orderData?.order?.createdAt)
                .setLocale("sg")
                .toLocaleString(DateTime.DATETIME_FULL)
            : ""}
        </p>
      </div>
      <div className="jusitfy-center mt-10 flex w-full flex-col items-stretch  space-y-4 md:space-y-6 xl:flex-row xl:space-x-8 xl:space-y-0">
        <div className="flex w-full flex-col items-start justify-start space-y-4 md:space-y-6 xl:space-y-8">
          <div className="flex w-full flex-col items-start justify-start bg-gray-50 px-4 py-4 md:p-6 md:py-6 xl:p-8">
            <p className="text-lg font-semibold leading-6 text-gray-800 md:text-xl xl:leading-5">
              Buyer's Cart
            </p>

            {orderData?.data?.length > 0
              ? orderData?.data?.map((item, index) => (
                  <OrderDetailCard item={item} index={index} />
                ))
              : null}
          </div>
          <div className="flex w-full flex-col items-stretch justify-center space-y-4 md:flex-row md:space-x-6 md:space-y-0 xl:space-x-8">
            <div className="flex w-full flex-col space-y-6 bg-gray-50 px-4 py-6 md:p-6 xl:p-8   ">
              <h3 className="text-xl font-semibold leading-5 text-gray-800">
                Summary
              </h3>
              <div className="flex w-full flex-col items-center justify-center space-y-4 border-b border-gray-200 pb-4">
                <div className="flex w-full  justify-between">
                  <p className="text-base leading-4 text-gray-800">Subtotal</p>
                  <p className="text-base leading-4 text-gray-600">
                    {/* ${orderData} */}$ {orderData?.total_original}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <p className="text-base leading-4 text-gray-800">
                    Discount{" "}
                    {orderData?.promo_id?.name ? (
                      <span className="bg-gray-200 p-1 text-xs font-medium leading-3  text-gray-800">
                        {orderData?.promo_id?.name}
                      </span>
                    ) : null}
                  </p>
                  <p className="text-base leading-4 text-gray-600">
                    {/* {handlePromos("discount")} */}
                    {/* {handlePromos()} */}- $ {orderData?.total_promo}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between">
                  <p className="text-base leading-4 text-gray-800">Shipping</p>
                  <p className="text-base leading-4 text-gray-600">
                    ${orderData?.order?.Shipping_Cost}
                  </p>
                </div>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="text-base font-semibold leading-4 text-gray-800">
                  Total
                </p>
                <p className="text-base font-semibold leading-4 text-gray-600">
                  $
                  {orderData?.total_original -
                    orderData?.total_promo +
                    orderData?.order?.Shipping_Cost}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-between bg-gray-50 px-4 py-6 md:items-start md:p-6 xl:w-96 xl:p-8 ">
          <h3 className=" mb-4 text-xl font-semibold leading-5 text-gray-800">
            Status
          </h3>
          <Button
            fullWidth
            variant="outlined"
            color={orderData?.order?.Is_Paid ? "success" : "error"}
          >
            {orderData?.order?.Is_Paid ? "Paid" : "Unpaid"}
          </Button>
          {orderData?.order?.Is_Paid ? (
            <FormControl fullWidth>
              <Select
                id="demo-simple-select-label"
                value={status}
                className="mt-4"
                onChange={handleChange}
              >
                <MenuItem value={1}>Shipped</MenuItem>
                <MenuItem value={2}>Delivered</MenuItem>
              </Select>
            </FormControl>
          ) : null}
          <h3 className=" mt-4 text-xl font-semibold leading-5 text-gray-800">
            Buyer
          </h3>

          <div className="flex  h-full w-full flex-col items-stretch justify-start md:flex-row md:space-x-6 lg:space-x-8 xl:flex-col xl:space-x-0 ">
            <div className="flex flex-shrink-0 flex-col items-start justify-start">
              <div className="flex w-full  items-center  justify-center space-x-4 border-b border-gray-200 py-8 md:justify-start">
                <img
                  src={orderData?.profile?.avatar_file || artoImagePlacehoder}
                  alt="avatar"
                />
                <div className=" flex flex-col items-start justify-start space-y-2">
                  <p className="text-left text-base font-semibold leading-4 text-gray-800">
                    {orderData?.order?.profile_id?.First_Name +
                      " " +
                      orderData?.order?.profile_id?.Last_Name}
                  </p>
                  {/* <p className="text-sm leading-5 text-gray-600">
                    10 Previous Orders
                  </p> */}
                </div>
              </div>

              <div className="flex w-full  items-center justify-center space-x-4 border-b border-gray-200 py-4 md:justify-start">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3 7L12 13L21 7"
                    stroke="#1F2937"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p className="cursor-pointer text-sm leading-5 text-gray-800">
                  {orderData?.profile?.email}
                </p>
              </div>
            </div>
            <div className="mt-6 flex w-full  flex-col items-stretch justify-between md:mt-0 xl:h-full">
              <div className="flex flex-col items-center justify-center space-y-4 md:flex-row md:items-start md:justify-start md:space-x-6 md:space-y-0 lg:space-x-8 xl:flex-col  xl:space-x-0 xl:space-y-12 ">
                <div className="flex flex-col items-center  justify-center space-y-4 md:items-start md:justify-start xl:mt-8">
                  <p className="text-center text-base font-semibold leading-4 text-gray-800 md:text-left">
                    Shipping Address
                  </p>
                  <p className="w-48 text-center text-sm leading-5 text-gray-600 md:text-left lg:w-full xl:w-48">
                    {orderData?.order?.Address}
                  </p>
                </div>
              </div>

              <div className="flex w-full items-center justify-center md:items-start md:justify-start">
                <button
                  onClick={handleClick}
                  className="mt-6 w-96 border border-gray-800 py-5 text-base font-medium leading-4 text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 md:mt-0 2xl:w-full"
                >
                  Contact Buyer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
