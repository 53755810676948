import React, { useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { columnInventory } from "./variables/columnsData";
import Tables from "components/table/index";
import { useDebounce } from "@uidotdev/usehooks";
import { CircularProgress, Stack } from "@mui/material";
import { Button } from "@material-tailwind/react";
import { useGetAllQuery } from "redux/Services/inventory";
import { handleUploadCsv } from "helpers/uploadFile";
import { toast } from "sonner";
import { ToastError } from "components/toast";
import { useGetAllTypeQuery } from "redux/Services/inventory";
const InventoryView = ({ query }) => {
  const fileRef = useRef(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const navigate = useNavigate();
  const location = useLocation();
  const [selected, setSelected] = useState([]);
  const { data: dataInventory, isLoading: loadingInventory } =
    useGetAllTypeQuery({
      page,
      pageSize,
      filter: debouncedSearchTerm,
      query: query,
      additional: "&filters[$and][0][Name][$ne]=Prints",
    });
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    fileRef.current.click();
  };
  return (
    <div className="mt-5">
      <div>
        <input
          onChange={(e) =>
            handleUploadCsv({
              loadingFunction: setLoading,
              files: e.target.files[0],
            })
          }
          ref={fileRef}
          type="file"
          className="hidden"
        />
      </div>
      <Stack
        alignItems={"end"}
        mb={2}
        flexDirection={"row"}
        justifyContent={"end"}
        gap={2}
      >
        <Button
          className="mb-3 mt-3 w-1/5"
          color="pink"
          variant="gradient"
          onClick={() => {
            navigate(`/admin/inventory/add`, {
              state: { previous: location.pathname + "" },
            });
            // toast.error("error");
          }}
        >
          Add Inventory
        </Button>
        <Button
          disabled={loading}
          className="mb-3 mt-3 w-1/5"
          color="pink"
          variant="gradient"
          onClick={handleClick}
        >
          {loading ? <CircularProgress size={15} /> : `Add Bulk Inventory`}
        </Button>
      </Stack>
      <Tables
        columnsData={columnInventory}
        tableData={dataInventory?.data || []}
        setSearchTerm={setSearchTerm}
        pageProps={page}
        setPage={setPage}
        totalPage={dataInventory?.meta}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

export default InventoryView;
