import React, { useEffect, useMemo, useState } from "react";
import CardMenu from "components/card/CardMenu";

import Card from "components/card";
import { Spacer } from "@nextui-org/react";
import { BiPencil } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { TablePagination } from "components/pagination/index";
import { IconContext } from "react-icons";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Toast } from "components/toasterSweetAlert";
import { useDeleteUserListMutation } from "redux/Services/user";
import { Toaster, toast } from "sonner";
import { useDeleteCommentMutation } from "redux/Services/comment";

import { useDeleteCollectionMutation } from "redux/Services/collection";
import { useDeleteExhibitionMutation } from "redux/Services/artist";
import { useDeleteAwardMutation } from "redux/Services/artist";
import { useDeleteArtworkMutation } from "redux/Services/artist";
import { useDeleteProfileOrgMutation } from "redux/Services/organisation";
import { useDeleteInitiativeOrgMutation } from "redux/Services/organisation";
import { useDeleteArtPreferenceMutation } from "redux/Services/art-preference";
import { useDeleteMotivationMutation } from "redux/Services/motivation";
import { useDeleteMoodCategoryMutation } from "redux/Services/mood-category";
import { useDeleteIssueTypeMutation } from "redux/Services/contactUs";
import { useDeletePromoMutation } from "redux/Services/promo";
import { useDeleteInventoryMutation } from "redux/Services/inventory";
// import { DndProvider, useDrag, useDrop } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
// import update from "immutability-helper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LoadingButton, Skeleton } from "@mui/lab";
import { useEditIndexIssueTypeMutation } from "redux/Services/contactUs";
const Tables = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("label");
  const pathName = window.location.pathname;
  const [talbeStateData, setTableStateData] = useState([]);

  const [first, setFirst] = useState(true);
  const handleClickTable = ({ id, edit }) => {
    switch (pathName?.split("/")?.[2]) {
      case "collection":
        // window.location.href = `/admin/collection/collection-details/${id}`;
        navigate(`/admin/collection/collection-details/${id}`, {
          state: { previous: "/admin/collection" },
        });
        break;
      case "users":
        navigate(`/admin/users/user-details/${id}`, {
          state: { previous: "/admin/users" },
        });
        break;
      case "artist":
        switch (param1) {
          case "Profile":
            navigate(`/admin/artist/profile-details/${id}`, {
              state: { previous: "/admin/artist?label=Profile" },
            });
            break;
          case "Artwork":
            navigate(`/admin/artist/artwork-details/${id}`, {
              state: { previous: "/admin/artist?label=Artwork" },
            });
            break;
          case "Awards":
            navigate(`/admin/artist/award-details/${id}`, {
              state: { previous: "/admin/artist?label=Awards" },
            });
            break;
          case "Exhibition":
            navigate(`/admin/artist/exhibition-details/${id}`, {
              state: { previous: "/admin/artist?label=Exhibition" },
            });
            break;
          default:
            switch (props?.columnsData.length === 3) {
              case true:
                navigate(`/admin/artist/award-details/${id}`, {
                  state: { previous: `${pathName}` },
                });
                break;
              case false:
                switch (pathName?.split("/")?.[3] === "artwork-details") {
                  default:
                    break;
                  case true:
                    navigate(`/admin/inventory/${id}`, {
                      state: { previous: `${pathName}` },
                    });
                    break;
                  case false:
                    navigate(`/admin/artist/exhibition-details/${id}`, {
                      state: { previous: `${pathName}` },
                    });
                    break;
                }
                break;
              default:
                break;
            }
            break;
        }
        break;
      case "organisation":
        switch (param1) {
          case "Profile":
            navigate(`/admin/organisation/profile/${id}`, {
              state: {
                previous: "/admin/organisation?label=Profile",
              },
            });
            break;
          case "Initiative":
            navigate(`/admin/organisation/initiative/${id}`, {
              state: {
                previous: "/admin/organisation?label=Initiative",
              },
            });
            break;
          default:
            navigate(`/admin/organisation/initiative/${id}`, {
              state: {
                previous: pathName,
              },
            });

            break;
        }
        break;
      case "onboarding":
        switch (param1) {
          case "Preference":
            navigate(`/admin/onboarding/preference/${id}`, {
              state: {
                previous: "/admin/onboarding?label=Preference",
              },
            });
            break;
          case "Motivation":
            navigate(`/admin/onboarding/motivation/${id}`, {
              state: {
                previous: "/admin/onboarding?label=Motivation",
              },
            });
            break;
          default:
            break;
        }
        break;
      case "orders":
        navigate(`/admin/orders/${id}`, {
          state: {
            previous: "/admin/orders",
          },
        });
        break;
      case "promo":
        navigate(`/admin/promo/${id}`, {
          state: {
            previous: "/admin/promo",
          },
        });
        break;
      default:
        break;
      case "mood-categories":
        navigate(`/admin/mood-categories/${id}`, {
          state: {
            previous: "/admin/mood-categories",
          },
        });
        break;
      case "settings":
        switch (param1) {
          case "type":
            navigate(`/admin/settings/type/${id}`, {
              state: {
                previous: "/admin/settings?label=type",
              },
            });
            break;
          case "issue":
            navigate(`/admin/settings/${id}`, {
              state: {
                previous: "/admin/settings?label=issue",
              },
            });
            break;
          default:
            break;
        }
        break;
      case "inventory":
        navigate(`/admin/inventory/${id}`, {
          state: {
            previous: "/admin/inventory",
          },
        });
        break;
    }
  };

  const {
    columnsData,
    tableData,
    searchTerm,
    setSearchTerm,
    pageProps,
    setPage,
    totalPage,
    selected,
    setSelected,
    artWork,
  } = props;
  // const [searchQuery, setSearchQuery] = useState("");
  const columns = useMemo(() => columnsData, [columnsData]);

  // const filteredRows = useMemo(() => {
  //   if (!searchQuery) return tableData;
  //   if (tableData.length > 0) {
  //     const attributes = Object.keys(tableData[0]);
  //     const list = [];
  //     for (const current of tableData) {
  //       for (const attribute of attributes) {
  //         if (attribute === "first_name") {
  //           continue;
  //         }
  //         const value = current[attribute];
  //         if (
  //           value &&
  //           value.toString().toLowerCase() ===
  //             searchQuery.toString().toLowerCase()
  //         ) {
  //           const found = tableData.find(
  //             (row) => row.first_name === current.first_name
  //           );
  //           if (found) {
  //             list.push(found);
  //           }
  //         }
  //       }
  //     }
  //     return list;
  //   }

  //   return [];
  // }, [searchQuery, tableData]);
  const data = useMemo(() => tableData, [tableData]);
  useEffect(() => {
    if (data?.length > 0) {
      setTableStateData(data);
      setFirst(false);
    }
  }, [data, talbeStateData]);

  function EditLogo(id) {
    if (data?.length === 0) {
      return <div></div>;
    } else {
      return (
        <div>
          <Spacer y={2} />
          <BiPencil onClick={() => handleClickTable(id)} />
        </div>
      );
    }
  }
  const tableInstance = useTable(
    {
      columns,
      data:
        pathName === "/admin/settings" && param1 === "type"
          ? talbeStateData
          : data,
      initialState: {
        pageSize: 30,
      },
    },

    useGlobalFilter,
    useSortBy,
    usePagination
  );
  console.log(tableInstance, "tableInstance");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state,
  } = tableInstance;
  initialState.pageSize = 10;

  const handleSort = (state, sortBy) => {
    if (state?.id !== sortBy) {
      return null;
    }

    return state.desc ? (
      <AiOutlineArrowDown className="text-xs text-gray-600" />
    ) : (
      <AiOutlineArrowUp className="text-xs text-gray-600" />
    );
  };
  const [deleteUser, { data: deleteData }] = useDeleteUserListMutation();
  const [deleteComment, { data: deleteCommentData }] =
    useDeleteCommentMutation();
  const [deleteCollection, { data: deleteCollectionData }] =
    useDeleteCollectionMutation();
  const [deleteExhibition, { data: deleteExhibitionData }] =
    useDeleteExhibitionMutation();
  const [deleteAward, { data: deleteAwardData }] = useDeleteAwardMutation();
  const [deleteArtwork, { data: deleteArtworkData }] =
    useDeleteArtworkMutation();
  const [deleteProfileOrg, { data: deleteProfileOrgData }] =
    useDeleteProfileOrgMutation();
  const [deleteInitiative, { data: deleteInitiativeData }] =
    useDeleteInitiativeOrgMutation();
  const [deleteArtPreference, { data: deleteArtPreferenceData }] =
    useDeleteArtPreferenceMutation();
  const [deleteMotivation, { data: deleteMotivationData }] =
    useDeleteMotivationMutation();
  const [deleteCategory, { data: deleteCategoryData }] =
    useDeleteMoodCategoryMutation();
  const [deleteIssueType, { data: deleteIssueTypeData }] =
    useDeleteIssueTypeMutation();
  const [deletePromo, { data: deletePromoData }] = useDeletePromoMutation();
  const [deleteInventory, { data: deleteInventoryData }] =
    useDeleteInventoryMutation();
  const hadleClickDelete = () => {
    return Toast.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        switch (pathName?.split("/")?.[2]) {
          case "users":
            deleteUser({ id: selected }).then((res) => {
              setSelected([]);
            });
            break;
          case "comments":
            deleteComment({ id: selected }).then((res) => {
              toast.success("Delete Comment Success");
              setSelected([]);
            });
            break;
          case "collection":
            deleteCollection({ id: selected }).then((res) => {
              toast.success("Delete Collection Success");
              setSelected([]);
            });
            break;
          case "artist":
            switch (param1) {
              case "Profile":
                break;
              case "Exhibition":
                deleteExhibition({ id: selected }).then((res) => {
                  toast.success("Delete Exhibition Success");
                  setSelected([]);
                });
                break;
              case "Awards":
                deleteAward({ id: selected }).then((res) => {
                  toast.success("Delete Award Success");
                  setSelected([]);
                });
                break;
              case "Artwork":
                deleteArtwork({ id: selected }).then((res) => {
                  toast.success("Delete Artwork Success");
                  setSelected([]);
                });
                break;
              default:
                switch (props?.columnsData.length === 3) {
                  case true:
                    deleteAward({ id: selected }).then((res) => {
                      toast.success("Delete Award Success");
                      setSelected([]);
                    });
                    break;
                  case false:
                    deleteExhibition({ id: selected }).then((res) => {
                      toast.success("Delete Exhibition Success");
                      setSelected([]);
                    });
                    break;
                  default:
                    break;
                }
                break;
            }
            break;
          case "organisation":
            switch (param1) {
              case "Profile":
                deleteProfileOrg({ id: selected }).then((res) => {
                  toast.success("Delete Organisation Profile Success");
                  setSelected([]);
                });
                break;
              case "Initiative":
                deleteInitiative({ id: selected }).then((res) => {
                  toast.success("Delete Initiative Success");
                  setSelected([]);
                });
                break;
              default:
                break;
            }
            break;
          case "onboarding":
            switch (param1) {
              case "Art Preference":
                deleteArtPreference({ id: selected }).then((res) => {
                  toast.success("Delete Art Preference Success");
                  setSelected([]);
                });
                break;
              case "Motivation":
                deleteMotivation({ id: selected }).then((res) => {
                  toast.success("Delete Motivation Success");
                  setSelected([]);
                });
                break;
              default:
                break;
            }
            break;
          case "mood-categories":
            deleteCategory({ id: selected }).then((res) => {
              toast.success("Delete Mood Category Success");
              setSelected([]);
            });
            break;
          case "settings":
            switch (param1) {
              case "type":
                deleteIssueType({ id: selected }).then((res) => {
                  toast.success("Delete Issue Type Success");
                  setSelected([]);
                });
                break;
              default:
                break;
            }
            break;
          case "promo":
            deletePromo({ id: selected }).then((res) => {
              toast.success("Delete Promo Success");
              setSelected([]);
            });
            break;
          case "inventory":
            deleteInventory({ id: selected }).then((res) => {
              toast.success("Delete Inventory Success");
              setSelected([]);
              window.location.reload();
            });
            break;
          default:
            break;
        }
      }
    });
  };
  const [updateIndex, { data: dataUpdate, isLoading: loadingUpdate }] =
    useEditIndexIssueTypeMutation();
  const handeleDragEnd = async (results) => {
    try {
      if (!results.destination) return;
      const selectedRowIndex = results.source.index;
      const destinationRowIndex = results.destination.index;
      const arrUser = [...talbeStateData];
      const [selectedRow] = arrUser.splice(selectedRowIndex, 1);
      arrUser.splice(destinationRowIndex, 0, selectedRow);
      const newArrUser = arrUser.map((item, index) => {
        return {
          issue: item.issue,
          id: item.id,
          created_at: item.created_at,
          issue_index: index,
        };
      });
      setTableStateData(newArrUser);
      const data = {
        issues: newArrUser,
      };
      const res = await updateIndex({
        data,
      });
      console.log(res, "res");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {selected?.length > 0 && (
        <div className=" sticky top-0 mt-2 flex items-center justify-between p-2 ">
          <div className="text-lg text-gray-600">
            {selected?.length} selected
          </div>
          {/* <BsFillTrashFill
            className="text-red-500"
            onClick={hadleClickDelete}
          /> */}

          {pathName?.split("/")?.[2] === "artist" &&
          param1 === "Profile" ? null : (
            <LoadingButton
              variant="outlined"
              sx={{ paddingX: "3rem" }}
              color="error"
              onClick={hadleClickDelete}
            >
              Delete
            </LoadingButton>
          )}
        </div>
      )}

      <Card extra={"w-full h-full px-6 overflow-y-scroll"}>
        <header
          className={`${
            artWork ? "w-screen" : ""
          } relative flex items-center justify-between pt-4`}
        >
          {/* <div className="text-xl font-bold text-navy-700 dark:text-white">
          {tableTitle}
        </div> */}
          {pathName === "/admin/settings" && param1 === "type" ? null : (
            <div>
              <IconContext.Provider value={{ size: "1em", color: "#6B7280" }}>
                <div className="relative">
                  <input
                    className="mb-2 mr-14 mt-2 h-10 w-full rounded-lg pl-10 outline md:outline-gray-300"
                    type="text"
                    placeholder="Search here"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                  <div className="absolute left-0 top-0 flex h-full items-center px-3">
                    <BsSearch />
                  </div>
                </div>
              </IconContext.Provider>
            </div>
          )}
          {/* <CardMenu /> */}
        </header>

        <div
          className={`${
            artWork ? "w-max" : ""
          } mt-8 overflow-x-scroll overflow-y-scroll xl:overflow-x-hidden`}
        >
          {pathName === "/admin/settings" && param1 === "type" ? (
            <>
              <DragDropContext onDragEnd={(results) => handeleDragEnd(results)}>
                <table
                  {...getTableProps()}
                  className="w-full"
                  variant="simple"
                  color="gray-500"
                  mb="24px"
                >
                  <thead>
                    {headerGroups.map((headerGroup, index) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, index) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="border-b border-gray-200 pb-[10px] pr-16 text-start dark:!border-navy-700"
                            key={index}
                          >
                            <div className="flex flex-row items-center gap-1">
                              <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                                {column.render("Header")}
                              </div>
                              {handleSort(state?.sortBy?.[0], column.id)}
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <Droppable droppableId="tbody">
                    {(provided) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        {...getTableBodyProps()}
                      >
                        {page.map((row, index) => {
                          prepareRow(row);
                          return (
                            <>
                              <Draggable
                                draggableId={row.id}
                                index={row.index}
                                isDragDisabled={loadingUpdate}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...row.getRowProps()}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={index}
                                    className=" align-center flex flex-row items-center justify-between space-x-4"
                                  >
                                    {/* whats is radio but checked? */}

                                    {row.cells.map((cell, index) => {
                                      // if (cell.column.Header === "FIRST NAME") {
                                      //   data = (
                                      //     <div className="flex items-center gap-2">
                                      //       <Checkbox />
                                      //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      //         {cell.value[0]}
                                      //       </p>
                                      //     </div>
                                      //   );
                                      // } else if (cell.column.Header === "LAST NAME") {
                                      //   data = (
                                      //     <div className="flex items-center">
                                      //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      //         {cell.value}%
                                      //       </p>
                                      //     </div>
                                      //   );
                                      // } else if (cell.column.Header === "COUNTRY") {
                                      //   data = (
                                      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      //       {" "}
                                      //       {cell.value}{" "}
                                      //     </p>
                                      //   );
                                      // } else if (cell.column.Header === "LAST LOGIN") {
                                      //   data = (
                                      //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                                      //       {cell.value}
                                      //     </p>
                                      //   );
                                      // }
                                      return (
                                        <>
                                          {loadingUpdate ? (
                                            <Skeleton
                                              key={index}
                                              variant="text"
                                              width={200}
                                              height={50}
                                              animation="wave"
                                            />
                                          ) : (
                                            <>
                                              <td
                                                {...cell.getCellProps()}
                                                key={index}
                                                className={`${
                                                  index === 0
                                                    ? "flex w-60 flex-row items-center "
                                                    : ""
                                                } cursor-pointer gap-2 pb-[16px] pt-[14px] sm:text-[14px]`}
                                                onClick={() => {
                                                  // ini syg
                                                  handleClickTable({
                                                    id: row.original.id,
                                                  });
                                                }}
                                              >
                                                {index === 0 ? (
                                                  <div
                                                    key={index}
                                                    className={`flex items-center ${
                                                      artWork ? "h-32" : ""
                                                    }`}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="h-4 w-4"
                                                      checked={selected.includes(
                                                        pathName?.split(
                                                          "/"
                                                        )?.[2] === "users"
                                                          ? row.original.userId
                                                          : row.original.id
                                                      )}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        // how to propagate click?
                                                        const id =
                                                          pathName?.split(
                                                            "/"
                                                          )?.[2] === "users"
                                                            ? row.original
                                                                .userId
                                                            : row.original.id;
                                                        setSelected(
                                                          (selected) => {
                                                            if (
                                                              selected.includes(
                                                                id
                                                              )
                                                            ) {
                                                              return selected.filter(
                                                                (item) =>
                                                                  item !== id
                                                              );
                                                            } else {
                                                              return [
                                                                ...selected,
                                                                id,
                                                              ];
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                ) : null}
                                                {cell.column.Header ===
                                                  "THUMBNAILS" ||
                                                cell?.column?.type ===
                                                  "image" ||
                                                cell?.column?.type ===
                                                  "color" ? (
                                                  cell?.column?.type !==
                                                  "color" ? (
                                                    <img
                                                      src={cell.value}
                                                      alt="thumbnail"
                                                      className={`h-16 w-16 rounded-full`}
                                                    />
                                                  ) : (
                                                    <div
                                                      className="h-16 w-16 rounded-full"
                                                      style={{
                                                        backgroundColor:
                                                          cell?.value[0] === "#"
                                                            ? cell?.value
                                                            : `#${cell?.value}`,
                                                      }}
                                                    />
                                                  )
                                                ) : cell.column.Header ===
                                                  "DESCRIPTION" ? (
                                                  <div className="line-clamp-4 w-36">
                                                    {cell.value}
                                                  </div>
                                                ) : (
                                                  cell.value
                                                )}
                                              </td>
                                            </>
                                          )}
                                        </>
                                      );
                                    })}
                                    {/* <EditLogo
                                      id={row.original.id}
                                      edit={true}
                                    /> */}
                                  </tr>
                                )}
                              </Draggable>
                            </>
                          );
                        })}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            </>
          ) : (
            <table
              {...getTableProps()}
              className="w-full"
              variant="simple"
              color="gray-500"
              mb="24px"
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="border-b border-gray-200 pb-[10px] pr-16 text-start dark:!border-navy-700"
                        key={index}
                      >
                        <div className="flex flex-row items-center gap-1">
                          <div className="text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                            {column.render("Header")}
                          </div>
                          {handleSort(state?.sortBy?.[0], column.id)}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()} key={index}>
                        {/* whats is radio but checked? */}

                        {row.cells.map((cell, index) => {
                          // if (cell.column.Header === "FIRST NAME") {
                          //   data = (
                          //     <div className="flex items-center gap-2">
                          //       <Checkbox />
                          //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                          //         {cell.value[0]}
                          //       </p>
                          //     </div>
                          //   );
                          // } else if (cell.column.Header === "LAST NAME") {
                          //   data = (
                          //     <div className="flex items-center">
                          //       <p className="text-sm font-bold text-navy-700 dark:text-white">
                          //         {cell.value}%
                          //       </p>
                          //     </div>
                          //   );
                          // } else if (cell.column.Header === "COUNTRY") {
                          //   data = (
                          //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                          //       {" "}
                          //       {cell.value}{" "}
                          //     </p>
                          //   );
                          // } else if (cell.column.Header === "LAST LOGIN") {
                          //   data = (
                          //     <p className="text-sm font-bold text-navy-700 dark:text-white">
                          //       {cell.value}
                          //     </p>
                          //   );
                          // }
                          return (
                            <>
                              <td
                                {...cell.getCellProps()}
                                key={index}
                                className={`${
                                  index === 0
                                    ? "align-center flex flex-row items-center gap-3"
                                    : ""
                                } cursor-pointer gap-2 pb-[16px] pt-[14px] sm:text-[14px]`}
                                onClick={() => {
                                  // ini syg
                                  handleClickTable({ id: row.original.id });
                                }}
                              >
                                {index === 0 ? (
                                  <div
                                    key={index}
                                    className={`flex items-center ${
                                      artWork ? "h-32" : ""
                                    }`}
                                  >
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4"
                                      checked={selected.includes(
                                        pathName?.split("/")?.[2] === "users"
                                          ? row.original.userId
                                          : row.original.id
                                      )}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        // how to propagate click?
                                        const id =
                                          pathName?.split("/")?.[2] === "users"
                                            ? row.original.userId
                                            : row.original.id;
                                        setSelected((selected) => {
                                          if (selected.includes(id)) {
                                            return selected.filter(
                                              (item) => item !== id
                                            );
                                          } else {
                                            return [...selected, id];
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {cell.column.Header === "THUMBNAILS" ||
                                cell?.column?.type === "image" ||
                                cell?.column?.type === "color" ? (
                                  cell?.column?.type !== "color" ? (
                                    <img
                                      src={cell.value}
                                      alt="thumbnail"
                                      className={`h-16 w-16 rounded-full`}
                                    />
                                  ) : (
                                    <div
                                      className="h-16 w-16 rounded-full"
                                      style={{
                                        backgroundColor:
                                          cell?.value[0] === "#"
                                            ? cell?.value
                                            : `#${cell?.value}`,
                                      }}
                                    />
                                  )
                                ) : cell.column.Header === "DESCRIPTION" ? (
                                  <div className="line-clamp-4 w-36">
                                    {cell.value}
                                  </div>
                                ) : (
                                  cell.value
                                )}
                              </td>
                            </>
                          );
                        })}
                        <EditLogo id={row.original.id} edit={true} />
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </Card>
      <div>
        <Spacer y={4} />

        <TablePagination
          page={pageProps}
          setPage={setPage}
          totalPage={totalPage}
        />

        <Spacer y={4} />
      </div>
    </>
  );
};

export default Tables;
