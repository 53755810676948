import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { artoImagePlacehoder } from 'helpers/placeholder'
const baseUrl = process.env.REACT_APP_API_URL
export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: headers => {
      const token = localStorage.getItem('jwt')
      if (token) {
        headers.set('Authorization', `bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['Inventory'],
  endpoints: builder => ({
    getAll: builder.query({
      // query: ({ page = 1, pageSize = 10, filter = "",query="" }) => ({
      //   url: `/inventories?pagination[page]=${page}&pagination[pageSize]=${pageSize}&filters[$or][0][product_name][$containsi]=${filter}&filters[$or][1][variety_code][$containsi]=${filter}&filters[$or][2][artwork_id][Name][$containsi]=${filter}&filters[$or][3][type_id][Name][$containsi]=${filter}

      //   &populate=type_id,artwork_id`,
      //   method: "GET",
      // }),
      query: ({ page = 1, pageSize = 10, filter = '', query = '' }) => {
        const baseUrl = `/inventories?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate=type_id,artwork_id`
        const filterUrl = `&filters[$or][0][product_name][$containsi]=${filter}&filters[$or][1][variety_code][$containsi]=${filter}&filters[$or][2][artwork_id][Name][$containsi]=${filter}&filters[$or][3][type_id][Name][$containsi]=${filter}`
        const queryUrl = query ? `&filters[artwork_id][id][$eq]=${query}` : ''
        return {
          url: baseUrl + filterUrl + queryUrl,
          method: 'GET'
        }
      },
      providesTags: ['Inventory'],
      transformResponse: response => {
        const { data } = response

        const newData = data?.map(item => {
          const id = item?.id
          const {
            product_name,
            variety_code,
            type_id,
            artwork_id,
            product_price
          } = item?.attributes

          return {
            id,
            product_name,
            variety_code,
            Name: type_id?.data?.attributes?.Name,
            artwork_name: artwork_id?.data?.attributes?.Name,
            product_price
          }
        })
        const newResponse = {
          meta: response?.meta,
          data: newData
        }
        return newResponse
      }
    }),
    getById: builder.query({
      query: ({ id }) => ({
        url: `/back-offcie/inventory/${id}`,
        method: 'GET'
      }),
      providesTags: ['Inventory'],
      transformResponse: response => {
        const {
          Name,
          id: idType,
          subtypes_id,
          notes,
          description,
          image_url,
          price
        } = response

        const subType = subtypes_id?.map(item => {
          const idSub = item?.id
          const { Name, subsubtypes_id } = item
          const variety = subsubtypes_id?.map(item => {
            const idVariety = item?.id
            const { Name } = item
            return {
              id: idVariety,
              value: Name
            }
          })

          return {
            id: idSub,
            value: Name,
            variety,
            show: false
          }
        })
        return {
          id: idType,
          Name: Name,
          valueSub: subType,
          notes,
          description,
          image: {
            id: image_url?.id,
            url: image_url?.url || artoImagePlacehoder
          },
          price
        }
      }
    }),
    create: builder.mutation({
      query: ({ data }) => ({
        url: '/inventory/back-office',
        method: 'POST',
        body: data
      })
    }),
    update: builder.mutation({
      query: ({ id, data }) => ({
        url: `/inventory/back-office/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['Inventory']
    }),
    deleteInventory: builder.mutation({
      query: ({ id }) => ({
        url: `back-office/dynamic-delete?model=api::type.type&id_select=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Inventory']
    }),
    getAllType: builder.query({
      query: ({ filter = '', page = 1, pageSize = 100, additional = '' }) => {
        const baseUrl = `/types?pagination[page]=${page}&pagination[pageSize]=${pageSize}${additional}`
        const filterUrl = filter
          ? `&filters[$or][2][Name][$containsi]=${filter}`
          : ''
        return {
          url: baseUrl + filterUrl,
          method: 'GET'
        }
      },
      providesTags: ['Type'],
      transformResponse: response => {
        const { data } = response
        const newData = data?.map(item => {
          const id = item?.id
          const { Name, subtype_id } = item?.attributes

          return {
            id,
            name: Name
          }
        })
        const newResponse = {
          meta: response?.meta,
          data: newData
        }
        return newResponse
      }
    }),
    getAllSubType: builder.query({
      query: ({ filters }) => ({
        url: `/subtypes${filters}?populate=type_id`,
        method: 'GET'
      }),
      providesTags: ['SubType'],
      transformResponse: response => {
        const { data } = response
        const newData = data?.map(item => {
          const id = item?.id
          const { Name, type_id } = item?.attributes
          const type_name = type_id?.data?.attributes?.Name
          return {
            id,
            Name,
            type_name
          }
        })
        const newResponse = {
          meta: response?.meta,
          data: newData
        }
        return newResponse
      }
    }),
    getAllSubSubType: builder.query({
      query: ({ filters }) => ({
        url: `/subsubtypes${filters}?populate=subtype_id`,
        method: 'GET'
      }),
      providesTags: ['SubSubType'],
      transformResponse: response => {
        const { data } = response
        const newData = data?.map(item => {
          const id = item?.id
          const { Name, subtype_id } = item?.attributes
          const subtype_name = subtype_id?.data?.attributes?.Name
          return {
            id,
            Name,
            subtype_name
          }
        })
        const newResponse = {
          meta: response?.meta,
          data: newData
        }
        return newResponse
      }
    }),
    creaateSubsubType: builder.mutation({
      query: ({ data }) => ({
        url: '/subsubtypes',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SubSubType']
    }),
    createSubType: builder.mutation({
      query: ({ data }) => ({
        url: '/subtypes',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SubType']
    }),
    createType: builder.mutation({
      query: ({ data }) => ({
        url: '/types',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Type']
    })
  })
})

export const {
  useGetAllQuery,
  useGetByIdQuery,
  useCreateMutation,
  useUpdateMutation,
  useDeleteInventoryMutation,
  useGetAllTypeQuery,
  useGetAllSubTypeQuery,
  useGetAllSubSubTypeQuery,
  useCreaateSubsubTypeMutation,
  useCreateSubTypeMutation,
  useCreateTypeMutation
} = inventoryApi
