import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useForm } from "react-hook-form";
import { useUserLoginMutation } from "redux/Services/user";
import { useEffect } from "react";
import { Toast } from "components/toast";
import { userLoginSliceAction } from "../../redux/Slices/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";

export default function SignIn() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
      touchedFields: { email: emailTouch, password },
    },
    getValues,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [loginUser, { data: dataLogin, isLoading, isError }] =
    useUserLoginMutation();

  const onSubmit = async (payload) => {
    const { data } = await loginUser({
      identifier: payload.email,
      password: payload.password,
    });
    console.log(data);
  };
  useEffect(() => {
    if (isError) {
      Toast.fire({
        icon: "error",
        title: "Invalid Email or Password!",
      });
    }
    if (dataLogin) {
      dispatch(
        userLoginSliceAction({
          jwt: dataLogin.jwt,
          user: dataLogin.user,
        })
      );

      navigate("/admin/default");
      // i want to navigate to "/admin/default" routes using react-router-dom how??
      // history.push("/admin/default");
    }
  }, [isError, dataLogin]);
  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-3 text-4xl font-bold text-navy-700 dark:text-white">
          ARTO BACKOFFICE
        </h4>
        {console.log(`disini ada`)}
        {isError && !emailTouch && (
          <span className="text-brand-500">Invalid Email or Password!</span>
        )}
        {/* <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4> */}
        {/* <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div>
        <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            state={errors.email?.message ? "error" : "default"}
            errorMessage={errors.email?.message}
            register={{
              ...register("email", {
                required: { message: "Email is required", value: true },
                pattern: {
                  message: "Email is not valid",
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                },
              }),
            }}
          />

          {/* Password */}
          <InputField
            variant="auth"
            extra="mb-3"
            label="Password*"
            placeholder="Min. 8 characters"
            id="password"
            type="password"
            state={errors.password?.message ? "error" : "default"}
            errorMessage={errors.password?.message}
            register={{
              ...register("password", {
                required: { message: "Password is required", value: true },
                minLength: {
                  message: "Password must be at least 8 characters",
                  value: 8,
                },
              }),
            }}
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <div className="flex items-center">
              <Checkbox />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                Keep me logged In
              </p>
            </div>
            {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
          </div>
          <button
            disabled={isLoading}
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
        </form>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
